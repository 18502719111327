/*

Template: Sofbox - Responsive Software Landing Page
Author: iqonicthemes.in
Version: 3.0
Design and Developed by: iqonicthemes.in

*/

/*================================================
[  Table of contents  ]
================================================

:: General
:: Back to Top
:: Loader
:: Buttons
:: Page Section Margin Padding
:: Text Weight
:: Text Color 
:: Font Size
:: Background Color
:: BG Effect
:: Background Gradient
:: Section Title
:: Header
:: Banner
:: How it Works
:: Who is Sofbox ?
:: Software Features
:: Great screenshots
:: Special Features
:: More Useful Infomation
:: Sofbox Specialities
:: Counter
:: Loved By Our Customers
:: Affordable Price
:: Meet the Team
:: Compare Services
:: Frequently Asked Questions
:: Latest Blog Post
:: Our clients
:: Subscribe Our Newsletter
:: Get in Touch
:: Footer Info
:: Breadcrumb Inner Page
:: Blog Page
:: Blog - SideBar
:: 404 Error
:: Coming Soon
:: jarallax
:: TERMS OF SERVICE
:: OWL Carousel
:: Testimonial
:: Pricing Table
:: Feature
:: Extra

======================================
[ End table content ]
/* ======================================*/
@import "/css/flaticon.css";
@import "/css/ionicons.min.css"; 
@import "/css/font-awesome.css"; 

/* @import '/node_modules/bootstrap/dist/css/bootstrap.min.css'; */


img {
  max-width: 100%;
  height: auto;
}

/*---------------------------------------------------------------------
                               General
-----------------------------------------------------------------------*/

*::-moz-selection {
  background: #32A7C6;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #32A7C6;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #32A7C6;
  color: #fff;
  text-shadow: none;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 26px;
  color: #666666;
  overflow-x: hidden;
}

a,
.button {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  color: #333333;
}

a:focus {
  text-decoration: none !important;
}

a:focus,
a:hover {
  color: #32A7C6;
  text-decoration: none !important;
}

a,
.button,
input {
  outline: medium none !important;
  color: #32A7C6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #333333;
  margin-top: 0px;
  margin-bottom: 0px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 52px;
  font-style: normal;
  line-height: 58px;
}

h2 {
  font-size: 2.441em;
  font-style: normal;
  line-height: 46px;
}

h3 {
  font-size: 36px;
  font-style: normal;
  line-height: 44px;
}

h4 {
  font-size: 24px;
  font-style: normal;
  line-height: 33px;
}

h5 {
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
}

h6 {
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
}

.lead {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

hr {
  margin: 0;
  padding: 0px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 0px;
}

label {
  font-size: 15px;
  font-weight: 400;
  color: #aaaaaa;
}

.label {
  color: #fff !important;
  font-size: 9px !important;
}

.blockquote,
blockquote {
  border-left: 5px solid #32A7C6;
  font-size: 16px;
}

.no-padding {
  padding: 0px !important;
}

.no-border {
  border: none !important;
}

.container {
  max-width: 1300px;
}

/*---------------------------------------------------------------------
                               Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top {
  z-index: 999;
  position: fixed;
  margin: 0px;
  color: #fff;
  background: #333333;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 999;
  font-size: 26px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 90px;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

#back-to-top .top:hover {
  background: #32A7C6;
  color: #fff;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

/*---------------------------------------------------------------------
                               Loader
-----------------------------------------------------------------------*/
#loading {
  background-color: #07133E;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading img {
  width: 100px;
}


/*---------------------------------------------------------------------
Extra class
---------------------------------------------------------------------*/
section {
  padding: 120px 0;
}

.main-content {
  overflow-x: hidden;
}

.container-fluid {
  padding: 0 100px;
}

.overflow,
.main-content.overflow {
  overflow: visible;
}

.iq-border-top {
  border-top: 1px solid rgba(142, 152, 159, 0.13)
}

.overflow-hidden {
  overflow: hidden;
}



/*----------------------------------------------------------------------
                        Buttons
-----------------------------------------------------------------------*/
.button {
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  border: none;
  position: relative;
  display: inline-block;
  background: var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  z-index: 4;
}

.button:hover,
.button:focus {
  color: #ffffff;
  background: #333333;
}

/* Buttons white */
.button.bt-white {
  color: #333;
  background: #fff;
}

.button.bt-white:hover,
.button.bt-white:focus {
  color: #ffffff;
  background: #32A7C6;
}

/* Buttons white 2 */
.button.bt-black {
  color: #333;
  background: #fff;
}

.button.bt-black:hover,
.button.bt-black:focus {
  color: #ffffff;
  background: #333;
}

.button.bt-blue {
  color: #fff;
  background: #32A7C6;
}

.button.bt-blue:hover {
  background-color: #333;
}

/* Butten blue shadow */
.button-blue-shadow {
  color: #fff;
  cursor: pointer;
  padding: 12px 36px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  position: relative;
  background: #4ac3f3;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
  -moz-box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
  box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
}

.button-blue-shadow:hover,
.button-blue-shadow:focus {
  color: #ffffff;
  background: #333333;
}

/* Butten white shadow */
.button-white-shadow {
  color: #4ac3f3;
  cursor: pointer;
  padding: 12px 36px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  position: relative;
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
  -moz-box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
  box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
}

.button-white-shadow:hover,
.button-white-shadow:focus {
  color: #ffffff;
  background: #333333;
}

/* Butten Line */
.button-line {
  color: #4ac3f3;
  cursor: pointer;
  padding: 10px 36px;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #4ac3f3;
  position: relative;
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.button-line:hover,
.button-line:focus {
  color: #ffffff;
  background: #4ac3f3;
}

/* Butten Line white*/
.button-line-white {
  color: #fff;
  cursor: pointer;
  padding: 12px 36px;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #fff;
  position: relative;
  background: transparent;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.button-line-white:hover,
.button-line-white:focus {
  color: #333;
  border: 2px solid #333;
}

/* Butten Line shadow */
.button-line-shadow {
  color: #4ac3f3;
  cursor: pointer;
  padding: 10px 36px;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #4ac3f3;
  position: relative;
  background: none;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
  -moz-box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
  box-shadow: 0px 20px 70px -16px rgba(74, 195, 243, 1);
}

.button-line-shadow:hover,
.button-line-shadow:focus {
  color: #ffffff;
  background: #4ac3f3;
}

/*---------------------------------------------------------------------
                    Page Section Margin Padding 
---------------------------------------------------------------------*/

/*----------------
Section padding 
----------------*/

.overview-block-ptb {
  padding: 100px 0;
}

.overview-block-pt {
  padding: 100px 0 0;
}

.overview-block-pb {
  padding: 0 0 100px;
}

/*----------------
Padding All 
----------------*/
.iq-pall {
  padding: 0px;
}

.iq-pall-10 {
  padding: 10px;
}

.iq-pall-15 {
  padding: 15px;
}

.iq-pall-20 {
  padding: 20px;
}

.iq-pall-25 {
  padding: 25px;
}

.iq-pall-30 {
  padding: 30px;
}

.iq-pall-40 {
  padding: 40px;
}

.iq-pall-50 {
  padding: 50px;
}

.iq-pall-60 {
  padding: 60px;
}

.iq-pall-70 {
  padding: 70px;
}

.iq-pall-80 {
  padding: 80px;
}

.iq-pall-90 {
  padding: 90px;
}

.iq-pall-100 {
  padding: 100px;
}

/*----------------
Padding Top and Bottom 
----------------*/
.iq-ptb-0 {
  padding: 0;
}

.iq-ptb-10 {
  padding: 10px 0;
}

.iq-ptb-15 {
  padding: 15px 0;
}

.iq-ptb-20 {
  padding: 20px 0;
}

.iq-ptb-25 {
  padding: 25px 0;
}

.iq-ptb-30 {
  padding: 30px 0;
}

.iq-ptb-40 {
  padding: 40px 0;
}

.iq-ptb-50 {
  padding: 50px 0;
}

.iq-ptb-60 {
  padding: 60px 0;
}

.iq-ptb-70 {
  padding: 70px 0;
}

.iq-ptb-80 {
  padding: 80px 0;
}

.iq-ptb-90 {
  padding: 90px 0;
}

.iq-ptb-100 {
  padding: 100px 0;
}

/*----------------
Padding Left and right 
----------------*/
.iq-plr-0 {
  padding: 0;
}

.iq-plr-10 {
  padding: 0 10px;
}

.iq-plr-15 {
  padding: 0 15px;
}

.iq-plr-20 {
  padding: 0 20px;
}

.iq-plr-25 {
  padding: 0 25px;
}

.iq-plr-30 {
  padding: 0 30px;
}

.iq-plr-40 {
  padding: 0 40px;
}

.iq-plr-50 {
  padding: 0 50px;
}

.iq-plr-60 {
  padding: 0 60px;
}

.iq-plr-70 {
  padding: 0 70px;
}

.iq-plr-80 {
  padding: 0 80px;
}

.iq-plr-90 {
  padding: 0 90px;
}

.iq-plr-100 {
  padding: 0 100px;
}

/*----------------
Padding top 
----------------*/
.iq-pt-0 {
  padding-top: 0px;
}

.iq-pt-10 {
  padding-top: 10px;
}

.iq-pt-15 {
  padding-top: 15px;
}

.iq-pt-20 {
  padding-top: 20px;
}

.iq-pt-25 {
  padding-top: 25px;
}

.iq-pt-30 {
  padding-top: 30px;
}

.iq-pt-40 {
  padding-top: 40px;
}

.iq-pt-50 {
  padding-top: 50px;
}

.iq-pt-60 {
  padding-top: 60px;
}

.iq-pt-70 {
  padding-top: 70px;
}

.iq-pt-80 {
  padding-top: 80px;
}

.iq-pt-90 {
  padding-top: 90px;
}

.iq-pt-100 {
  padding-top: 100px;
}

/*----------------
Padding Bottom 
----------------*/
.iq-pb-0 {
  padding-bottom: 0px;
}

.iq-pb-10 {
  padding-bottom: 10px;
}

.iq-pb-15 {
  padding-bottom: 15px;
}

.iq-pb-20 {
  padding-bottom: 20px;
}

.iq-pb-25 {
  padding-bottom: 25px;
}

.iq-pb-30 {
  padding-bottom: 30px;
}

.iq-pb-40 {
  padding-bottom: 40px;
}

.iq-pb-50 {
  padding-bottom: 50px;
}

.iq-pb-60 {
  padding-bottom: 60px;
}

.iq-pb-70 {
  padding-bottom: 70px;
}

.iq-pb-80 {
  padding-bottom: 80px;
}

.iq-pb-90 {
  padding-bottom: 90px;
}

.iq-pb-100 {
  padding-bottom: 100px;
}

/*----------------
Padding Left 
----------------*/
.iq-pl-0 {
  padding-left: 0;
}

.iq-pl-10 {
  padding-left: 10px;
}

.iq-pl-15 {
  padding-left: 15px;
}

.iq-pl-20 {
  padding-left: 20px;
}

.iq-pl-25 {
  padding-left: 25px;
}

.iq-pl-30 {
  padding-left: 30px;
}

.iq-pl-40 {
  padding-left: 40px;
}

.iq-pl-50 {
  padding-left: 50px;
}

.iq-pl-60 {
  padding-left: 60px;
}

.iq-pl-70 {
  padding-left: 70px;
}

.iq-pl-80 {
  padding-left: 80px;
}

.iq-pl-90 {
  padding-left: 90px;
}

.iq-pl-100 {
  padding-left: 100px;
}

/*----------------
Padding Right 
----------------*/
.iq-pr-0 {
  padding-right: 0px;
}

.iq-pr-10 {
  padding-right: 10px;
}

.iq-pr-15 {
  padding-right: 15px;
}

.iq-pr-20 {
  padding-right: 20px;
}

.iq-pr-25 {
  padding-right: 25px;
}

.iq-pr-30 {
  padding-right: 30px;
}

.iq-pr-40 {
  padding-right: 40px;
}

.iq-pr-50 {
  padding-right: 50px;
}

.iq-pr-60 {
  padding-right: 60px;
}

.iq-pr-70 {
  padding-right: 70px;
}

.iq-pr-80 {
  padding-right: 80px;
}

.iq-pr-90 {
  padding-right: 90px;
}

.iq-pr-100 {
  padding-right: 100px;
}

/*----------------
Margin All 
----------------*/
.iq-mall-0 {
  margin: 0;
}

.iq-mall-10 {
  margin: 10px;
}

.iq-mall-15 {
  margin: 15px;
}

.iq-mall-20 {
  margin: 20px;
}

.iq-mall-25 {
  margin: 25px;
}

.iq-mall-30 {
  margin: 30px;
}

.iq-mall-40 {
  margin: 40px;
}

.iq-mall-50 {
  margin: 50px;
}

.iq-mall-60 {
  margin: 60px;
}

.iq-mall-70 {
  margin: 70px;
}

.iq-mall-80 {
  margin: 80px;
}

.iq-mall-90 {
  margin: 90px;
}

.iq-mall-100 {
  margin: 100px;
}

/*----------------
Margin Top and Bottom 
----------------*/
.iq-mtb-0 {
  margin: 0;
}

.iq-mtb-10 {
  margin: 10px 0;
}

.iq-mtb-15 {
  margin: 15px 0;
}

.iq-mtb-20 {
  margin: 20px 0;
}

.iq-mtb-25 {
  margin: 25px 0;
}

.iq-mtb-30 {
  margin: 30px 0;
}

.iq-mtb-40 {
  margin: 40px 0;
}

.iq-mtb-50 {
  margin: 50px 0;
}

.iq-mtb-60 {
  margin: 60px 0;
}

.iq-mtb-70 {
  margin: 70px 0;
}

.iq-mtb-80 {
  margin: 80px 0;
}

.iq-mtb-90 {
  margin: 90px 0;
}

.iq-mtb-100 {
  margin: 100px 0;
}

/*----------------
Margin Left and Right 
----------------*/
.iq-mlr-0 {
  margin: 0;
}

.iq-mlr-10 {
  margin: 0 10px;
}

.iq-mlr-15 {
  margin: 0 15px;
}

.iq-mlr-20 {
  margin: 0 20px;
}

.iq-mlr-25 {
  margin: 0 25px;
}

.iq-mlr-30 {
  margin: 0 30px;
}

.iq-mlr-40 {
  margin: 0 40px;
}

.iq-mlr-50 {
  margin: 0 50px;
}

.iq-mlr-60 {
  margin: 0 60px;
}

.iq-mlr-70 {
  margin: 0 60px;
}

.iq-mlr-80 {
  margin: 0 80px;
}

.iq-mlr-90 {
  margin: 0 80px;
}

.iq-mlr-100 {
  margin: 0 100px;
}

/*----------------
Margin Top 
----------------*/
.iq-mt-0 {
  margin-top: 0px;
}

.iq-mt-5 {
  margin-top: 5px;
}

.iq-mt-10 {
  margin-top: 10px;
}

.iq-mt-15 {
  margin-top: 15px;
}

.iq-mt-20 {
  margin-top: 20px;
}

.iq-mt-25 {
  margin-top: 25px;
}

.iq-mt-30 {
  margin-top: 30px;
}

.iq-mt-40 {
  margin-top: 40px;
}

.iq-mt-50 {
  margin-top: 50px;
}

.iq-mt-60 {
  margin-top: 60px;
}

.iq-mt-70 {
  margin-top: 70px;
}

.iq-mt-80 {
  margin-top: 80px;
}

.iq-mt-90 {
  margin-top: 90px;
}

.iq-mt-100 {
  margin-top: 100px;
}

/*----------------
Margin Bottom 
----------------*/
.iq-mb-0 {
  margin-bottom: 0px;
}

.iq-mb-10 {
  margin-bottom: 10px;
}

.iq-mb-15 {
  margin-bottom: 15px;
}

.iq-mb-20 {
  margin-bottom: 20px;
}

.iq-mb-25 {
  margin-bottom: 25px;
}

.iq-mb-30 {
  margin-bottom: 30px;
}

.iq-mb-40 {
  margin-bottom: 40px;
}

.iq-mb-50 {
  margin-bottom: 50px;
}

.iq-mb-60 {
  margin-bottom: 60px;
}

.iq-mb-70 {
  margin-bottom: 70px;
}

.iq-mb-80 {
  margin-bottom: 80px;
}

.iq-mb-90 {
  margin-bottom: 90px;
}

.iq-mb-100 {
  margin-bottom: 100px;
}

/*----------------
Margin Left 
----------------*/
.iq-ml-0 {
  margin-left: 0px;
}

.iq-ml-10 {
  margin-left: 10px;
}

.iq-ml-15 {
  margin-left: 15px;
}

.iq-ml-20 {
  margin-left: 20px;
}

.iq-ml-25 {
  margin-left: 25px;
}

.iq-ml-30 {
  margin-left: 30px;
}

.iq-ml-40 {
  margin-left: 40px;
}

.iq-ml-50 {
  margin-left: 50px;
}

.iq-ml-60 {
  margin-left: 60px;
}

.iq-ml-70 {
  margin-left: 70px;
}

.iq-ml-80 {
  margin-left: 80px;
}

.iq-ml-90 {
  margin-left: 90px;
}

.iq-ml-100 {
  margin-left: 100px;
}

/*----------------
Margin Right
----------------*/
.iq-mr-0 {
  margin-right: 0px;
}

.iq-mr-10 {
  margin-right: 10px;
}

.iq-mr-15 {
  margin-right: 15px;
}

.iq-mr-20 {
  margin-right: 20px;
}

.iq-mr-25 {
  margin-right: 25px;
}

.iq-mr-30 {
  margin-right: 30px;
}

.iq-mr-40 {
  margin-right: 40px;
}

.iq-mr-50 {
  margin-right: 50px;
}

.iq-mr-60 {
  margin-right: 60px;
}

.iq-mr-70 {
  margin-right: 70px;
}

.iq-mr-80 {
  margin-right: 80px;
}

.iq-mr-90 {
  margin-right: 90px;
}

.iq-mr-100 {
  margin-right: 100px;
}

/*---------------------------------------------------------------------
                            Text Weight 
-----------------------------------------------------------------------*/
.iq-tw-1 {
  font-weight: 100;
}

.iq-tw-2 {
  font-weight: 200;
}

.iq-tw-3 {
  font-weight: 300;
}

.iq-tw-4 {
  font-weight: 400;
}

.iq-tw-5 {
  font-weight: 500;
}

.iq-tw-6 {
  font-weight: 600;
}

.iq-tw-7 {
  font-weight: 700;
}

.iq-tw-8 {
  font-weight: 800;
}

.iq-tw-9 {
  font-weight: 900;
}

/*---------------------------------------------------------------------
                            Text Color 
-----------------------------------------------------------------------*/
.iq-font-blue {
  color: #32A7C6;
}

.iq-font-dark-blue {
  color: #0F145B;
}

.iq-font-white {
  color: #ffffff;
}

.iq-font-black {
  color: #333333;
}

.iq-font-light {
  color: #666666;
}

.iq-font-light-bg {
  color: #ced1d5;
}

.iq-font-unika {
  color: #6794c3;
}

/*---------------------------------------------------------------------
                            Font Size 
-----------------------------------------------------------------------*/
.iq-font-15 {
  font-size: 15px;
}

.iq-font-20 {
  font-size: 20px;
}

.iq-font-30 {
  font-size: 30px;
}

/*---------------------------------------------------------------------
                          Background Color 
-----------------------------------------------------------------------*/
.white-bg {
  background: #ffffff;
}

.grey-bg {
  background: #f5f7fb;
}

.blue-bg {
  background: #32A7C6;
}

.dark-blue-bg {
  background: #0F145B;
}

.dark-bg {
  background: #07133E;
}

.light-bg {
  background: #f8f7ff;
}

.light-blue-bg {
  background: #bbe5f6;
}

.unika-bg {
  background: #6794c3 !important;
}

/*---------------------------------------------------------------------
                             BG Effect
-----------------------------------------------------------------------*/
.iq-parallax {
  position: relative;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -ms-background-size: cover !important;
  background-origin: initial;
  background-position: center center !important;
  background-repeat: no-repeat;
}

/*---------------------------------------------------------------------
                       Background Gradient 
---------------------------------------------------------------------*/
.iq-bg-over {
  position: relative;
}

/* Background Gradient Blue */
.iq-over-blue-10:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.1);
}

.iq-over-blue-20:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.2);
}

.iq-over-blue-30:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.3);
}

.iq-over-blue-40:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.4);
}

.iq-over-blue-50:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.5);
}

.iq-over-blue-60:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.6);
}

.iq-over-blue-70:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.7);
}

.iq-over-blue-80:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.8);
}

.iq-over-blue-85:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.85);
}

.iq-over-blue-90:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgb(7, 19, 62, 0.98);
}

.iq-over-blue-95:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(74, 196, 243, 0.95);
}

/* Background Gradient Black */
.iq-over-black-10:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.1);
}

.iq-over-black-20:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.2);
}

.iq-over-black-30:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.3);
}

.iq-over-black-40:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.4);
}

.iq-over-black-50:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}

.iq-over-black-60:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.6);
}

.iq-over-black-70:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.7);
}

.iq-over-black-80:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.8);
}

.iq-over-black-85:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.85);
}

.iq-over-black-90:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.9);
}

.iq-over-black-95:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.95);
}

[class*='iq-over-']:before {
  z-index: 0;
}

/* Background Gradient Black */
.iq-over-Gradient-top {
  background: rgba(255, 93, 177, 0);
  background: -moz-linear-gradient(top, rgba(255, 93, 177, 0) 0%, rgba(255, 93, 177, 0) 0%, rgba(204, 122, 195, 0) 28%, rgba(74, 196, 243, 0.8) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 93, 177, 0)), color-stop(0%, rgba(255, 93, 177, 0)), color-stop(28%, rgba(204, 122, 195, 0)), color-stop(100%, rgba(74, 196, 243, 0.8)));
  background: -webkit-linear-gradient(top, rgba(255, 93, 177, 0) 0%, rgba(255, 93, 177, 0) 0%, rgba(204, 122, 195, 0) 28%, rgba(74, 196, 243, 0.8) 100%);
  background: -o-linear-gradient(top, rgba(255, 93, 177, 0) 0%, rgba(255, 93, 177, 0) 0%, rgba(204, 122, 195, 0) 28%, rgba(74, 196, 243, 0.8) 100%);
  background: -ms-linear-gradient(top, rgba(255, 93, 177, 0) 0%, rgba(255, 93, 177, 0) 0%, rgba(204, 122, 195, 0) 28%, rgba(74, 196, 243, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(255, 93, 177, 0) 0%, rgba(255, 93, 177, 0) 0%, rgba(204, 122, 195, 0) 28%, rgba(74, 196, 243, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5db1', endColorstr='#32A7C6', GradientType=0);
}

/*---------------------------------------------------------------------
                           Section Title
-----------------------------------------------------------------------*/
.heading-title {
  margin-bottom: 80px;
  text-align: center;
}

.heading-title .title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.heading-title .title:before {
  content: "";
  position: absolute;
  bottom: 0;
  border-radius: 4px;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 4px;
  background: #32A7C6;
}

.heading-title p {
  padding: 0 80px;
  display: block;
  margin-bottom: 50px;
}

.title-box {
  margin-bottom: 100px;
  text-align: center;
}

.title {
  font-family: var(--title-font-family);
  font-weight: light;
  color: #666666;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
}


/* Section title white */
.heading-title.white .title {
  color: #fff;
  font-weight: 100;
}

.heading-title.white .title:before {
  background: transparent;
}

.iq-fadebounce {
  -webkit-animation-name: fadebounce;
  -moz-animation-name: fadebounce;
  -ms-animation-name: fadebounce;
  -o-animation-name: fadebounce;
  animation-name: fadebounce;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -ms-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-moz-keyframes fadebounce {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }

  50% {
    -moz-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1
  }

  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@-webkit-keyframes fadebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@-o-keyframes fadebounce {
  0% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }

  50% {
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1
  }

  100% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@-ms-keyframes fadebounce {
  0% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }

  50% {
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1
  }

  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes fadebounce {
  0% {
    transform: translateY(0);
    opacity: 1
  }

  50% {
    transform: translateY(20px);
    opacity: 1
  }

  100% {
    transform: translateY(0);
    opacity: 1
  }
}

/* Section title Style 2 */
.heading-title-2 {
  margin-bottom: 80px;
  text-align: center;
}

.heading-title-2 .title {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 25px;
}

.heading-title-2 i {
  font-size: 60px;
  color: #32A7C6;
}

/* Heading Title 2 Left */
.heading-title-2.text-left {
  text-align: left;
  margin-bottom: 10px;
}

.heading-title-2.text-left .title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 25px;
  padding-top: 10px;
}

.heading-title-2.text-left i {
  font-size: 50px;
  color: #32A7C6;
}

/*---------------------------------------------------------------------
                            Header
-----------------------------------------------------------------------*/
header {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 20px 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

header .container-fluid {
  padding: 0 100px;
}

header .navbar {
  padding: 0;
}

header .navbar .navbar-brand {
  padding: 0;
}

header .navbar .navbar-brand img {
  height: 50px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

header .navbar .navbar-nav {
  margin-top: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

header .navbar .navbar-nav>li {
  margin: 0 30px 0 0;
  position: relative;
}

header .navbar .navbar-nav>li:last-child {
  margin-right: 0;
}

header .navbar .navbar-nav .nav-item a {
  color: #ffffff;
  padding: 10px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

header .navbar .navbar-nav .nav-item a:hover,
header .navbar .navbar-nav .nav-item a:focus,
header .navbar .navbar-nav .nav-item a.active,
header .navbar .navbar-nav .nav-item a.active:focus,
header .navbar .navbar-nav .nav-item a.active:hover {
  color: #fff;
  background: none;
  box-shadow: none;
}

header .navbar .navbar-nav .nav-item a.active::before {
  background: #32A7C6;
  bottom: 3px;
  content: "";
  height: 1.813px;
  left: 0;
  position: absolute;
  width: 0;
  transition: all 0.3s ease-out 0s;
  border-radius: .5rem;
}

header .navbar .navbar-nav .nav-item:hover a {
  color: #32A7C6;
}

header .navbar .navbar-nav .nav-item a.active::before,
header .navbar .navbar-nav .nav-item:hover>a::before,
header .navbar .navbar-nav .nav-item>a:hover::before {
  width: 100%;
}

header .button,
header .button-line {
  margin-top: 0;
  margin-left: 40px;
}

/* Header sticky */
header.menu-sticky {
  padding: 10px 0;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  background: #07133ecc;
}

header.menu-sticky .navbar .navbar-brand img {
  height: 50px;
}


/* Header Fancy */
header.header-fancy .button {
  margin-left: 30px;
  padding: 11px 20px 11px;
  font-size: 28px;
}

header.header-fancy .navbar .navbar-nav {
  background: #fff;
  border-radius: 4px;
}

header.header-fancy .navbar .navbar-nav .nav-item a {
  color: #333;
  padding: 12px 20px;
  font-weight: 500;
}

header.header-fancy .navbar .navbar-nav>li {
  margin: 0;
}

header.header-fancy .navbar .navbar-nav .nav-item a::before {
  display: none;
}

header.header-fancy .navbar .navbar-nav .nav-item a:hover,
header.header-fancy .navbar .navbar-nav .nav-item a:focus,
header.header-fancy .navbar .navbar-nav .nav-item a.active,
header.header-fancy .navbar .navbar-nav .nav-item a.active:focus,
header.header-fancy .navbar .navbar-nav .nav-item a.active:hover {
  color: #32A7C6;
  background: none;
  box-shadow: none;
}

/* Header With Top Bar */
header.header-one {
  position: fixed;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

header.header-one .navbar .navbar-nav .nav-item a {
  color: #333;
  padding: 12px 20px;
  font-weight: 500;
}

header.header-one .navbar .navbar-nav>li {
  margin: 0;
}

header.header-one .navbar .navbar-nav .nav-item a::before {
  display: none;
}

header.header-one .navbar .navbar-nav .nav-item a:hover,
header.header-one .navbar .navbar-nav .nav-item a.active,
header.header-one .navbar .navbar-nav .nav-item a.active:focus,
header.header-one .navbar .navbar-nav .nav-item a.active:hover {
  color: #32A7C6;
  background: none;
  box-shadow: none;
}

header.header-one .header-top-bar ul li {
  display: inline-block;
  margin: 0px 8px;
}

header.header-one .header-top-bar ul li a {
  color: #fff;
  font-size: 14px
}

header.header-one .header-top-bar ul li a:hover {
  color: #32A7C6;
  font-size: 14px;
}

header.header-one .navbar {
  padding: 10px 0;
}

.header-one.menu-sticky {
  background-color: #ffffff;
}


/* Header White */
header.header-white {
  background: #fff;
}

header.header-white .navbar .navbar-nav .nav-item a::before {
  background: #32A7C6;
}

header.header-white .navbar .navbar-nav .nav-item a {
  color: #333;
}

header.header-white .navbar .navbar-nav .nav-item a:hover,
header.header-white .navbar .navbar-nav .nav-item a:focus,
header.header-white .navbar .navbar-nav .nav-item a.active,
header.header-white .navbar .navbar-nav .nav-item a.active:focus,
header.header-white .navbar .navbar-nav .nav-item a.active:hover {
  color: #32A7C6;
  background: none;
  box-shadow: none;
}

/* Header Dark */
header.dark .navbar .navbar-nav .nav-item a::before {
  background: #32A7C6;
}

header.dark .navbar .navbar-nav .nav-item a {
  color: #333;
}

header.dark .navbar .navbar-nav .nav-item a:hover,
header.dark .navbar .navbar-nav .nav-item a:focus,
header.dark .navbar .navbar-nav .nav-item a.active,
header.dark .navbar .navbar-nav .nav-item a.active:focus,
header.dark .navbar .navbar-nav .nav-item a.active:hover {
  color: #32A7C6;
  background: none;
  box-shadow: none;
}

/* Header sticky */
header.dark.menu-sticky {
  padding: 10px 0;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
}

/*---------------------------------------------------------------------
                            Banner
-----------------------------------------------------------------------*/
.iq-banner {
  position: relative;
  overflow: hidden;
  background-image: url(../static/banner-seccion.png) !important;
  background-size: cover !important;
}

.iq-banner-lg {
  background-image: url(../static/banner-seccion-grande.png) !important;
}

.iq-banner .container-fluid {
  padding: 0 90px;
}

.iq-banner:after {
  content: "";
  bottom: -5px;
  left: 0;
  width: 100%;
  display: inline-block;
  position: absolute;
}

.iq-banner .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 13%;
}

.iq-banner .banner-text h1 {
  font-size: 64px;
  line-height: normal;
}

.iq-banner .banner-img {
  width: 110%;
}

.iq-banner .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-banner .iq-video i {
  margin-left: 5px;
}

.iq-banner .waves-box {
  position: relative;
}

.iq-banner .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -90px;
  top: -90px;
  z-index: 2;
  float: right;
}

.iq-banner .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.iq-banner .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.iq-banner .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.iq-banner .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.iq-banner .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.iq-banner .banner-objects .banner-objects-01 {
  position: absolute;
  left: -8%;
  bottom: -20%;
  opacity: 0.1;
}

.iq-banner .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -125px;
  left: -13%;
  border: 15px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 250px;
  width: 250px;
}

.iq-banner .banner-objects .banner-objects-03 {
  position: absolute;
  top: 0%;
  right: -15%;
  border: 30px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

/* Banner Wave One */
.iq-banner.wave-one:after {
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 330px;
  background-size: cover;
  display: inline-block;
  position: absolute;
}

.iq-banner.wave-one .banner-text {
  margin-top: 7%;
  padding-bottom: 13%;
}

.iq-banner-servicios {
  position: relative;
  overflow: hidden;
  background-image: url(../static/banner-servicios.png) !important;
  background-size: cover !important;
}

.iq-banner-servicios .banner-img {
  width: 110%;
}


/* Banner 2 */
.iq-banner-02 {
  position: relative;
}

.iq-banner-02:after {
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 78px;
  background-size: cover;
  display: inline-block;
  position: absolute;
}

.iq-banner-02 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 13%;
}

.iq-banner-02 .banner-text h1 {
  font-size: 64px;
  line-height: normal;
}

.iq-banner-02 .banner-img {
  margin-bottom: -80px;
}

.iq-banner-02 .banner-video {
  margin-bottom: -50px;
  width: 680px;
  height: 383px;
  -webkit-box-shadow: 0px 5px 50px 1px rgba(102, 102, 102, 0.25);
  -moz-box-shadow: 0px 5px 50px 1px rgba(102, 102, 102, 0.25);
  box-shadow: 0px 5px 50px 1px rgba(102, 102, 102, 0.25);
}

.iq-banner-02 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iq-banner-02 .banner-objects .banner-objects-01 {
  position: absolute;
  right: -0%;
  bottom: 20%;
  opacity: 0.2;
}

.iq-banner-02 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -280px;
  left: -20%;
  border: 30px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

.iq-banner-02 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 50%;
  right: 25%;
  border: 20px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 300px;
  width: 300px;
}

.iq-banner-02 .banner-objects .banner-objects-04 {
  position: absolute;
  top: 20%;
  left: 10%;
  opacity: 0.2;
}

.iq-banner-02.no-before:after {
  display: none;
}

/* Banner style 01 */
.iq-banner-02.style-1:after {
  display: none;
}

.iq-banner-02.style-1 .banner-img {
  margin-bottom: 0;
}

.iq-banner-02.style-1 .banner-objects .banner-objects-01 {
  bottom: 40%;
  opacity: 0.6;
}

.iq-banner-02.style-1 .banner-objects .banner-objects-02 {
  border: 30px solid rgba(74, 195, 243, 0.9);
}

.iq-banner-02.style-1 .banner-objects .banner-objects-03 {
  border: 20px solid rgba(255, 255, 255, 0.3);
}

.iq-banner-02.style-1 .banner-objects .banner-objects-04 {
  top: 20%;
  opacity: 0.6;
}

/* Banner style 02 */
.iq-banner-02.style-2:after {
  display: none;
}

.iq-banner-02.style-2 .banner-img {
  margin-bottom: 0;
}


/* Banner 3 */
.iq-banner-03 {
  position: relative;
  overflow: hidden;
}

.iq-banner-03:after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-size: cover;
  display: inline-block;
  position: absolute;
}

.iq-banner-03 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 13%;
  margin-bottom: 5%;
}

.iq-banner-03 .banner-text h1 {
  font-size: 55px;
  line-height: normal;
}

.iq-banner-03 .banner-img {
  width: 110%;
}

.iq-banner-03 .iq-banner-video {
  position: relative;
}

.iq-banner-03 .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-banner-03 .iq-video i {
  margin-left: 5px;
}

.iq-banner-03 .waves-box {
  position: absolute;
  top: 28%;
  left: 39%;
}

.iq-banner-03 .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -90px;
  top: -90px;
  z-index: 2;
  float: right;
}

.iq-banner-03 .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.iq-banner-03 .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.iq-banner-03 .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.iq-banner-03 .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.iq-banner-03 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.iq-banner-03 .banner-objects .banner-objects-01 {
  position: absolute;
  left: 14%;
  opacity: 0.4;
}

.iq-banner-03 .banner-objects .banner-objects-02 {
  position: absolute;
  margin-bottom: -125px;
  left: -5%;
  border: 15px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 250px;
  width: 250px;
}

.iq-banner-03 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 19%;
  right: 36%;
  border: 20px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 250px;
  width: 250px;
}

.iq-banner-03 .banner-objects .banner-objects-04 {
  position: absolute;
  top: 25%;
  right: -3%;
  opacity: 0.4;
}

/* Banner 4 */
.iq-banner-04 {
  position: relative;
  overflow: hidden;
}

.iq-banner-04 .container-fluid {
  padding: 0 90px;
}

.iq-banner-04:after {
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 78px;
  background-size: cover;
  display: inline-block;
  position: absolute;
  z-index: 99;
}

.iq-banner-04 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 2%;
}

.iq-banner-04 .banner-text h1 {
  font-size: 64px;
  line-height: normal;
}

.iq-banner-04 .banner-img {
  width: 100%;
}

.iq-banner-04 .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-banner-04 .iq-video i {
  margin-left: 5px;
}

.iq-banner-04 .waves-box {
  position: relative;
}

.iq-banner-04 .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -90px;
  top: -90px;
  z-index: 2;
  float: right;
}

.iq-banner-04 .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.iq-banner-04 .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.iq-banner-04 .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.iq-banner-04 .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.iq-banner-04 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.iq-banner-04 .banner-objects .banner-objects-01 {
  position: absolute;
  left: -8%;
  bottom: -20%;
  opacity: 0.1;
}

.iq-banner-04 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -125px;
  left: -13%;
  border: 15px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 250px;
  width: 250px;
}

.iq-banner-04 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 0%;
  right: -15%;
  border: 30px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

/* Banner 5 */
.iq-banner-05 {
  position: relative;
  overflow: hidden;
}

.iq-banner-05 .container-fluid {
  padding: 0 90px;
}

.iq-banner-05 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 5%;
}

.iq-banner-05 .banner-text h1 {
  font-size: 64px;
  line-height: normal;
}

.iq-banner-05 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iq-banner-05 .banner-objects .banner-objects-01 {
  position: absolute;
  right: -0%;
  bottom: 20%;
  opacity: 0.2;
}

.iq-banner-05 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -280px;
  left: -20%;
  border: 30px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

.iq-banner-05 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 50%;
  right: 25%;
  border: 20px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 300px;
  width: 300px;
}

.iq-banner-05 .banner-objects .banner-objects-04 {
  position: absolute;
  top: 20%;
  left: 10%;
  opacity: 0.2;
}

.iq-banner-05 img {
  width: 70%;
}

/* Banner 6 */
.iq-banner-06 {
  position: relative;
  overflow: hidden;
}

.iq-banner-06 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 15%;
  margin-bottom: 30px;
}

.iq-banner-06 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iq-banner-06 .banner-objects .banner-objects-01 {
  position: absolute;
  right: -0%;
  bottom: 20%;
  opacity: 0.2;
}

.iq-banner-06 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -280px;
  left: -20%;
  border: 30px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

.iq-banner-06 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 19%;
  right: 25%;
  border: 20px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 300px;
  width: 300px;
}

/* Banner 7 */
.iq-banner-07 {
  position: relative;
  overflow: hidden;
}

.iq-banner-07 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 20%;
  margin-bottom: 13%;
}

.iq-banner-07 .banner-img {
  width: 100%;
}

.iq-banner-07 .iq-video {
  background: #32A7C6;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #fff;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-banner-07 .iq-video i {
  margin-left: 5px;
}

.iq-banner-07 .waves-box {
  position: relative;
}

.iq-banner-07 .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -90px;
  top: -90px;
  z-index: 2;
  float: right;
}

.iq-banner-07 .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(74, 196, 243, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.iq-banner-07 .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.iq-banner-07 .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.iq-banner-07 .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* Banner 8 */
.iq-banner-08 {
  position: relative;
  overflow: hidden;
}

.iq-banner-08 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 14%;
  margin-bottom: 10%;
}

.iq-banner-08 .banner-text h1 {
  font-size: 52px;
  line-height: 70px;
}

.iq-banner-08 .banner-img {
  width: 110%;
}

.iq-banner-08 .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-banner-08 .iq-video i {
  margin-left: 5px;
}

.iq-banner-08 .waves-box {
  position: relative;
}

.iq-banner-08 .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -90px;
  top: -90px;
  z-index: 2;
  float: right;
}

.iq-banner-08 .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.iq-banner-08 .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.iq-banner-08 .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.iq-banner-08 .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animations {
  display: table;
  background: #FFFFFF;
  width: 100%;
  text-align: center;
}

.animations .animations-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ani {
  position: absolute;
}

.ani.ani-1 {
  left: 30px;
  top: 120px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.ani.ani-2 {
  right: 25%;
  top: 140px;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.ani.ani-3 {
  right: 100px;
  bottom: 100px;
  -webkit-transform: translate(50%, 50%) scale(0.8);
  transform: translate(50%, 50%) scale(0.8);
}

.ani.ani-4 {
  bottom: 98px;
  left: 15%;
}

.ani.ani-5 {
  left: 30px;
  top: 70%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ani.ani-6 {
  height: 320px;
  width: 320px;
  right: 0;
  top: 0;
}

.ani-cube {
  -webkit-perspective: 20000px;
  perspective: 20000px;
  height: 100px;
  width: 100px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-30deg) rotateY(40deg) rotateZ(360deg);
  transform: rotateX(-30deg) rotateY(40deg) rotateZ(360deg);
  -webkit-animation: ani-cube-1 20s linear infinite;
  animation: ani-cube-1 20s linear infinite;
}

.ani-cube .cube-face {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
}

.ani-cube .cube-face.face_front {
  background-color: #32A7C6;
  -webkit-transform: translate3d(0px, 0px, 50px);
  transform: translate3d(0px, 0px, 50px);
  z-index: 10;
}

.ani-cube .cube-face.face_right {
  background-color: #52b8e0;
  -webkit-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  z-index: 8;
}

.ani-cube .cube-face.face_left {
  background-color: #52b8e0;
  -webkit-transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  z-index: 9;
}

.ani-cube .cube-face.face_top {
  background-color: #6fd7ff;
  -webkit-transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  z-index: 11;
}

.ani-cube .cube-face.face_bottom {
  background-color: #6fd7ff;
  -webkit-transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 50px);
  z-index: 5;
}

.ani-cube .cube-face.face_back {
  background-color: #32A7C6;
  -webkit-transform: translate3d(0px, 0px, -50px);
  transform: translate3d(0px, 0px, -50px);
  z-index: 1;
}

.ani-cube.ani-cube-2 {
  height: 40px;
  -webkit-transform: rotateX(143deg) rotateY(50deg) rotateZ(360deg);
  transform: rotateX(143deg) rotateY(50deg) rotateZ(360deg);
  -webkit-animation: ani-cube-2 10s linear infinite;
  animation: ani-cube-2 10s linear infinite;
}

.ani-cube.ani-cube-2 .cube-face.face_front {
  height: 40px;
  -webkit-transform: translate3d(0px, 0px, 100px);
  transform: translate3d(0px, 0px, 100px);
}

.ani-cube.ani-cube-2 .cube-face.face_right {
  width: 200px;
  height: 40px;
  -webkit-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg) translate3d(0px, 0px, 0);
  transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg) translate3d(0px, 0px, 0);
}

.ani-cube.ani-cube-2 .cube-face.face_left {
  width: 200px;
  height: 40px;
  -webkit-transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg) translate3d(0px, 0px, 100px);
  transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg) translate3d(0px, 0px, 100px);
}

.ani-cube.ani-cube-2 .cube-face.face_top {
  height: 200px;
  -webkit-transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 60px);
  transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 60px);
}

.ani-cube.ani-cube-2 .cube-face.face_bottom {
  height: 200px;
  -webkit-transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, -100px);
  transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, -100px);
}

.ani-cube.ani-cube-2 .cube-face.face_back {
  height: 40px;
  -webkit-transform: translate3d(0px, 0px, -100px);
  transform: translate3d(0px, 0px, -100px);
}

.ani-cube.ani-cube-3 {
  -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
  transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
  -webkit-animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  animation: ani-cube-3 20s cubic-bezier(0.7, 0, 0.7, 1) infinite;
}

.ani-cube.ani-cube-3 .cube-face.face_front,
.ani-cube.ani-cube-3 .cube-face.face_right,
.ani-cube.ani-cube-3 .cube-face.face_left,
.ani-cube.ani-cube-3 .cube-face.face_top,
.ani-cube.ani-cube-3 .cube-face.face_bottom,
.ani-cube.ani-cube-3 .cube-face.face_back {
  border: 2px solid #32A7C6;
  border-radius: 2px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #32A7C6, 0 0 0 2px #32A7C6;
}

.ani-hamburger {
  height: 19px;
  width: 30px;
  -webkit-animation: rotate90 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  animation: rotate90 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
}

.ani-hamburger .hamburger-line {
  position: absolute;
  background-color: #32A7C6;
  height: 2px;
  width: 100%;
  left: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.ani-hamburger .hamburger-line.hamburger-line_top {
  top: 0;
  -webkit-animation: hamburger-line_top 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  animation: hamburger-line_top 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
}

.ani-hamburger .hamburger-line.hamburger-line_middle {
  top: 50%;
  -webkit-animation: hamburger-line_middle 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  animation: hamburger-line_middle 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
}

.ani-hamburger .hamburger-line.hamburger-line_bottom {
  top: 100%;
  -webkit-animation: hamburger-line_bottom 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  animation: hamburger-line_bottom 4s cubic-bezier(0.7, 0, 0.7, 1) infinite;
}

.moving-square-frame {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 10px;
  left: 10px;
  opacity: .1;
  border: 1px solid #000;
}

.ani-moving-square {
  background: #32A7C6;
  height: 20px;
  width: 20px;
  -webkit-animation: ani-moving-square 6s cubic-bezier(0.7, 0, 0.7, 1) infinite;
  animation: ani-moving-square 6s cubic-bezier(0.7, 0, 0.7, 1) infinite;
}

svg {
  height: 100%;
  width: 100%;
}

svg #followPath {
  fill: none;
  stroke: #32A7C6;
  stroke-width: 8;
  stroke-dasharray: 2870px;
  stroke-dashoffset: 2870px;
  -webkit-animation: drawPath 6s linear infinite;
  animation: drawPath 6s linear infinite;
}

svg #dashedPath {
  fill: none;
  stroke: #fff;
  stroke-width: 14;
  stroke-dasharray: 50px;
}

svg #airplain {
  fill: #32A7C6;
  -webkit-transform: translate(-10px, -45px);
  transform: translate(-10px, -45px);
}

@-webkit-keyframes ani-cube-1 {
  to {
    -webkit-transform: rotateX(-30deg) rotateY(40deg) rotateZ(0);
    transform: rotateX(-30deg) rotateY(40deg) rotateZ(0);
  }
}

@keyframes ani-cube-1 {
  to {
    -webkit-transform: rotateX(-30deg) rotateY(40deg) rotateZ(0);
    transform: rotateX(-30deg) rotateY(40deg) rotateZ(0);
  }
}

@-webkit-keyframes ani-cube-2 {
  to {
    -webkit-transform: rotateX(143deg) rotateY(50deg) rotateZ(0);
    transform: rotateX(143deg) rotateY(50deg) rotateZ(0);
  }
}

@keyframes ani-cube-2 {
  to {
    -webkit-transform: rotateX(143deg) rotateY(50deg) rotateZ(0);
    transform: rotateX(143deg) rotateY(50deg) rotateZ(0);
  }
}

@-webkit-keyframes ani-cube-3 {
  0% {
    -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
    transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
  }

  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(47deg);
    transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(47deg);
  }

  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90% {
    -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
    transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
  }

  100% {
    -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(407deg);
    transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(407deg);
  }
}

@keyframes ani-cube-3 {
  0% {
    -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
    transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
  }

  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    -webkit-transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(47deg);
    transform: scale(0.8) rotateX(-63deg) rotateY(-23deg) rotateZ(47deg);
  }

  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90% {
    -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
    transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(47deg);
  }

  100% {
    -webkit-transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(407deg);
    transform: scale(0.6) rotateX(-63deg) rotateY(13deg) rotateZ(407deg);
  }
}

@-webkit-keyframes rotate90 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5%,
  50% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  55%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotate90 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5%,
  50% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  55%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes hamburger-line_top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5%,
  50% {
    top: 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  55%,
  100% {
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes hamburger-line_top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5%,
  50% {
    top: 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  55%,
  100% {
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes hamburger-line_middle {
  0% {
    opacity: 1;
  }

  5%,
  50% {
    opacity: 0;
  }

  55%,
  100% {
    opacity: 1;
  }
}

@keyframes hamburger-line_middle {
  0% {
    opacity: 1;
  }

  5%,
  50% {
    opacity: 0;
  }

  55%,
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes hamburger-line_bottom {
  0% {
    top: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5%,
  50% {
    top: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  55%,
  100% {
    top: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes hamburger-line_bottom {
  0% {
    top: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  5%,
  50% {
    top: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  55%,
  100% {
    top: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes ani-moving-square {

  0%,
  2.5% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  10%,
  15% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }

  22.5%,
  27.5% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  35%,
  40% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }

  47.5%,
  52.5% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  60%,
  65% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }

  72.5%,
  77.5% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  85%,
  90% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }

  97.5%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes ani-moving-square {

  0%,
  2.5% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  10%,
  15% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }

  22.5%,
  27.5% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  35%,
  40% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }

  47.5%,
  52.5% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  60%,
  65% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }

  72.5%,
  77.5% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  85%,
  90% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }

  97.5%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes drawPath {
  0% {
    opacity: 1;
    stroke-dashoffset: 2870px;
  }

  53% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  78% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@keyframes drawPath {
  0% {
    opacity: 1;
    stroke-dashoffset: 2870px;
  }

  53% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  78% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@media (max-width:1023px) {
  .ani.ani-1 {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  .ani.ani-2 {
    top: 120px;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  .ani.ani-5 {
    top: 80%;
  }

  .ani.ani-6 {
    max-height: 30vw;
    max-width: 30vw;
    max-height: 140px;
    max-width: 140px;
  }
}

/* Banner 09 */
.iq-banner-09 {
  position: relative;
  overflow: hidden;
}

.iq-banner-09 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 10%;
}

.iq-banner-09 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iq-banner-09 .banner-objects .banner-objects-01 {
  position: absolute;
  right: -0%;
  bottom: 20%;
  opacity: 0.2;
}

.iq-banner-09 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -280px;
  left: -20%;
  border: 30px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

.iq-banner-09 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 19%;
  right: 25%;
  border: 20px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 300px;
  width: 300px;
}

.iq-banner-09 .form-group {
  width: 72%;
}

.iq-banner-09 .form-control {
  border-radius: 4px;
  height: 50px;
  width: 100%;
  display: inline-block;
  padding-left: 25px;
  box-shadow: none;
  border: none;
}

.iq-banner-09 .banner-service {
  background: rgb(255, 255, 255, 0.9);
  margin: 100px 0px 50px;
  padding: 18px 0;
  border-radius: 5px;
}

.iq-banner-09 i {
  font-size: 50px;
  color: #32A7C6;
}

.banner-service:hover,
.banner-service:focus {
  background: #fff;
}

/* Banner 10 */
.iq-banner-10 {
  position: relative;
  overflow: hidden;
}

.iq-banner-10 .container-fluid {
  padding: 0 90px;
}

.iq-banner-10:after {
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 330px;
  background-size: cover;
  display: inline-block;
  position: absolute;
}

.iq-banner-10 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 10%;
  padding-bottom: 10%;
}

.iq-banner-10 .banner-text h1 {
  font-size: 64px;
  line-height: normal;
}

.iq-banner-10 .banner-img {
  width: 90%;
  -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.36);
}

.iq-banner-10 .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-banner-10 .iq-video i {
  margin-left: 5px;
}

.iq-banner-10 .waves-box {
  position: relative;
}

.iq-banner-10 .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -90px;
  top: -90px;
  z-index: 2;
  float: right;
}

.iq-banner-10 .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.iq-banner-10 .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.iq-banner-10 .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.iq-banner-10 .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.iq-banner-10 .banner-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.iq-banner-10 .banner-objects .banner-objects-01 {
  position: absolute;
  left: -8%;
  bottom: -20%;
  opacity: 0.1;
}

.iq-banner-10 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -125px;
  left: -13%;
  border: 15px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 250px;
  width: 250px;
}

.iq-banner-10 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 0%;
  right: -15%;
  border: 30px solid rgba(255, 255, 255, 0.1);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

/* Banner 11 */
.iq-banner-11 {
  position: relative;
  overflow: hidden;
}

.iq-banner-11 .container-fluid {
  padding: 0 90px;
}

.iq-banner-11 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 8%;
  margin-bottom: 100px;
}

.iq-banner-11 .banner-text h1 {
  font-size: 64px;
  line-height: normal;
}

.iq-banner-11 .banner-img {
  width: 110%;
}

.iq-banner-11 .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

/* Banner 12 */
.iq-banner-12 {
  position: relative;
  overflow: hidden;
}

.iq-banner-12 .banner-text {
  z-index: 9;
  position: relative;
  margin-top: 13%;
  margin-bottom: 3%;
}

.iq-banner-12 .banner-img {
  max-width: 150%;
}

.iq-banner-12 .banner-objects .banner-objects-01 {
  position: absolute;
  right: -0%;
  bottom: 20%;
  opacity: 0.2;
}

.iq-banner-12 .banner-objects .banner-objects-02 {
  position: absolute;
  bottom: 50%;
  margin-bottom: -280px;
  left: -20%;
  border: 30px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 400px;
  width: 400px;
}

.iq-banner-12 .banner-objects .banner-objects-03 {
  position: absolute;
  top: 50%;
  right: 25%;
  border: 20px solid rgba(255, 255, 255, 0.2);
  border-radius: 900px;
  height: 300px;
  width: 300px;
}

.iq-banner-12 .banner-objects .banner-objects-04 {
  position: absolute;
  top: 20%;
  left: 10%;
  opacity: 0.2;
}

.iq-banner-12 .banner-text .banner-phone {
  position: absolute;
  left: -50px;
  bottom: -31px;
  width: 40%;
}

.iq-banner-12 .banner-text .form-group .form-control {
  border: none;
  height: 50px;
  margin-top: 9px;
  border-radius: 0;
  margin-right: 10px;
}

.iq-banner-12 .banner-text .form-group .form-control:focus {
  box-shadow: none;
  border: none;
}

.iq-banner-12 .banner-text .button {
  border-radius: 0;
  background: #333333;
  color: #ffffff;
}

.iq-banner-12 .banner-text .button:hover {
  background: #fff;
  color: #333333;
}


/* Banner 13*/
.iq-banner .banner-text .banner-phone {
  position: absolute;
  right: -50px;
  bottom: -18px;
  width: 26%;
}

.iq-banner .banner-text .watch-img {
  position: absolute;
  width: 20%;
  top: 52%;
  right: 85%
}

/* Banner 14*/
.iq-banner-13 .banner-text {
  margin-top: 3%
}

.iq-breadcrumb .banner-img {
  max-width: 130%;
}

.iq-breadcrumb .banner-ani {
  position: absolute;
  top: 20%;
  right: 0
}

/* ---- particles.js container ---- */
#particles-js {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
}


/*--------------------------------------------------------------------
                            How it Works
-----------------------------------------------------------------------*/
.iq-works-box {
  border: 1px solid #f7f7f7;
  background: #fff;
  padding: 50px 20px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-works-box .icon-bg {
  background: rgba(31, 76, 255, 0.1);
  height: 100px;
  width: 100px;
  border-radius: 100px;
}

.iq-works-box .icon-bg i {
  font-size: 50px;
  line-height: 100px;
  color: #32A7C6;
}

.iq-works-box .step {
  font-size: 20px;
  background: #ffffff;
  border-radius: 100px;
  border: 1px solid #f7f7f7;
  color: #32A7C6;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  position: absolute;
  top: 40px;
}

.iq-works-box:hover {
  border: solid transparent 1px;
  box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
  -webkit-box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
  -moz-box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
}

/* IQ Works Box No Shadow */
.iq-works-box.no-shadow {
  border: none;
  box-shadow: none;
}

.iq-works-box.no-shadow:hover {
  border: none;
  box-shadow: none;
}

/* IQ Works Box No Shadow */
.iq-works-box.round-icon {
  padding: 10px 20px 0;
  border: none;
  box-shadow: none;
}

.iq-works-box.round-icon .icon-bg {
  background: none;
  height: 100px;
  width: 100px;
  display: inline-block;
  border-radius: 100px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-works-box.round-icon .icon-bg i {
  font-size: 50px;
  line-height: 100px;
  color: #32A7C6;
}

.iq-works-box.round-icon .step {
  font-size: 20px;
  background: #ffffff;
  border-radius: 100px;
  border: 1px solid #f7f7f7;
  color: #32A7C6;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  position: absolute;
  top: 40px;
}

.iq-works-box.round-icon:hover {
  border: none;
  box-shadow: none;
}

.iq-works-box.round-icon:hover .icon-bg {
  box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
  -webkit-box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
  -moz-box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
}

/* IQ Border Block */
.iq-border-block {
  border: 1px solid #f7f7f7;
  padding: 50px 20px;
  position: relative;
}

.iq-border-block .icon-bg {
  height: 100px;
  width: 100px;
  border-radius: 100px;
}

.iq-border-block .icon-bg i {
  font-size: 50px;
  line-height: 100px;
  color: #32A7C6;
}

.iq-border-block .step {
  font-size: 20px;
  background: #ffffff;
  border-radius: 100px;
  border: 1px solid #f7f7f7;
  color: #32A7C6;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  position: absolute;
  top: 40px;
}

.iq-border-block {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.iq-border-block:before,
.iq-border-block:after,
.iq-border-block>.border-box:before,
.iq-border-block>.border-box:after {
  content: "";
  background: #32A7C6;
  position: absolute;
  transition: all 0.3s ease 0s;
}

.iq-border-block:before,
.iq-border-block:after {
  bottom: 0;
  right: 0;
}

.iq-border-block:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
  transition-delay: 0.9s;
}

.iq-border-block:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
  transition-delay: 0.6s;
}

.iq-border-block>.border-box:before,
.iq-border-block>.border-box:after {
  top: 0;
  left: 0;
}

.iq-border-block>.border-box:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
  transition-delay: 0.3s;
}

.iq-border-block>.border-box:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
  transition-delay: 0s;
}

.iq-border-block:hover:before,
.iq-border-block:hover:after,
.iq-border-block:hover>.border-box:before,
.iq-border-block:hover>.border-box:after {
  transform: translate(0, 0);
}

.iq-border-block:hover:before {
  transition-delay: 0s;
}

.iq-border-block:hover:after {
  transition-delay: 0.3s;
}

.iq-border-block:hover>.border-box:before {
  transition-delay: 0.6s;
}

.iq-border-block:hover>.border-box:after {
  transition-delay: 0.9s;
}

/*---------------------------------------------------------------------
                            Who is Sofbox ?
-----------------------------------------------------------------------*/
.how-works {
  position: relative;
  overflow: hidden;
}

.iq-works-img {
  width: 100%;
}

.iq-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.iq-objects .iq-objects-01 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.iq-objects .iq-objects-02 {
  position: absolute;
  top: 10%;
  right: 35%;
}

.iq-objects .iq-objects-03 {
  position: absolute;
  top: 30%;
  right: 0;
}

.iq-objects .iq-objects-04 {
  position: absolute;
  top: 0%;
  right: -13%;
  border: 56px solid #32A7C6;
  border-radius: 900px;
  height: 600px;
  width: 600px;
}

/*---------------------------------------------------------------------
                            All four Productivity 
-----------------------------------------------------------------------*/
.how-works {
  position: relative;
  overflow: hidden;
}

.iq-works-img {
  width: 100%;
}

.iq-objectsnew {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.iq-objectsnew .iq-objects-01 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.iq-objectsnew .iq-objects-02 {
  position: absolute;
  top: 10%;
  right: 35%;
}

.iq-objectsnew .iq-objects-03 {
  position: absolute;
  top: 30%;
  right: 0;
}

.iq-objectsnew .iq-objects-04 {
  position: absolute;
  top: 0%;
  right: -13%;
  border: 56px solid #32A7C6;
  border-radius: 900px;
  height: 600px;
  width: 600px;
}

/*---------------------------------------------------------------------
                            Software Features
-----------------------------------------------------------------------*/
.software {
  position: relative;
}

.iq-software-demo {
  position: absolute;
  top: 50px;
  height: 100%;
  width: 50%;
  left: 0;
}

.iq-list li {
  margin-bottom: 15px;
  line-height: 26px;
}

.iq-list li i {
  float: left;
}

.iq-list li span {
  display: table-cell;
}

.iq-objects-software {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.iq-objects-software .iq-objects-01 {
  position: absolute;
  top: 0;
  left: 0;
}

.iq-objects-software .iq-objects-02 {
  position: absolute;
  bottom: 5%;
  left: 15%;
}

.iq-objects-software .iq-objects-03 {
  position: absolute;
  top: 0%;
  left: 10%;
  border: 40px solid #32A7C6;
  border-radius: 900px;
  height: 600px;
  width: 600px;
}


/*---------------------------------------------------------------------
                            All four Productivity 
-----------------------------------------------------------------------*/
.software {
  position: relative;
}

.iq-software-demo {
  position: absolute;
  top: 50px;
  height: 100%;
  width: 50%;
  left: 0;
}

.iq-list li {
  margin-bottom: 15px;
  line-height: 26px;
}

.iq-list li i {
  float: left;
}

.iq-list li span {
  display: table-cell;
}

.iq-objects-softwarenew {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.iq-objects-softwarenew .iq-objects-01 {
  position: absolute;
  top: 0;
  left: 0;
}

.iq-objects-softwarenew .iq-objects-02 {
  position: absolute;
  bottom: 5%;
  left: 15%;
}

.iq-objects-softwarenew .iq-objects-03 {
  position: absolute;
  top: 0%;
  left: 10%;
  border: 40px solid #32A7C6;
  border-radius: 900px;
  height: 600px;
  width: 600px;
}

/*---------------------------------------------------------------------
                         Great screenshots
-----------------------------------------------------------------------*/
.iq-screenshots {
  overflow: hidden;
  position: relative;
}

@keyframes heartbeat {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.screenshots-slider {
  display: inline-block;
  width: 100%;
  min-height: 580px;
}

.slider-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 780px;
  height: 580px;
  margin: -300px 0 0 -390px;
}

.slider-container .slider-content {
  position: relative;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.slider-container .slider-content .slider-single {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: z-index 0ms 250ms;
}

.slider-container .slider-content .slider-single .slider-single-image {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
  transform: scale(0);
  opacity: 0;
}

.slider-container .slider-content .slider-single.preactivede .slider-single-image {
  transform: translateX(-50%) scale(0);
}

.slider-container .slider-content .slider-single.preactive {
  z-index: 1;
}

.slider-container .slider-content .slider-single.preactive .slider-single-image {
  opacity: .3;
  transform: translateX(-25%) scale(0.8);
}

.slider-container .slider-content .slider-single.proactive {
  z-index: 1;
}

.slider-container .slider-content .slider-single.proactive .slider-single-image {
  opacity: .3;
  transform: translateX(25%) scale(0.8);
}

.slider-container .slider-content .slider-single.proactivede .slider-single-image {
  transform: translateX(50%) scale(0);
}

.slider-container .slider-content .slider-single.active {
  z-index: 2;
}

.slider-container .slider-content .slider-single.active .slider-single-image {
  opacity: 1;
  transform: translateX(0%) scale(1);
}

.slider-container .slider-left {
  position: absolute;
  z-index: 3;
  display: block;
  right: 115%;
  top: 50%;
  color: #ffffff;
  transform: translateY(-50%);
  padding: 20px 15px;
  font-size: 60px;
}

.slider-container .slider-right {
  position: absolute;
  z-index: 3;
  display: block;
  left: 115%;
  top: 50%;
  color: #ffffff;
  transform: translateY(-50%);
  padding: 20px 15px;
  font-size: 60px;
}

/* Screenshots Slider NO Shadow  */
.no-shadow .slider-container .slider-content .slider-single .slider-single-image {
  box-shadow: none;
}

.no-shadow .slider-container .slider-content .slider-single.preactive .slider-single-image {
  opacity: 0;
}

.no-shadow .slider-container .slider-content .slider-single.proactive .slider-single-image {
  opacity: 0;
}

/*---------------------------------------------------------------------
                             Special Features
-----------------------------------------------------------------------*/
.iq-amazing-tab .nav.nav-tabs {
  border-bottom: 1px solid #e9e9e9;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.iq-amazing-tab .nav.nav-tabs li {
  margin-bottom: 0;
  display: inline-block;
  float: none;
  width: 25%;
  position: relative;
}

.iq-amazing-tab .nav-tabs>li a.active,
.iq-amazing-tab .nav-tabs>li a.active:hover,
.iq-amazing-tab .nav-tabs>li a.active:focus,
.iq-amazing-tab .nav-tabs>li a.active1,
.iq-amazing-tab .nav-tabs>li a.active1:hover,
.iq-amazing-tab .nav-tabs>li a.active1:focus {
  background-color: #f5f7fb !important;
}

.iq-amazing-tab .nav.nav-tabs li a {
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 20px 40px;
  margin-right: 0;
  text-align: center;
  color: #666666;
  border: none;
  position: relative;
}

.iq-amazing-tab .nav-tabs li a i {
  font-size: 50px;
  margin-right: 20px;
  vertical-align: middle;
  text-align: right;
}

.iq-amazing-tab .nav.nav-tabs li a span {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  line-height: 40px;
  vertical-align: sub;
  text-align: left;
}

.iq-amazing-tab .nav.nav-tabs li a:hover {
  color: #32A7C6;
  background: none;
  border: none;
}

.iq-amazing-tab .nav.nav-tabs li a:hover i {
  color: #32A7C6;
}

.iq-amazing-tab .nav.nav-tabs li a.active,
.iq-amazing-tab .nav.nav-tabs li a.active:hover,
.iq-amazing-tab .nav.nav-tabs li a.active:focus {
  color: #333333;
  background: none;
  border: none;
}

.iq-amazing-tab .nav.nav-tabs li a.active i,
.iq-amazing-tab .nav.nav-tabs li a.active:hover i,
.iq-amazing-tab .nav.nav-tabs li a.active:focus i,
.iq-amazing-tab .nav.nav-tabs li a.active1 i,
.iq-amazing-tab .nav.nav-tabs li a.active1:hover i,
.iq-amazing-tab .nav.nav-tabs li a.active1:focus i {
  color: #32A7C6;
}

.iq-amazing-tab .nav.nav-tabs li a:before,
.iq-amazing-tab .nav.nav-tabs li a:hover:before,
.iq-amazing-tab .nav.nav-tabs li a:focus:before {
  position: absolute;
  left: -100%;
  bottom: -1px;
  height: 3px;
  width: 100%;
  content: "";
  opacity: 0;
  background: #32A7C6;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-amazing-tab .nav.nav-tabs li a.active:before,
.iq-amazing-tab .nav.nav-tabs li a.active:hover:before,
.iq-amazing-tab .nav.nav-tabs li a.active:focus:before,
.iq-amazing-tab .nav.nav-tabs li a:hover,
.iq-amazing-tab .nav.nav-tabs li a.active1:before,
.iq-amazing-tab .nav.nav-tabs li a.active1:hover:before {
  opacity: 1;
  left: 0%;
}

/*---------------------------------------------------------------------
                         More Useful Infomation
-----------------------------------------------------------------------*/
.info-box {
  border: 1px solid #eee;
  height: 140px;
  width: 140px;
  border-radius: 70px;
  text-align: center;
  display: inline-block;
}

.info-box .info-icon i {
  font-size: 56px;
}

.info-box .info-icon {
  border-radius: 50px;
  box-shadow: 0px 0px 30px 5px #eee;
  height: 100px;
  width: 100px;
  text-align: center;
  margin: 20px;
  line-height: 94px;
}

/*---------------------------------------------------------------------
                       Sofbox Specialities
-----------------------------------------------------------------------*/
.iq-fancy-box {
  padding: 40px 15px 25px 15px;
  overflow: hidden;
  position: relative;
  background: #fff;
  border: 1px solid #f2f2f2;
  height: 100%;
}

.iq-fancy-box .iq-icon {
  border-radius: 90px;
  display: inline-block;
  height: 86px;
  width: 86px;
  line-height: 86px;
  text-align: center;
  color: #32A7C6;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-fancy-box:hover .iq-icon {
  color: #f4f4f4;
  background: #e1f2f3;
}

.iq-fancy-box .iq-icon i {
  font-size: 46px;
}

.iq-fancy-box .fancy-content h5 {
  z-index: 9;
  position: relative;
}

/*---------------------------------------------------------------------
                       Sofbox Specialities
-----------------------------------------------------------------------*/
.iq-fancy-box-new {
  padding: 40px 15px 25px 15px;
  overflow: hidden;
  position: relative;
}

.iq-fancy-box-new .iq-icon {
  border-radius: 90px;
  display: inline-block;
  height: 86px;
  width: 86px;
  line-height: 86px;
  text-align: center;
  color: #32A7C6;
  background: #f4f4f4;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-fancy-box-new:hover .iq-icon {
  color: #f4f4f4;
  background: #32A7C6;
}

.iq-fancy-box-new .iq-icon i {
  font-size: 46px;
}

.iq-fancy-box-new .fancy-content h5 {
  z-index: 9;
  position: relative;
}





/*---------------------------------------------------------------------
                       Sofbox Specialities
-----------------------------------------------------------------------*/
.iq-fancy-box-1 {
  padding: 40px 15px 25px 15px;
  overflow: hidden;
  position: relative;
}

.iq-fancy-box-1 .iq-icon {
  border-radius: 90px;
  display: inline-block;
  height: 86px;
  width: 86px;
  line-height: 86px;
  text-align: center;
  color: #32A7C6;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-fancy-box-1:hover .iq-icon {
  color: #333333;
}

.iq-fancy-box-1 .iq-icon i {
  font-size: 46px;
}

.iq-fancy-box-1 .fancy-content h5 {
  z-index: 9;
  position: relative;
}

/*---------------------------------------------------------------------
                          Counter
-----------------------------------------------------------------------*/
.counter {
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: flex-start;
}

.counter i {
  font-size: 60px;
  line-height: 65px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.counter-date {
  flex: 1;
}

.counter span {
  display: inline-block;
  width: 100%;
  font-size: 40px;
  margin: 0;
  margin-bottom: 5px;
  line-height: 40px;
}

.counter label {
  font-size: 18px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
  margin-bottom: 0;
  text-transform: uppercase;
  float: left;
}

.counter-info {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
  width: 100%;
  position: relative;
}

.counter-info-img {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.counter-info-img img {
  margin-top: -30px;
  margin-bottom: -135px;
}

.counter-info .iq-video {
  background: #fff;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 32px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.6;
  z-index: 9;
  position: relative;
}

.counter-info .iq-video i {
  margin-left: 7px;
}

.counter-info .waves-box {
  position: absolute;
  top: 45%;
  left: 61%;
}

.counter-info .iq-waves {
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -80px;
  top: -80px;
  z-index: 2;
  float: right;
}

.counter-info .iq-waves .waves {
  position: absolute;
  width: 384px;
  width: 15rem;
  height: 384px;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.counter-info .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.counter-info .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.counter-info .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* Counter */
.iq-counter {
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: flex-start;
}

.iq-counter i {
  font-size: 60px;
  line-height: 65px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.counter-date {
  flex: 1;
}

.iq-counter span {
  display: inline-block;
  width: 100%;
  font-size: 40px;
  margin: 0;
  margin-bottom: 5px;
  line-height: 40px;
}

.iq-counter label {
  font-size: 14px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
  margin-bottom: 0;
  float: left;
}

.iq-counter-box-1 .heading-title p {
  padding-left: 20%;
  padding-right: 20%;
}

/* Counter 1 */
.iq-counter-1 {
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: flex-start;
}

.iq-counter-1 i {
  font-size: 60px;
  line-height: 65px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.counter-date {
  flex: 1;
}

.iq-counter-1 span {
  display: inline-block;
  width: 100%;
  font-size: 40px;
  margin: 0;
  margin-bottom: 5px;
  line-height: 40px;
}

.iq-counter-1 label {
  margin-top: 40px;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
  margin-bottom: 0;
  float: left;
}

.iq-counter-1-box-2 .heading-title p {
  padding-left: 20%;
  padding-right: 20%;
}

/*---------------------------------------------------------------------
                          Loved By Our Customers
-----------------------------------------------------------------------*/
.iq-loved-customers .owl-carousel .owl-item .item {
  padding: 10px 10px 20px;
}

.iq-client {
  position: relative;
  margin-top: 40px;
  padding: 30px;
  text-align: center;
  border: 1px solid #f2f2f2;
  -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
}

.iq-client:before {
  position: absolute;
  content: "";
  bottom: -8px;
  left: 5%;
  width: 90%;
  background: #32A7C6;
  height: 8px;
  display: inline-block;
  z-index: -1;
}

.iq-client .client-img {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 90px;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.client-info {
  margin-top: 30px;
}

.client-name:before {
  content: "\f10d";
  font-family: FontAwesome;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 44px;
  color: #f0f0f0;
  line-height: normal;
}

.client-name:after {
  content: "\f10e";
  font-family: FontAwesome;
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: 44px;
  color: #f0f0f0;
  line-height: normal;
}

.client-info p {
  line-height: 28px;
}

/*---------------------------------------------------------------------
                        Affordable Price
-----------------------------------------------------------------------*/
.iq-pricing {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #ededed;
  background: #fff;
}

.iq-pricing .price-title {
  padding: 35px 20px;
  position: relative;
}

.iq-pricing .price-title:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.iq-pricing.pricing-02 .price-title:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.iq-pricing.pricing-03 .price-title:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.iq-pricing .price-title h2 {
  font-family: 'Open Sans', sans-serif;
  line-height: 60px;
  font-size: 50px;
  position: relative;
}

.iq-pricing .price-title h2 small {
  font-size: 16px;
  color: #fff;
  vertical-align: super;
  padding: 0 5px;
}

.iq-pricing .price-title h2 small:first-child {
  font-size: 24px;
}

.iq-pricing .price-title span {
  letter-spacing: 6px;
  position: relative;
}

.iq-pricing ul {
  margin: 20px 0 0;
}

.iq-pricing ul li {
  line-height: 50px;
}

.iq-pricing .price-footer {
  padding: 30px 0;
  z-index: 9;
  position: relative;
}

/*---------------------------------------------------------------------
                          Meet the Team
-----------------------------------------------------------------------*/
.iq-team {
  position: relative;
  overflow: hidden;
}

.iq-team .iq-team-info {
  padding: 10px 0;
}

.iq-team .iq-team-info span {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.iq-team .share {
  background: none;
  position: absolute;
  left: -65px;
  top: 10px;
  width: 40px;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-team:hover .share {
  left: 15px;
}

.iq-team .share ul li {
  margin: 5px 0;
}

.iq-team .share ul li a {
  color: #fff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #333;
  color: #fff;
  border-radius: 90px;
  text-align: center;
  display: inline-block;
}

.iq-team .share ul li a:hover {
  background: #32A7C6;
}

.iq-team .iq-team-img {
  position: relative;
}

.iq-team .iq-team-img img {
  width: 100%;
}

.iq-team .iq-team-img:before {
  content: "";
  bottom: 0;
  opacity: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(74, 196, 243, 0);
  background: -moz-linear-gradient(top, rgba(74, 196, 243, 0) 0%, rgba(74, 196, 243, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(74, 196, 243, 0)), color-stop(100%, rgba(74, 196, 243, 1)));
  background: -webkit-linear-gradient(top, rgba(74, 196, 243, 0) 0%, rgba(74, 196, 243, 1) 100%);
  background: -o-linear-gradient(top, rgba(74, 196, 243, 0) 0%, rgba(74, 196, 243, 1) 100%);
  background: -ms-linear-gradient(top, rgba(74, 196, 243, 0) 0%, rgba(74, 196, 243, 1) 100%);
  background: linear-gradient(to bottom, rgba(74, 196, 243, 0) 0%, rgba(74, 196, 243, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#32A7C6', endColorstr='#32A7C6', GradientType=0);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-team:hover .iq-team-img:before {
  opacity: 1;
}

/*Team 1*/
.iq-team-1 .team-blog {
  border: 1px solid #f2f2f2;
  border-bottom: 2px solid #4ac3f3;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.iq-team-1 .team-blog img {
  display: inline;
}

.iq-team-1 .iq-star i {
  font-size: 16px;
  margin-right: 4px;
  color: #4ac3f3;
}





/*---------------------------------------------------------------------
                          Compare Services
-----------------------------------------------------------------------*/
.iq-services-box .iq-icon i {
  font-size: 60px;
  float: left;
  margin-right: 20px;
}

.iq-services-box .services-content {
  display: table-cell;
}

.iq-progress-bar-text {
  font-size: 16px;
  text-transform: uppercase;
  color: #32A7C6;
  position: relative;
  margin-top: 50px;
}

.iq-progress-bar-text span {
  float: right;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  width: 40px;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  position: relative;
  bottom: 50px;
  right: 0;
}

.iq-progress-bar-text span:before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -7px;
  bottom: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #ffffff transparent transparent transparent;
}

.iq-progress-bar {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 0;
  height: 8px;
  margin: 0;
  position: relative;
  width: 100%;
}

.iq-progress-bar>span {
  background: #333 none repeat scroll 0 0;
  display: block;
  height: 100%;
  width: 0;
}

/*---------------------------------------------------------------------
                        Frequently Asked Questions
-----------------------------------------------------------------------*/
.iq-asked {
  position: relative;
}

.iq-accordion {
  z-index: 9;
  position: relative;
}

.iq-accordion .ad-title {
  position: relative;
  padding: 15px 50px 15px 15px;
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
  background-color: #f5f7fb;
  display: block;
  text-align: left;
  color: #2c3e50;
  text-decoration: none;
  border-radius: 4px;
}

.iq-accordion .ad-title::before {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 15px 0px;
  cursor: pointer;
  content: "";
  font-family: Ionicons;
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  padding: 14px 20px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  font-weight: normal;
  transition: all 0.25s ease-in-out 0s;
  background: rgb(0 202 214);
}

.iq-accordion .ad-active .ad-title:before {
  content: "\f3d8";
  font-family: "Ionicons";
}

.iq-accordion .ad-details {
  display: none;
  overflow: hidden;
  text-align: left;
  padding: 15px 15px;
  color: #666666;
  line-height: 24px;
  background: #fff;
  border: 1px solid #eeeeee;
  border-top: none;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
}

.iq-accordion .iq-ad-block {
  margin-bottom: 30px;
}

.iq-accordion .iq-ad-block:last-child {
  margin-bottom: 0;
}

.iq-objects-asked {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.iq-objects-asked .iq-objects-01 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.iq-objects-asked .iq-objects-02 {
  position: absolute;
  top: 37%;
  left: 35%;
}

.iq-objects-asked .iq-objects-03 {
  position: absolute;
  top: 28%;
  left: 13%;
  border: 25px solid #32A7C6;
  border-radius: 900px;
  height: 350px;
  width: 350px;
}

/*---------------------------------------------------------------------
                                Latest Blog Post
-----------------------------------------------------------------------*/
.iq-blog-box {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
}

.iq-blog-box .iq-blog-image {
  position: relative;
}

.iq-blog-box .iq-blog-image img {
  width: 100%;
}

.iq-blog-detail {
  padding: 20px 15px 15px;
  background: #fff;
}

.iq-blog-detail a,
.iq-blog-detail .blog-title a h5 {
  color: #333;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-blog-detail a:hover,
.iq-blog-detail .blog-title a:hover h5 {
  color: #32A7C6;
}

.iq-blog-detail a.button {
  color: #fff;
}

.iq-blog-meta {
  margin: 15px 0 5px;
  border-top: 1px solid #ececec;
  padding-top: 15px;
}

.iq-blog-meta ul li {
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 16px;
}

.iq-blog-meta ul li:last-child {
  margin: 0;
}

/*video vimeo and youtube*/
.iq-bolg-video {
  height: 0;
  padding-bottom: 56.5%;
  position: relative;
  overflow: hidden;
}

.iq-bolg-video video,
.iq-bolg-video embed,
.iq-bolg-video iframe,
.iq-bolg-video object {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: none;
}

/*blog-entry-audio*/
.media-wrapper video {
  width: 100%;
  height: 100%;
  display: inline-block;
  float: left;
}

.players {
  position: absolute;
  bottom: 15px;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}

/*---------------------------------------------------------------------
                          Our clients
-----------------------------------------------------------------------*/
.iq-our-clients {
  border-top: 1px solid #ededed;
}

.iq-our-clients .owl-carousel .owl-nav {
  margin-top: -20px;
}

.iq-our-new {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
}

/* clients Box*/
.iq-clients-box {
  display: inline-block;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}

.iq-clients-box .clients .clients-brd {
  border: 1px solid #f2f2f2;
}

.iq-clients-box ul li {
  width: 33.33%;
  border-left: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  float: left;
  margin: 0;
  padding: 15px 0;
  text-align: center;
}

.iq-clients-box ul li.small {
  width: 25%;
}

.iq-clients-box ul li:nth-child(3n) {
  border-righ: 1px solid #f2f2f2;
}

/*---------------------------------------------------------------------
                           Subscribe Our Newsletter
-----------------------------------------------------------------------*/
.iq-newsletter .form-group {
  width: 72%;
}

.iq-newsletter .form-control {
  border-radius: 4px;
  height: 50px;
  width: 100%;
  display: inline-block;
  padding-left: 25px;
  box-shadow: none;
  border: none;
}

.iq-newsletter .form-group {
  width: 72%;
}

.iq-newsletter .form-control {
  border-radius: 4px;
  height: 50px;
  width: 100%;
  display: inline-block;
  padding-left: 25px;
  box-shadow: none;
  border: none;
}


/*---------------------------------------------------------------------
                             Get in Touch
-----------------------------------------------------------------------*/
.iq-footer-box .iq-icon i {
  font-size: 26px;
  float: left;
  margin-right: 20px;
  height: 60px;
  width: 60px;
  background: #f4f4f4;
  text-align: center;
  line-height: 60px;
  color: #32A7C6;
  border-radius: 90px;
}

.iq-footer-box .footer-content {
  display: table-cell;
}

.info-share {
  margin: 30px 0 0;
  padding: 0;
  text-align: left;
}

.info-share li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0px 5px;
}

.info-share li a {
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  color: #666;
  background: transparent;
  border: 1px solid #666;
  text-align: center;
  border-radius: 50%;
}

.info-share li a:hover {
  background: #32A7C6;
  border-color: #32A7C6;
  color: #fff;
}

.footer-copyright {
  border-top: 1px solid #efefef;
}

/*---------------------------------------------------------------------
                           Footer Info
-----------------------------------------------------------------------*/
.footer-info {
  position: relative;
}

.footer-info .map {
  border: 0px;
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.iq-get-in {
  position: relative;
  z-index: 9;
  background: #fff;
  padding: 30px 30px;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.contact-form .section-field {
  position: relative;
  width: 100%;
  float: left;
}

.contact-form .section-field input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 4px;
  padding-left: 20px;
  height: 48px;
  line-height: 48px;
  clear: both;
  color: #b6b6b6;
  border: 1px solid #e1e1e1;
}

.contact-form .section-field input:focus,
.contact-form .section-field.textarea textarea:focus {
  border: 1px solid #32A7C6;
  box-shadow: none;
  outline: none;
}

.contact-form .section-field.textarea textarea {
  width: 100%;
  float: left;
  color: #b6b6b6;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 15px 0 0 20px;
  resize: none;
}

#formmessage {
  display: none;
}

#success {
  display: none;
  margin-top: 10px;
}

.contact-info {
  position: relative;
}

.contact-info:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  left: 15px;
  top: 0;
  background: #e5e5e5;
}

.info-share {
  margin: 0 0;
  padding: 0;
  text-align: left;
}

.info-share li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0px 5px;
}

.info-share li a {
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  color: #666;
  background: transparent;
  border: 1px solid #666;
  text-align: center;
  border-radius: 50%;
}

.info-share li a:hover {
  background: #32A7C6;
  border-color: #32A7C6;
  color: #fff;
}

/*---------------------------------------------------------------------
                            Footer 2
-----------------------------------------------------------------------*/
.iq-footer {
  background-color: #07133E;
  border-bottom: 10px solid #32A7C6;
  background-image: url(../static/bg-footer.png);
  background-size: 32rem;
  background-position: right center;
  background-repeat: no-repeat;
}

.iq-footer hr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.iq-footer.gray-footer {
  background: #000;
}

/*Contact*/
.iq-footer .iq-contact .contact-block {
  color: #ffffff;
  display: inline-block;
}

.iq-footer .iq-contact .contact-block .title {
  font-size: 20px;
}

.iq-footer .iq-contact .contact-block span {
  font-size: 20px;
}

.iq-footer .iq-contact .contact-block i {
  color: #32A7C6;
  font-size: 40px;
  line-height: 40px;
  vertical-align: middle;
  margin-right: 15px;
  display: table;
  float: left;
}

.iq-footer .iq-contact .contact-block .content {
  display: table-cell;
}

/*Menu*/
.iq-footer .footer-menu li {
  margin: 0;
}

.iq-footer .footer-menu li a {
  font-weight: 500;
  background: rgba(255, 255, 255, 0);
  padding: 8px 0;
  display: block;
  color: #ffffff;
}

.iq-footer .footer-menu li a:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #32A7C6;
  padding: 8px 15px;
}

/*Popular Posts*/
.iq-footer .iq-post li {
  width: 100%;
  float: left;
  margin-top: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.iq-footer .post-blog {
  display: table-cell;
}

.iq-footer .post-blog a {
  vertical-align: top;
  font-size: 16px;
  color: #fff;
}

.iq-footer .post-blog a:hover {
  color: #32A7C6;
}

.iq-footer .post-blog .iq-date {
  font-size: 13px;
  display: table;
}

.iq-footer .post-blog i {
  font-size: 14px;
}

.iq-footer .iq-post li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.iq-footer .post-img {
  display: table;
  float: left;
  margin-right: 15px;
}

.iq-footer .post-img img {
  vertical-align: top;
  height: 65px;
  width: 65px;
  border-radius: 2px;
}

.iq-footer ul {
  margin-right: 0px;
}

/*Newsletter*/
.iq-footer .input-group-addon {
  padding: 0px;
}

.iq-footer .newsletter-form .input-group .form-control {
  border: none;
  padding: 15px 10px;
}

.iq-footer .newsletter-form .input-group-addon {
  border: none;
}

.iq-footer .newsletter-form .button {
  padding: 11px 20px 11px;
  border-radius: 0px 4px 4px 0px
}

/*Copyright*/
.iq-footer .iq-copyright,
.iq-footer .iq-copyright a {
  color: #ffffff;
}

.iq-footer .iq-copyright a:hover {
  color: #32A7C6;
}

.iq-footer .footer-bottom {
  background: #000000;
}

/*---------------------------------------------------------------------
                            Footer 3
-----------------------------------------------------------------------*/
.iq-footer3 {
  background: #222222;
}

.iq-footer3 .footer-top {
  background: #242424;
}

.iq-footer3 {
  color: #fff;
}

.iq-footer3 hr {
  margin: 0;
  border-top: 0px;
  padding: 0px;
  border-bottom: 1px solid #323232;
}

.iq-footer3 .logo img {
  width: 50px;
}

/*Menu*/
.iq-footer3 .menu li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footer3 .menu li a {
  color: #fff;
}

.iq-footer3 .menu li a:hover {
  color: #32A7C6;
}

.iq-footer3 .office-day li {
  margin-bottom: 10px;
}

.iq-footer3 .office-day li a {
  color: #fff;
}

.iq-footer3 .office-day li a:hover {
  color: #32A7C6;
}


/*Link*/
.iq-footer3 .link li a {
  color: #fff;
}

.iq-footer3 .link li a:hover {
  color: #32A7C6;
}

/*Social Media*/
.iq-footer3 .iq-media-blog li {
  margin: 0 0 0 4px;
}

.iq-footer3 .iq-media-blog li a {
  height: 45px;
  width: 45px;
  font-size: 18px;
  line-height: 45px;
  background: rgba(2, 216, 113, 1.0);
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  float: left;
  border: 1px solid #32A7C6;
}

.iq-footer3 .iq-media-blog li a:hover {
  background: rgba(2, 216, 113, .0);
  color: #32A7C6;
}

/*footer-widget*/
.iq-footer3 .iq-contact li {
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}

.iq-footer3 .iq-contact i {
  color: #4ac3f3;
  float: left;
  display: table-cell;
  width: 30px;
  line-height: 23px;
  font-size: 32px;
}

.iq-footer3 .iq-contact p {
  display: table;
  color: #fff;
  margin-bottom: 0px;
  padding-left: 5px;
}

/*Tweeter*/
.iq-footer3 .owl-carousel .owl-nav {
  opacity: 1;
  top: inherit;
  top: 15%;
  bottom: inherit;
}

.iq-footer3 .owl-carousel .owl-nav .owl-next {
  right: 20%;
}

.iq-footer3 .owl-carousel .owl-nav .owl-prev {
  left: 20%;
}

.iq-footer3 .owl-carousel:hover .owl-nav .owl-prev {
  left: 20%;
}

.iq-footer3 .owl-carousel:hover .owl-nav .owl-next {
  right: 20%;
}

.iq-footer3 .tweet-img {
  float: left;
  display: table;
}

.iq-footer3 .tweet-img img {
  vertical-align: middle;
  width: 70px;
  height: 60px;
}

.iq-footer3 .tweet-info {
  display: table-cell;
  padding-left: 15px;
}

.iq-footer3 .tweet-info a {
  color: #fff;
  font-size: 14px;
  vertical-align: top;
}

.iq-footer3 .tweet-info a:hover {
  color: #32A7C6;
}

.iq-footer3 .tweet-info span {
  font-size: 12px;
  display: table;
  padding-top: 5px;
}

.iq-footer3 .tweet-info i {
  font-size: 14px;
  padding-right: 5px;
}

/*Copyright*/
.iq-footer3 .iq-copyright {
  text-align: right;
}

.iq-footer3 .iq-copyright a {
  color: #32A7C6;
}

.iq-footer3 .iq-copyright a:hover {
  color: #ffffff;
}

/*build*/
.iq-footer3 .build li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footer3 .build li a {
  color: #fff;
}

.iq-footer3 .build li a:hover {
  color: #32A7C6;
}

/*about*/
.iq-footer3 .about li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footer3 .about li a {
  color: #fff;
}

.iq-footer3 .about li a:hover {
  color: #32A7C6;
}

/*Support*/
.iq-footer3 .support li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footer3 .support li a {
  color: #fff;
}

.iq-footer3 .support li a:hover {
  color: #32A7C6;
}

/*Contact*/
.iq-footer3 .contact li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footer3 .contact li a {
  color: #fff;
}

.iq-footer3 .contact li a:hover {
  color: #32A7C6;
}

/*---------------------------------------------------------------------
                            Footer 4
-----------------------------------------------------------------------*/
.iq-footer4 {
  position: relative;
  overflow: hidden;
}

.iq-footer4 .container-fluid {
  padding: 0 90px;
}

.iq-footer4 .iq-video {
  background: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 29px;
  color: #32A7C6;
  float: left;
  border-radius: 100%;
  line-height: 2.1;
  z-index: 9;
  position: relative;
}

.iq-footer4 .iq-video i {
  margin-left: 5px;
}

/*Menu*/
.iq-footer4 .menu li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footer4 .menu li a {
  color: #fff;
}

.iq-footer4 .menu li a:hover {
  color: #000000;
}

/*Link*/
.iq-footer4 .link li a {
  color: #fff;
}

.iq-footer4 .link li a:hover {
  color: #666;
}

/*Social Media*/
.iq-footer4 .iq-media-blog li {
  margin: 0 0 0 4px;
}

.iq-footer4 .iq-media-blog li a {
  margin-right: 5px;
  height: 45px;
  width: 45px;
  font-size: 18px;
  line-height: 45px;
  background: #32A7C6;
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  float: left;
  border: 1px solid #32A7C6;
}

.iq-footer4 .iq-media-blog li a:hover {
  background: #666;
  color: #fff;
}

/*footer-widget*/
.iq-footer4 .iq-contact li {
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}

.iq-footer4 .iq-contact i {
  color: #fff;
  float: left;
  display: table-cell;
  width: 30px;
  line-height: 23px;
  font-size: 32px;
}

.iq-footer4 .iq-contact p {
  display: table;
  color: #fff;
  margin-bottom: 0px;
  padding-left: 5px;
}

/*Copyright*/
.iq-footer4 .iq-copyright {
  text-align: right;
}

.iq-footer4 .iq-copyright a {
  color: #32A7C6;
}

.iq-footer4 .iq-copyright a:hover {
  color: #ffffff;
}

/*---------------------------------------------------------------------
 Footer
-----------------------------------------------------------------------*/
footer {
  padding: 75px 0;
}

footer .iq-subscribe {
  margin-bottom: 100px;
}

footer ul {
  padding: 0;
}

footer .menu li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 15px;
  line-height: 24px;
  padding: 0;
}

footer .menu li a {
  color: #8e989f;
}

footer .menu li a:hover {
  color: rgb(74, 196, 243);
}

footer .iq-contact li {
  font-size: 14px;
  color: #8e989f;
  margin-bottom: 15px;
}

footer .iq-contact i {
  color: #8e989f;
  float: left;
  display: table-cell;
  width: 30px;
  line-height: 23px;
  font-size: 32px;
}

footer .iq-contact p {
  display: table;
  color: #8e989f;
  margin-bottom: 0px;
  padding-left: 5px;
}

footer .contact-bg {
  padding-left: 50px;
}

footer .logo {
  padding-right: 80px;
}

footer .logo img {
  height: 50px;
}

footer .title-fancy .footer-title {
  margin-bottom: 10px;
}


footer.iq-footer .button.sign-btn {
  position: absolute;
  right: 0;
  height: 100%;
}

footer.footer-shap {
  padding-top: 0;
}

footer.footer-shap input {
  margin-bottom: 0;
}

footer.footer-one .widget .iq-contact li {
  display: inline-block;
}

footer.footer-one ul.iq-contact li {
  margin: 0;
}

footer.footer-one ul.iq-contact {
  margin-top: 30px;
}

footer.footer-one ul.iq-contact li:last-child a span {
  padding-left: 20px;
}

footer.footer-one .widget .container {
  position: relative;
  z-index: 1
}

footer.footer-one.footer-shap .footer-top footer.footer-one.footer-shap .sofbox-subscribe .col-sm-12,
footer.footer-one.footer-shap .sofbox-subscribe .col-sm-12,
footer.footer-one.footer-shap .col-sm-6 {
  z-index: 4;
}

footer.footer-one.footer-shap .footer-top {
  padding-top: 200px;
}

footer.footer-one.footer-shap:before {
  content: "";
  display: block;
  height: 100px;
  z-index: 0;
  width: 100%;
}

footer.footer-one .sofbox-subscribe {
  position: relative;
  padding: 30px;
  background: #ffffff;
  margin-top: -200px;
  box-shadow: 0px 0px 45px 5px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

footer.footer-one .sofbox-subscribe:before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 98%;
  z-index: 0;
  border-radius: 4px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0.4;
}

footer.footer-one .sofbox-subscribe:after {
  content: "";
  position: absolute;
  bottom: -16px;
  width: 96%;
  left: 0;
  margin: 0 auto;
  right: 0;
  z-index: 0;
  border-radius: 4px;
  height: 100%;
  background: #fff;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06);
  opacity: 0.4;
}

footer.footer-one ul.iq-contact li:last-child a span:before {
  content: "|";
  position: absolute;
  left: 0;
}

footer.footer-one .widget .textwidget p {
  margin-bottom: 0;
}

footer.footer-one {
  display: inline-block;
  width: 100%;
}

footer.footer-one .footer-top {
  padding-top: 45px;
  padding-bottom: 45px;
  border-top: 1px solid #eff1fe;
}

footer.footer-one .widget {
  background: transparent;
}

footer.footer-one .widget .sofbox-subscribe h4.footer-title {
  color: #1e1e1e !important;
}

footer.footer-one .widget h4.footer-title {
  margin-bottom: 15px;
  color: #ffffff;
}

footer.footer-one .sub-btn {
  position: absolute;
  right: 0;
}

footer.footer-one .sub-btn button {
  padding: 24px 30px;
  border: none;
  cursor: pointer;
  background-color: rgb(74, 196, 243);
}

footer.footer-one .sub-btn button:hover,
.footer-one .sub-btn button:focus {
  outline: none;
}

footer.footer-one .sub-btn button:before {
  content: "\f2c3";
  position: absolute;
  font-family: "Ionicons";
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #ffffff;
}

footer.footer-one input[type=submit] footer.footer-one .info-share li {
  margin-left: 15px;
  margin-right: 0px;
}

footer.footer-one .menu-footer-menu-container .menu {
  margin-bottom: 0;
  padding: 0;
}

footer.footer-one .menu-footer-menu-container .menu li {
  list-style: none;
  display: block;
  margin-right: 10px;
}

footer.footer-one .copyright a:hover,
footer.footer-one .menu-footer-menu-container .menu li a:hover,
footer.footer-one .info-share li a:hover {
  text-decoration: none;
  color: #1e1e1e;
}

footer.footer-one .menu-footer-menu-container .menu li:last-child {
  margin-right: 0;
}

footer.footer-one .copyright-footer {
  position: relative;
}

footer.footer-one .info-share li a {
  color: #ffffff;
}

footer.footer-one input[type=email]:hover,
footer.footer-one input[type=email]:focus {
  border-color: rgb(74, 196, 243);
  padding: 0 125px 0 15px;
}

footer.footer-one input[type=email] {
  background: #ffffff;
  border-color: #eff1fe;
  border-radius: 4px;
  color: #8e989f;
}

.mc4wp-form-fields input[type="submit"] {
  position: absolute;
  right: 10px;
}

footer.footer-one .footer-top .info-share li {
  margin-right: 15px;
}

footer.footer-one .footer-top .info-share li:last-child {
  margin-right: 0px;
}

footer.footer-one ul.iq-contact li a span {
  padding-left: 25px;
  display: table-cell;
  position: relative;
}

footer.footer-one ul.iq-contact li i {
  position: absolute;
  left: 0;
  line-height: 30px;
  font-size: 18px;
  color: #ffffff;
}

footer.footer-one .widget ul.menu li a:before {
  display: none;
}

footer.footer-one .widget ul li a {
  color: #ffffff;
}

footer.footer-one .widget ul li a:hover {
  color: #1e1e1e;
}

footer.footer-one .widget ul.menu li a {
  padding: 0px 0 10px 0px;
  color: #ffffff;
}

footer.footer-one .copyright {
  color: #ffffff;
}

.iq-bg-dark {
  position: relative;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-item .testimonial-info {
  padding: 0;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-item .testimonial-info p {
  font-size: 14px;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  position: relative;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-item .testimonial-info p:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -24px;
  border: 12px solid;
  border-color: rgba(255, 255, 255, 0.2) transparent transparent rgba(255, 255, 255, 0.2);
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-item .testimonial-info .testimonial-name .sub-title {
  font-size: 14px;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-item .testimonial-info .testimonial-name h5 {
  font-size: 18px;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-item .testimonial-info img {
  width: 54px;
  height: 54px;
  border-radius: 90px;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-dots {
  display: none;
}

footer.footer-one .testimonail-widget-menu .owl-carousel .owl-stage-outer {
  background: transparent;
}


footer.footer-two .footer-box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

footer.footer-two .footer-input {
  display: flex;
}

footer.footer-two .widget.footer-logo {
  margin-bottom: 15px;
}

footer.footer-two #menu-about-menu li {
  margin-right: 15px;
}

footer.footer-two #menu-about-menu li:last-child {
  margin-right: 0px;
}

footer.footer-two .widget.footer-logo:nth-child(1) {
  margin-bottom: 0px;
}

footer.footer-two .info-share li {
  margin-right: 30px;
}

footer.footer-two .info-share {
  display: block !important;
  margin-bottom: 30px;
}

footer.footer-two .info-share li:last-child {
  margin-right: 0px;
}

footer.footer-two .widget ul li {
  display: inline-block;
}

footer.footer-two .footer-input .button.sign-btn {
  width: 20%;
}

/*---------------------------------------------------------------------
 Footer Video
-----------------------------------------------------------------------*/
footer .copyright-space {
  margin-top: -30px;
  display: inherit;
}


/*---------------------------------------------------------------------
                          Breadcrumb Inner Page
-----------------------------------------------------------------------*/
.iq-breadcrumb {
  margin: 0px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.iq-breadcrumb-title .title {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 46px;
}

.iq-breadcrumb-title .title span {
  font-family: 'Open Sans', sans-serif;
}

.iq-breadcrumb .breadcrumb {
  background: rgba(255, 255, 255, 1);
  padding: 14px 25px;
  border-radius: 90px;
  display: inline-block;
  position: relative;
  bottom: -32px;
  margin-bottom: 0;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.iq-breadcrumb .breadcrumb li a {
  color: #2c3e50;
}

.iq-breadcrumb .breadcrumb li a i {
  padding-right: 5px;
}

.iq-breadcrumb .breadcrumb li a:hover {
  color: #32A7C6;
}

.iq-breadcrumb .breadcrumb li {
  color: #2c3e50;
  font-size: 16px;
  font-weight: 500;
  float: left;
}

.iq-breadcrumb .breadcrumb li.active {
  color: #32A7C6;
}

.iq-breadcrumb .breadcrumb>li+li:before {
  color: #2c3e50;
}

/*pagination-nav*/
.pagination .page-item.active .page-link {
  background-color: #32A7C6;
  border-color: #32A7C6;
}

.pagination .page-link {
  color: #32A7C6;
}

.pagination .page-link:hover {
  color: #333;
}

/*---------------------------------------------------------------------
                             Blog Page
-----------------------------------------------------------------------*/
.iq-page-blog .iq-blog-box {
  box-shadow: none;
  border: 1px solid #ededed;
}

.iq-blog-box .iq-get-in {
  margin-top: 0;
  box-shadow: none;
}

.iq-post-author {
  position: relative;
  min-height: 130px;
}

.iq-post-author-pic {
  float: left;
}

.iq-post-author a {
  color: #fff;
}

.iq-post-author a:hover {
  text-decoration: underline;
  color: #fff;
}

/* Comments Box */
.iq-comment-list li {
  margin-top: 25px;
  margin-bottom: 0;
  vertical-align: top;
  padding: 0;
}

.iq-comment-list .iq-comments-media {
  padding: 15px 15px 30px;
  border-radius: 0px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: flex-start;
}

.iq-comment-list li:last-child .iq-comments-media {
  border-bottom: none;
  padding-bottom: 0;
}

.iq-comment-list ul {
  padding-left: 60px;
}

.iq-comment-list .iq-comments-photo {
  padding-right: 20px;
}

.iq-comment-list .iq-comments-photo a img {
  width: 80px;
  height: auto;
}

.iq-comment-list .iq-comments-info {
  position: relative;
  flex: 1;
}

.iq-comment-list .iq-comments-info .title {
  margin: 0;
  line-height: 22px;
}

.iq-comment-list .iq-comment-metadata {
  line-height: 22px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.iq-comment-list .iq-comment-metadata a {
  color: #333333;
}

.iq-comment-list .iq-comment-metadata i {
  padding-right: 7px;
  color: #32A7C6;
}

.iq-comment-list .reply {
  position: absolute;
  line-height: 22px;
  margin: 0;
  padding: 2px 16px;
  font-size: 11px;
  right: 0;
  top: 0;
  font-weight: 500;
}

.iq-comment-list .iq-comments-media .iq-comments-info .reply {
  color: #ffffff;
}

.iq-comment-list .iq-comments-info p {
  margin-top: 5px;
  margin-bottom: 0;
}

/*---------------------------------------------------------------------
                            Blog - SideBar
-----------------------------------------------------------------------*/
.iq-post-sidebar {
  height: 100%;
}

.iq-sidebar-widget {
  margin-bottom: 40px;
  padding: 20px 20px;
  border: 1px solid #ededed;
  border-radius: 0px;
}

.iq-sidebar-widget:last-child {
  margin-bottom: 0;
}

.iq-sidebar-widget .iq-widget-title {
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 24px;
}

/*widget-menu*/
.iq-widget-menu {
  position: relative;
}

.iq-widget-menu ul li a {
  font-size: 14px;
  display: block;
  color: #2c3e50;
  position: relative;
}

.iq-widget-menu i {
  padding-top: 4px;
  position: absolute;
  right: 18px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.iq-widget-menu ul li a span {
  padding: 5px 10px 5px 0;
  display: block;
  font-size: 14px;
}

.iq-widget-menu ul li a span:hover {
  text-decoration: none;
  color: #32A7C6;
}

.iq-widget-menu ul li.active {
  border-bottom: none;
}

.iq-widget-menu ul li.hover a {
  color: #32A7C6;
}

.iq-widget-menu ul li.hover a span {
  background: transparent;
  color: #32A7C6;
}

.iq-widget-menu ul ul li a span {
  color: #333333;
}

.iq-widget-menu ul ul li a span:hover {
  color: #32A7C6;
}

.iq-widget-menu ul ul {
  display: none;
  padding-left: 20px;
}

.iq-widget-menu ul ul a {
  display: block;
  color: #333333;
  font-size: 14px;
}

.iq-widget-menu ul ul a:hover {
  color: #32A7C6;
}

.iq-widget-menu ul li.active i {
  transform: rotate(90deg);
}

/*SideBar - Search*/
.iq-sidebar-widget .iq-widget-search {
  position: relative;
}

.iq-sidebar-widget .iq-widget-search input {
  padding-right: 40px;
  box-shadow: none;
  border-radius: 90px;
  color: #2c3e50;
  height: 50px;
  border: 2px solid #f2f2f2;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.iq-sidebar-widget .iq-widget-search input:focus {
  background: #ffffff;
}

.iq-sidebar-widget .iq-widget-search i {
  position: absolute;
  right: 8px;
  color: #32A7C6;
  cursor: pointer;
  padding: 16px 12px;
  font-size: 18px;
}

.iq-sidebar-widget .iq-widget-search input:focus {
  border-color: #32A7C6;
}

/*SideBar - Posts*/
.iq-sidebar-widget .iq-recent-post {
  margin-top: 20px;
}

.iq-sidebar-widget .iq-recent-post .media-body>a {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #2c3e50;
}

.iq-sidebar-widget .iq-recent-post .media-body>a:hover {
  color: #32A7C6;
}

.iq-sidebar-widget .iq-recent-post .media-body span {
  color: #666666;
}

.iq-sidebar-widget .iq-recent-post .media-body span .fa {
  color: #333333;
  margin-right: 5px;
}

/*SideBar - Tags*/
.iq-tags li {
  padding-left: 2px;
  display: inline-block;
  padding-right: 2px;
  margin: 0 0 15px;
}

.iq-tags li a {
  background: transparent;
  color: #666;
  padding: 4px 8px;
  border: 1px solid #666;
  border-radius: 90px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.iq-tags li a:hover {
  border-color: #32A7C6;
  background: none;
  color: #32A7C6;
}

/* Meta - SideBar */
.iq-widget-archives li {
  margin: 10px 0;
}

.iq-widget-archives li a {
  color: #666;
}

.iq-widget-archives li a i {
  font-size: 16px;
  margin-right: 5px;
}

.iq-widget-archives li a:hover {
  margin-left: 5px;
  color: #32A7C6;
}

/*---------------------------------------------------------------------
                            404 Error
-----------------------------------------------------------------------*/
.iq-error .big-text {
  font-size: 180px;
  font-family: 'Open Sans', sans-serif;
  line-height: 180px;
  color: #333333;
}

.iq-error h6 {
  background-color: #32A7C6;
  color: #ffffff;
  display: inline-block;
}

.iq-error .form-inline.iq-subscribe {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.iq-error .iq-subscribe .form-group {
  width: 40%;
  display: inline-block;
}

.iq-error .iq-subscribe .form-control {
  border-radius: 90px;
  height: 50px;
  width: 100%;
  display: inline-block;
  padding-left: 25px;
  box-shadow: none;
  border: none;
  background: #f4f4f4;
}

.sofbox-gradient-bg-top::before,
.sofbox-gradient::before {
  content: "";
  background: -webkit-linear-gradient(top, rgb(175 184 188 / 8%) 0px, rgb(255, 255, 255) 60%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.sofbox-gradient-bg-dark,
.sofbox-gradient-bg-top,
.sofbox-gradient-bg-bottom,
.sofbox-gradient {
  position: relative;
}

.sofbox-gradient-bg-dark:before {
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(50, 119, 247, 0.08)), color-stop(60%, #ffffff));
  background: -moz-linear-gradient(top, rgba(50, 119, 247, 0.08) 80%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(50, 119, 247, 0.08) 80%, #ffffff 100%);
  background: -o-linear-gradient(top, rgba(50, 119, 247, 0.08) 80%, #ffffff 100%);
  background: -ms-linear-gradient(top, rgba(50, 119, 247, 0.08) 80%, #ffffff 100%);
  background: linear-gradient(top, rgba(50, 119, 247, 0.08) 80%, #ffffff 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.sofbox-gradient-bg-bottom {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(50, 119, 247, 0.08)), color-stop(60%, #ffffff));
  background: -moz-linear-gradient(bottom, rgba(50, 119, 247, 0.08) 0, #ffffff 60%);
  background: -webkit-linear-gradient(bottom, rgba(50, 119, 247, 0.08) 0, #ffffff 60%);
  background: -o-linear-gradient(bottom, rgba(50, 119, 247, 0.08) 0, #ffffff 60%);
  background: -ms-linear-gradient(bottom, rgba(50, 119, 247, 0.08) 0, #ffffff 60%);
  background: linear-gradient(bottom, rgba(50, 119, 247, 0.08) 0, #ffffff 60%);
}

ul.iq-list {
  margin: 0px;
}

.resent-post-bg:after {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 220px;
  z-index: -1;
}

.banner-overlap #rev_slider_5_1_forcefullwidth {
  z-index: 0;
}



/*---------------------------------------------------------------------
                           Coming Soon
-----------------------------------------------------------------------*/
.iq-coming .big-text {
  font-size: 80px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  color: #333333;
}

.iq-coming img {
  width: 140px;
}

.iq-coming .form-inline.iq-subscribe {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.iq-coming .iq-subscribe .form-group {
  width: 40%;
  display: inline-block;
}

.iq-coming .iq-subscribe .form-control {
  border-radius: 90px;
  height: 50px;
  width: 100%;
  display: inline-block;
  padding-left: 25px;
  box-shadow: none;
  border: none;
  background: #f4f4f4;
}

.iq-coming .countdown-timer {
  border-radius: 5px;
  margin-bottom: 20px;
  max-width: 300px;
  margin: 50px auto;
}

.iq-coming .countdown-timer h5 {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  padding-top: 10px;
  text-shadow: none;
}

.iq-coming .countdown-timer .timer {
  padding: 10px;
  text-align: center;
  padding-top: 15px;
}

.iq-coming .countdown-timer .timer .timer-wrapper {
  display: inline-block;
  width: 200px;
  height: 50px;
}

.iq-coming .countdown-timer .timer .timer-wrapper .time {
  font-size: 80px;
  font-weight: bold;
  color: #333;
  margin: 0 50px;
  float: left;
}

.iq-coming .countdown-timer .timer .timer-wrapper .text {
  font-size: 20px;
}

#countdown {
  list-style: none;
  margin: 50px 0;
  padding: 0;
  display: block;
  text-align: center;
  display: inline-block;
}

#countdown li {
  display: inline-block;
}

#countdown li span {
  font-size: 50px;
  font-weight: 800;
  line-height: 80px;
  margin: 0 30px;
}

#countdown li.seperator {
  font-size: 50px;
  line-height: 40px;
  vertical-align: top;
  margin-top: 15px;
}

#countdown li p {
  color: #a7abb1;
  font-size: 20px;
}

/*---------------------------------------------------------------------
                           jarallax
-----------------------------------------------------------------------*/
.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax>.jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit:cover; ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*---------------------------------------------------------------------
                           TERMS OF SERVICE
-----------------------------------------------------------------------*/
.terms-of-service .btn.btn-link {
  color: #32A7C6;
  text-decoration: none;
  font-weight: 700;
}

.terms-of-service .btn.btn-link.collapsed {
  color: #333;
}

/*---------------------------------------------------------------------
                               OWL Carousel
-----------------------------------------------------------------------*/
.owl-carousel .owl-nav {
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 999;
}

.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  left: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
}

.owl-carousel .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  right: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
}

.owl-carousel .owl-nav i {
  font-size: 24px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 42px;
  padding-left: 0px;
  display: inline-block;
  color: #fff;
  background: #e7e7e7;
  font-weight: normal;
  text-align: center;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.owl-carousel .owl-nav i:hover {
  background: #32A7C6;
  color: #fff;
}

/* Dots */
.owl-carousel .owl-controls .owl-dot {
  margin-top: 20px;
  display: inline-block;
}

.owl-carousel .owl-dots {
  position: relative;
  width: 100%;
  display: inline-block;
  text-indent: inherit;
  text-align: center;
  cursor: pointer;
}

.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background: #333333;
  display: inline-block;
  border-radius: 30px;
  margin: 0px 3px;
  height: 10px;
  width: 10px;
  border: 1px solid #333333;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background: #32A7C6;
  border: 1px solid #32A7C6;
}

.owl-carousel.owl-theme .owl-dots .owl-dot.active span {
  background: #32A7C6;
  border: 1px solid #32A7C6;
}

/* Arrow-1 */
.owl-carousel.arrow-1 {
  overflow: hidden;
}

.owl-carousel.arrow-1 .owl-nav {
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50% !important;
  margin-top: -20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  width: 100%;
  cursor: pointer;
}

.owl-carousel.arrow-1 .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-indent: inherit;
  left: -44px;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
}

.owl-carousel.arrow-1 .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-indent: inherit;
  right: -44px;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
}

.owl-carousel.arrow-1 .owl-nav i {
  font-weight: normal;
  font-size: 24px;
  color: #fff;
  background: rgba(34, 34, 34, 0.7);
  padding: 0px 12px;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.owl-carousel.arrow-1 .owl-nav i:hover {
  background: #32A7C6;
}

.owl-carousel.arrow-1 .owl-nav .owl-prev {
  left: 0;
  display: inline-grid;
}

.owl-carousel.arrow-1 .owl-nav .owl-next {
  right: 0;
  display: inline-grid;
}

.owl-carousel.arrow-1 .owl-stage-outer {
  overflow: hidden;
}

/*---------------------------------------------------------------------
                            Testimonial
-----------------------------------------------------------------------*/
.iq-testimonial .feedback .iq-avtar {
  width: 60px;
  display: inline-block;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  float: left;
}

.iq-testimonial .feedback .iq-avtar img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.iq-testimonial .feedback .iq-info {
  position: relative;
  background: rgba(255, 255, 255, 1.0);
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 8px 30px -20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 8px 30px -20px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 8px 30px -20px rgba(0, 0, 0, 0.5);
}

.iq-testimonial .feedback .iq-info:before {
  content: "";
  position: absolute;
  z-index: 9;
  bottom: -15px;
  left: 05%;
  height: 0;
  width: 0;
  border-top: 15px solid rgba(255, 255, 255, 1.0);
  border-left: 15px solid transparent;
}

.iq-testimonial .feedback .iq-info.light {
  background: rgba(255, 255, 255, 0.1);
}

.iq-testimonial .feedback .iq-info.light:before {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
}

.iq-testimonial .feedback .iq-info.bg-light {
  background: #f8f8f8;
}

.iq-testimonial .feedback .title {
  font-size: 18px;
}

.iq-testimonial .feedback .avtar-name {
  display: table-cell;
}

.iq-testimonial .feedback p {
  margin-bottom: 0px;
}

.iq-testimonial .feedback .iq-lead {
  font-size: 18px;
}

.iq-testimonial .iq-star i {
  margin-right: 4px;
}

.iq-testimonial .owl-prev,
.iq-testimonial .owl-next {
  float: left;
}

.iq-testimonial .owl-prev i,
.iq-testimonial .owl-next i {
  color: #ddd;
}

.iq-testimonial .owl-prev:hover i,
.iq-testimonial .owl-next:hover i {
  color: #32A7C6;
}

.iq-testimonial .owl-prev .fa-angle-left:before {
  content: "\f177";
  float: left;
}

.iq-testimonial .owl-next .fa-angle-right:before {
  content: "\f178";
  float: left;
  margin-left: 10px;
}

.iq-testimonial .iq-star i {
  margin-right: 4px;
}

.iq-testimonial .owl-controls {
  position: relative;
  width: 100%;
  position: relative;
  top: 30px;
}

/* Testimonial 2 */
.iq-testimonial2 .owl-prev,
.iq-testimonial2 .owl-next {
  float: left;
}

.iq-testimonial2 .owl-prev i,
.iq-testimonial2 .owl-next i {
  color: #ddd;
}

.iq-testimonial2 .owl-prev:hover i,
.iq-testimonial2 .owl-next:hover i {
  color: #32A7C6;
}

.iq-testimonial2 .owl-prev .fa-angle-left:before {
  content: "\f177";
  float: left;
}

.iq-testimonial2 .owl-next .fa-angle-right:before {
  content: "\f178";
  float: left;
  margin-left: 10px;
}

.iq-testimonial2 .feedback .iq-avtar {
  width: 60px;
  display: inline-block;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  float: left;
}

.iq-testimonial2 .feedback .iq-avtar img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.iq-testimonial2 .feedback .iq-info {
  position: relative;
  background: rgba(255, 255, 255, 1.0);
  border: 1px solid #f8f3f3;
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.iq-testimonial2 .feedback .iq-info.brd-none {
  border: none;
}

.iq-testimonial2 .feedback .iq-info.light {
  background: rgba(255, 255, 255, 0.2);
  border: none;
}

.iq-testimonial2 .feedback .iq-info.light:before {
  border-top: 15px solid rgba(255, 255, 255, 0.2);
}

.iq-testimonial2 .feedback .title {
  font-size: 18px;
}

.iq-testimonial2 .feedback p {
  margin-bottom: 0px;
  font-style: italic;
}

.iq-testimonial2 .iq-star i {
  margin-right: 4px;
}

.iq-testimonial2 .owl-controls {
  position: relative;
  width: 100%;
  position: relative;
  top: 30px;
}

.iq-testimonial2 .iq-brd {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.iq-testimonial2 .testi-white {
  background: #ffffff;
  padding: 30px 30px 100px 30px;
}

.iq-testimonial2 .testi-white .feedback .iq-info {
  padding: 20px 0;
}

.iq-testimonial2 img {
  left: 0;
  right: 0;
}

.iq-testimonial2 .feedback {
  padding: 0 200px;
}

/*---------------------------------------------------------------------
                            Pricing Table
-----------------------------------------------------------------------*/
.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.01), 0px 20px 31px 3px rgba(0, 0, 0, 0.01), 0px 8px 20px 7px rgba(0, 0, 0, 0.01);
  display: flex;
  flex-direction: column;
}

@media (min-width:900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width:900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  color: #32A7C6;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px 0 25px;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  color: #666666;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  color: #32A7C6;
  display: block;
  font-size: 32px;
  font-weight: 700;
}

.our-pricing-1 .iq-icon {
  border-radius: 90px;
  display: inline-block;
  height: 86px;
  width: 86px;
  line-height: 86px;
  text-align: center;
  color: #32A7C6;
  background: #f4f4f4;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  margin-bottom: 20px;
}

.our-pricing-1 .iq-icon i {
  font-size: 50px;
}

.our-pricing-1 .iq-icon:hover {
  color: rgb(244, 244, 244);
  background: rgb(74, 196, 243);
}

/* pricing 01 */
.iq-pricing-01 {
  padding: 30px 20px;
  text-align: center;
  z-index: 1;
  position: relative;
  border-radius: 10px;
  margin-top: 0;
  border: 1px solid #f2f2f2;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-pricing-01:hover,
.iq-pricing-01.active {
  margin-top: -5px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #32A7C6;
}

/*---------------------------------------------------------------------
                                Feature
-----------------------------------------------------------------------*/
.iq-feature h6 {
  position: relative;
}

.iq-feature h6::before {
  background: #32A7C6;
  bottom: -15px;
  content: "";
  height: 2px;
  position: absolute;
  width: 50px;
  left: 50%;
  margin-left: -25px;
}

.iq-feature img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
}

.iq-feature .step-img {
  position: relative;
  display: inline-block;
}

.iq-feature .step-number {
  background: #5bc9f4;
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  font-size: 18px;
  position: absolute;
  text-align: center;
  line-height: 43px;
  right: 0;
  top: 0;
  border: 4px solid #ffffff;
}

.iq-feature.stap-left:before {
  position: absolute;
  left: 79%;
  top: 20%;
  content: "";
  width: 174px;
  height: 29px;
}

.iq-feature.stap-right:before {
  position: absolute;
  left: 79%;
  top: 25%;
  content: "";
  width: 174px;
  height: 29px;
}

.get-feature .iq-text-right {
  padding-right: 40%;
}

.get-feature img {
  width: 90%;
  margin-top: -150px;
  margin-bottom: -40%;
  margin-left: 10%;
}

/* Feature 01 */
.iq-feature-01 {
  display: -ms-flexbox;
  display: flex;
}

.iq-feature-01 .icon-box {
  color: #666;
  font-size: 82px;
  padding: 5px 15px;
  margin-right: 15px;
  background: #fff;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-feature-01:hover .icon-box {
  color: #32A7C6;
}

.iq-feature-01 .feature-body {
  -ms-flex: 1;
  flex: 1;
}

/*---------------------------------------------------------------------
                                Extra
-----------------------------------------------------------------------*/
.iq-about {
  position: relative;
  margin-bottom: -243px;
  border-radius: 10px;
}

.soft-about {
  position: relative;
}

.soft-about .box-img1 {
  position: absolute;
  top: 100px;
  width: 400px;
  right: 180px;
}

.soft-about .box-img2 {
  position: absolute;
  right: -50px;
  width: 250px;
  top: -100px;
}

.soft-about .box-img3 {
  position: absolute;
  right: 580px;
  top: -50px;
}

.soft-about .box-img4 {
  position: absolute;
  right: 580px;
  top: 100px;
}

.soft-about .box-img5 {
  position: absolute;
  right: 0;
  top: 0;
}

.soft-about .box-img6 {
  position: absolute;
  top: 100px;
  width: 400px;
  right: 180px;
}

.about-me img {
  width: 50%;
}

.box-mail img {
  width: 90%;
  margin-bottom: -200px;
  margin-top: -138px;
}

.life-work .iq-software-demo-1 {
  position: absolute;
  top: 100px;
  height: 50%;
  width: 40%;
  right: 0;
}

.life-work img {
  -webkit-box-shadow: -97px -94px 0px -48px rgba(208, 240, 252, 1);
  -moz-box-shadow: -97px -94px 0px -48px rgba(208, 240, 252, 1);
  box-shadow: -97px -94px 0px -48px rgba(208, 240, 252, 1);
}

.life-work-1 img {
  width: 90%;
  border-radius: 10px;
}

.iq-tool-feature {
  margin-top: -100px;
  z-index: -1;
}

/*************************************
Feature 1 
**************************************/
.iq-feature1 .iq-blog {
  top: 0px;
  position: relative;
  background: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  border-bottom: 2px solid rgba(2, 216, 113, 0);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.iq-feature1 .iq-blog .content-blog {
  display: inline-block;
  width: 100%;
}

.iq-feature1 .iq-blog i {
  margin-right: 25px;
  color: #32A7C6;
  font-size: 50px;
  float: left;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-feature1 .iq-blog h5 {
  display: table;
  position: relative;
  text-transform: none;
  transition: all 0.3s ease-out 0s;
  padding-top: 5px;
}

.iq-feature1 .iq-blog:hover,
.iq-feature1 .iq-blog.active {
  top: -4px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 2px solid rgba(74, 196, 243, 0.9);
}

/*************************************
Feature 10
**************************************/
.iq-feature10 .left {
  width: 76px;
  height: 76px;
  float: left;
  margin-right: 20px;
  text-align: center;
  border: 1px solid #f5f5f5;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.iq-feature10 .left i {
  color: #32A7C6;
  font-size: 36px;
  background: #f3f3f3;
  display: block;
  margin: 7px 10px 10px 7px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.iq-feature10 .right {
  display: table-cell;
  margin-top: 10px;
  vertical-align: top;
}

.iq-feature10 .right p {
  margin-top: 0px;
}

.iq-feature10:hover .left,
.iq-feature10:hover .left i {
  border-color: #32A7C6;
}

.iq-feature10:hover .left i {
  background: #32A7C6;
  color: #ffffff;
}

.iq-feature10 .brd {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.right-side .iq-feature10 .left {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.right-side .iq-feature10 .right {
  text-align: right;
}


.iq-shadow {
  border: 1px solid #f3f4f7;
}

.iq-shadow i {
  font-size: 44px;
  padding: 20px;
  color: #32A7C6;
}

.iq-shadow .iq-font-white {
  color: #fff;
}



/*************************************
Teams 2 
**************************************/
.iq-team2 .team-content {
  width: 220px;
  height: 220px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 10px;
  overflow: hidden;
  display: inline-block;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.2);
  -webkit-box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.2);
  -ms-box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-team2 .team-content img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 100px;
  height: 100;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-team2 .team-social {
  position: relative;
}

.iq-team2 .team-social li {
  display: inline-block;
}

.iq-team2 .team-social li a {
  padding: 0 5px;
  font-size: 15px;
  background: rgba(255, 255, 255, 1.0);
  width: 36px;
  height: 36px;
  line-height: 36px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
  color: #32A7C6;
  border: 1px solid #ffffff;
}

.iq-team2 .team-social li a:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0);
}

.iq-team2 .avtar-name a:hover {
  color: #222222;
}


/*----------------------
Pricing 5
----------------------*/
.iq-pricing-5 {
  padding: 30px 20px;
  text-align: center;
  z-index: 1;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 0;
  border: 1px solid #f2f2f2;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-pricing-5:hover,
.iq-pricing-5.active {
  margin-top: -5px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #32A7C6;
}



/*---------------------------------------------------------------------
                            Footer 3
-----------------------------------------------------------------------*/
.iq-footerr {
  background: #fff;
}

.iq-footerr .footer-top {
  background: #242424;
}

.iq-footerr {
  color: #222222;
}

.iq-footerr hr {
  margin: 0;
  border-top: 0px;
  padding: 0px;
  border-bottom: 1px solid #3333;
}

.iq-footerr .logo img {
  width: 50px;
}

/*Menu*/
.iq-footerr .menu li {
  display: block;
  border-bottom: 0px;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 0;
}

.iq-footerr .menu li a {
  color: #222222;
}

.iq-footerr .menu li a:hover {
  color: #32A7C6;
}

.iq-footerr .office-day li {
  margin-bottom: 10px;
}

.iq-footerr .office-day li a {
  color: #222222;
}

.iq-footerr .office-day li a:hover {
  color: #32A7C6;
}


/*Link*/
.iq-footerr .link li a {
  color: #222222;
}

.iq-footerr .link li a:hover {
  color: #32A7C6;
}



/*footer-widget*/
.iq-footerr .iq-contact li {
  font-size: 14px;
  color: #222222;
  margin-bottom: 15px;
}

.iq-footerr .iq-contact i {
  color: #4ac3f3;
  float: left;
  display: table-cell;
  width: 30px;
  line-height: 23px;
  font-size: 32px;
}

.iq-footerr .iq-contact p {
  display: table;
  color: #222222;
  margin-bottom: 0px;
  padding-left: 5px;
}




/*************************
Tab
*************************/
.iq-tab .nav-pills {
  padding: 8px;
  border-radius: 900px;
}

.iq-tab .nav-item {
  width: 100%;
}

.iq-tab .nav-item a {
  color: #f0f0f0;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 10px;
  margin-bottom: 8px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  border: inherit;
}

.iq-footerr .link li img {
  height: 50px;
  margin-right: 10px;
}

.iq-tab .nav-pills .nav-link {
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 2px;
}

.iq-tab .nav-pills .nav-link.active,
.iq-tab .nav-pills .show>.nav-link,
.iq-tab .nav-pills .nav-link:hover {
  background: rgba(125, 210, 243, 0.7);
  color: #ffffff;
}

.iq-tab.horizontal .nav-item {
  width: 16%;
  text-align: center;
  margin-right: 15px;
}

.iq-tab.horizontal .nav-item a {
  padding: 15px 10px;
  margin-bottom: 0;
  border-radius: 10px;
}

.iq-tab.horizontal .nav-item a i {
  font-size: 44px;
  display: block;
  margin-bottom: 10px;
}


.service-box {
  padding: 20px;
  border: 1px solid transparent;
}

.service-box:hover {
  border: 1px solid #3333;
}

.media.service-box i {
  font-size: 50px;
  margin-right: 20px;
  color: #4ac3f3;
}


.animationnew-shap {
  position: absolute;
  top: 0%;
  right: 0;
}

.animation-shap {
  position: absolute;
  top: 0%;
  left: 0;
}

.animation-shap {
  position: absolute;
  top: 0%;
  left: 0;
}

.animation-shap .shap-bg,
.animationnew-shap .shap-bg {
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 515px;
  height: 515px;
  margin: auto;
  position: relative;
  background-color: #32A7C6;
  background-image: linear-gradient(-45deg, #32A7C6 0%, #32A7C6 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: one-animated 5s infinite;
  overflow: hidden;
}

@keyframes one-animated {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }

  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }

  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }

  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}

@keyframes two-animated {
  0% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }

  25% {
    border-radius: 40% 80% 30% 90% / 72% 65% 35% 28%;
  }

  50% {
    border-radius: 65% 35% 45% 55% / 22% 48% 52% 78%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }

  75% {
    border-radius: 24% 76% 10% 90% / 44% 68% 32% 56%;
  }
}

@keyframes three-animated {
  0% {
    border-radius: 12% 88% 40% 40% / 20% 15% 85% 80%; box-shadow:15px 15px 50px rgba(0, 0, 0, 0.2);
  }

  25% {
    border-radius: 72% 28% 30% 90% / 15% 46% 54% 85%;
  }

  50% {
    border-radius: 12% 88% 40% 40% / 20% 15% 85% 80%; box-shadow:-10px -5px 50px rgba(0, 0, 0, 0.2);
  }

  75% {
    border-radius: 18% 82% 10% 90% / 24% 68% 32% 76%;
  }
}


.iq-badge {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: normal;
  padding: 10px;
  margin-bottom: 15px;
}

.iq-fancy-boxnew {
  padding: 40px 15px 25px 15px;
  overflow: hidden;
  position: relative;
  background: #fff;
}

.iq-fancy-boxnew .iq-icon {
  border-radius: 90px;
  display: inline-block;
  height: 86px;
  width: 86px;
  line-height: 86px;
  text-align: center;
  color: #fff;
  background: #32A7C6;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-fancy-boxnew .iq-icon i {
  font-size: 46px;
}

.iq-fancy-boxnew .fancy-content h5 {
  z-index: 9;
  position: relative;
}

.iq-works-imgs {
  width: 100%;
}

.iq-fancy-boxnew.text-center .icon-bg {
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  margin: auto;
  position: relative;
  background-color: #32A7C6;
  background-image: linear-gradient(-45deg, #32A7C6 0%, #32A7C6 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: one-animated 10s infinite;
  overflow: hidden;
}

.squre-effect-top {
  margin-top: -35px;
}

.squre-effect-bottom {
  margin-top: -35px;
}

.squre-effect {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 150px 0px;
  margin-bottom: 30px;
}

.squre-effect .icon-box .img-effect i {
  display: inline-block;
  line-height: normal;
  font-size: 45px;
}

.squre-effect .icon-box {
  padding: 30px;
  background: rgb(255, 255, 255);
  position: relative;
  z-index: 1;
  border-radius: 4px;
}

.squre-effect:hover::before {
  transform: rotate(12deg);
}

.squre-effect::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(-20deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29) 100%);
  z-index: -1;
  transition: all 0.5s ease-in-out 0s;
  border-radius: 4px;
  transform: rotate(0deg);
}



.future-services {
  padding: 60px 10px 0px;
}

.future-services .future-img {
  margin-bottom: 15px;
}

.future-services h4 {
  margin-bottom: 10px;
}

.services-list {
  margin: 0 0 -120px;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 100%;
}

.services-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
}

.services-list li.one {
  position: absolute;
  left: 30px;
  bottom: 0;
}

.services-list li.two {
  position: absolute;
  right: 100px;
  bottom: 0;
}

.services-page .future-services {
  padding: 30px 10px 0px;
}

.search-btn input[type=text] {
  padding: 10px;
  width: 50%;
  font-size: 16px;
  border-radius: 4px;
  color: #333333;
  border: none;
}

.search-btn button {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: 16px;
  border-radius: 4px;
  background: #333333;
  font-size: 17px;
  border: none;
  cursor: pointer;
  color: #fff;
}

.search-btn button:focus {
  outline: none;
}

.search-btn button:hover {
  background: #fff;
  color: #333333;
}

/*---------------------------------------------------------------------
                   Career details
-----------------------------------------------------------------------*/
.career-details-box {
  background: #ffffff;
  border: 1px solid rgba(30, 30, 30, 0.07);
  padding: 30px 15px !important;
  border-radius: 4px;
}

.apply-box {
  padding: 30px 15px !important;
  border: 1px solid rgba(30, 30, 30, 0.07);
  border-top: none;
}

.cfield.upload-file input {
  padding: 0;
}

/*fancy list style 3*/
.fancy-service-list .fancy-service-detail {
  margin: 15px 0;
}

.fancy-service-list {
  box-shadow: 0px 20px 90px 0px rgba(0, 0, 0, 0.15);
  padding: 45px 30px;
}

.fancy-service-list .fancy-service-detail .iq-img {
  border-radius: 100%;
  position: relative;
  border: 20px solid #f3f7fd;
  background: #e7effa;
  text-align: center;
  height: 200px;
  width: 200px;
  margin: 0 auto 30px;
  line-height: 160px;
}

.fancy-service-list .fancy-service-detail .iq-img i {
  color: #ffffff;
  position: absolute;
  height: 37px;
  width: 37px;
  border-radius: 90px;
  right: 0;
  top: -20px;
  font-size: 16px;
  line-height: 37px;
  background: linear-gradient(to right, var(--linear-color-1) 0%, var(--linear-color-2) 51%, #016b97cc 100%);
}

.fancy-service-list .fancy-service-detail .iq-img img {
  width: auto;
  margin: 0 auto;
}

.fancy-list-box {
  display: flex;
}

.fancy-list-box img.fancy-list-img {
  margin-right: 30px;
}

/*feature circle*/
.sofbox-feature-circle {
  position: relative;
  overflow: hidden;
}

.sofbox-feature-circle .main-circle {
  margin: 0 auto;
  text-align: center;
}

.sofbox-feature-circle .main-circle img.fancy-list-img {
  margin-top: 25%;
}

.sofbox-feature-circle .dot-circle {
  border-radius: 100%;
  height: 950px;
  width: 950px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}

.sofbox-feature-circle .effect-circle {
  border-radius: 100%;
  height: 600px;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}

.sofbox-feature-circle .sofbox-img ul li .feature-info {
  text-align: center;
  height: 136px;
  border-radius: 100%;
  background: #ffffff;
  width: 136px;
  padding: 20px;
}

.sofbox-feature-circle .sofbox-img ul li {
  position: absolute;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(1) {
  left: 3%;
  top: 50%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(2) {
  left: 20%;
  top: 15%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(3) {
  right: 20%;
  top: 15%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(4) {
  right: 3%;
  top: 58%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(5) {
  left: 20%;
  bottom: 15%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(6) {
  left: 24%;
  top: 40%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(7) {
  right: 40%;
  top: 25%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(8) {
  right: 20%;
  top: 45%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(9) {
  right: 20%;
  bottom: 10%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(1) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(2) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(3) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(4) .feature-info {
  height: 120px;
  width: 120px;
}

.sofbox-feature-circle .sofbox-img ul li .feature-info .feature-img {
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: initial;
}

/*verticle tab*/
.custom-tab.verticaltab3 .nav.nav-pills .nav-link {
  position: relative;
}

.custom-tab.verticaltab3 .nav.nav-pills .nav-link:after {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: -4px;
  bottom: 0;
  background: #ffffff;
}

.custom-tab.verticaltab3 .nav.nav-pills .nav-link:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: -4px;
  top: 0;
  background: #ffffff;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] {
  box-shadow: none;
  border-radius: 0;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] li.nav-item {
  padding: 0 30px 20px;
  width: 100%;
  vertical-align: middle;
  float: left;
  margin-bottom: 0px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] li.nav-item:last-child {
  margin-bottom: 0;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link {
  border-left: 4px solid #32A7C6;
  padding: 15px 25px;
  border-radius: 0px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 50px 0px;
  transition: all 0.5s ease-in-out 0s;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link img {
  margin-right: 15px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link p {
  display: none;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link h4 {
  font-size: 18px;
  margin-top: 10px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:last-child,
.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] li.nav-item:last-child {
  margin-bottom: 0;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover h4 {
  color: #4ac3f3;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active {
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 29px 60px 0px rgba(143, 144, 145, 0.39);
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active h4 {
  margin-top: -10px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover {
  background: #ffffff;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active p {
  display: block;
  color: #8e989f;
}

/* .custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active:before, .custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover:before { display: none; } */
.custom-tab.verticaltab3 .tab-pane.fade.active.show {
  display: block;
}

.custom-tab.verticaltab3 .tab-pane.fade {
  display: none;
}

/*counter horizontal*/
.iq-timer.counter-horizontal .timer-details .timer,
.iq-timer.counter-horizontal .timer-details .total-cust,
.iq-timer.counter-horizontal .timer-details .price {
  font-size: 35px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}

.iq-timer.counter-horizontal .timer-details i {
  font-size: 30px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}

.iq-timer.counter-horizontal .timer-details p {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}

.iq-timer.counter-horizontal .timer-details {
  color: #ffffff;
  padding: 10px;
}

.iq-timer.counter-horizontal .border-effect {
  width: 30px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  margin: 0 15px;
}

.iq-timer.counter-horizontal .border-effect .title-saas {
  position: relative;
  background: #000000;
  height: 2px;
  width: 30px;
  overflow: hidden;
}

.iq-timer.counter-horizontal {
  text-align: center;
  margin: 0 auto 30px;
  border-radius: 4px;
}

.iq-timer.counter-horizontal .border-effect .title-saas::after,
.iq-timer.counter-horizontal .border-effect .title-saas::before {
  content: "";
  position: absolute;
  background: #ffffff;
  width: 3px;
  height: 2px;
  left: 0;
  z-index: 1;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: H-MOVE-BG;
  animation-name: H-MOVE-BG;
}

@-webkit-keyframes H-MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
  }
}

@keyframes H-MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
  }
}

.tab-img-shadow img {
  box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.1);
}

.popup-absolute-images {
  position: absolute;
  bottom: 50%;
  width: 100%;
  -webkit-animation: bounces 5s infinite alternate;
}

.popup-absolute-images .img-two {
  right: 0;
  left: auto;
  bottom: 0;
  z-index: 2;
}

.popup-absolute-images .img-one {
  left: 0;
  z-index: 2;
}

.popup-absolute-images .img-three {
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  bottom: -250px;
}

.popup-absolute-images .img-four {
  right: 20%;
  text-align: center;
  top: -250px;
  z-index: -1;
}

.popup-absolute-images.align-left,
.popup-absolute-images.align-right {
  top: 50%;
}

.popup-absolute-images.align-left .img-one {
  left: 0;
  top: 0;
}

.popup-absolute-images.align-left .img-two {
  left: 10%;
  top: 15%;
}

.popup-absolute-images.align-left .img-three {
  left: 0%;
  top: 45%;
}

.popup-absolute-images.align-left .img-four {
  left: -10%;
  top: 80%;
}

.popup-absolute-images.align-right .img-one {
  right: 0;
  top: 0;
}

.popup-absolute-images.align-right .img-two {
  right: 10%;
  top: 15%;
}

.popup-absolute-images.align-right .img-three {
  right: 0%;
  top: 45%;
}

.popup-absolute-images.align-right .img-four {
  right: -10%;
  top: 80%;
}

.custom-tab.horizontaltab .tab-pane.fade.active.show {
  display: block;
}

.custom-tab.horizontaltab .tab-pane.fade {
  display: none;
}

a.nav-link.active.show {
  background: transparent;
}

.custom-tab.horizontaltab .nav.nav-pills {
  display: block;
  width: 100%;
  overflow: hidden;
}

.custom-tab.horizontaltab .nav.nav-pills li {
  display: inline-block;
  width: auto;
}

.custom-tab.horizontaltab .nav-pills .nav-link .tab-title {
  display: inline-block;
}

.custom-tab.horizontaltab .show_content {
  width: 100%;
  text-align: left;
}

.custom-tab.horizontaltab .nav-pills .nav-link.active,
.custom-tab.horizontaltab .nav-pills .show>.nav-link,
.custom-tab.horizontaltab .nav-pills .nav-link:hover {
  background-image: linear-gradient(to right, rgb(0 201 213) 0%, rgb(0 202 214) 100%);
}

.custom-tab.horizontaltab .nav-pills .nav-link.active .tab-title,
.custom-tab.horizontaltab .nav-pills .show>.nav-link .tab-title,
.custom-tab.horizontaltab .nav-pills .nav-link:hover .tab-title {
  color: #ffffff;
}

.page-id-1790 .content-area .site-main,
.page-id-1584 .content-area .site-main {
  padding: 0;
}

.page-id-1790 .iq-breadcrumb-one,
.page-id-1584 .iq-breadcrumb-one {
  display: none;
}

.fancy-list-box .fancy-list-img {
  margin-right: 30px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: #ffffff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 30px;
}

.fancy-list-box .service-detail {
  flex: 1;
  display: inline-block;
  vertical-align: middle;
}

.fancy-list-box .fancy-list-img:before {
  border-radius: 90px;
  line-height: 80px;
  width: 80px;
  height: 80px;
  text-align: center;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-top-left-radius: 0px;
}

.fancy-list-box .fancy-list-img.purple-hover:before {
  background-image: -moz-linear-gradient(90deg, rgb(1, 106, 150) 0%, rgb(22, 97, 127) 100%);
  background-image: linear-gradient(135deg, rgb(27, 151, 180) 0%, rgb(11, 6, 39) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(135, 154, 251) 0%, rgb(23, 51, 195) 100%);
}

.fancy-list-box .fancy-list-img.org-hover:before {
  background-image: -moz-linear-gradient(90deg, rgb(230, 166, 160) 0%, rgb(255, 106, 92) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(230, 166, 160) 0%, rgb(255, 106, 92) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(230, 166, 160) 0%, rgb(255, 106, 92) 100%);
}

.fancy-list-box .fancy-list-img.green-hover:before {
  background-image: -moz-linear-gradient(90deg, rgb(149, 239, 218) 0%, rgb(100, 216, 190) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(149, 239, 218) 0%, rgb(100, 216, 190) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(149, 239, 218) 0%, rgb(100, 216, 190) 100%);
}


.full-img-left img {
  max-width: none !important;
  left: -60%;
  position: relative;
}

.full-img-left-two img {
  max-width: none !important;
  left: -40%;
  position: relative;
}

.full-img-effect img {
  max-width: none !important;
  left: -39%;
  position: relative;
}

.full-img-right img {
  max-width: none !important;
  right: 0;
  position: relative;
}

@media (max-width:1024px) {
  .full-img-right img {
    max-width: 100% !important;
  }
}

.job-potal-img img {
  max-width: none !important;
  position: absolute;
  top: -150px;
}

/*---------------------------------------------------------------------
                Rating Effect (Feedback)
-----------------------------------------------------------------------*/
.owl-rating {
  padding: 30px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}

.owl-rating .iq-star {
  margin-bottom: 15px;
  display: inline-block;
}

.owl-rating .iq-star i {
  color: #fbdc00;
  margin-right: 5px;
}

.owl-rating .iq-star i:last-child {
  margin: 0;
}

/*---------------------------------------------------------------------
              Curve shap effect
-----------------------------------------------------------------------*/
.cure-shap-right:before {
  content: "";
  width: 250px;
  height: 100%;
  top: 0;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  position: absolute;
  left: -20%;
}

.cure-shap-right {
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  border-top-right-radius: 50px;
  position: relative;
}

.cure-shap-left:before {
  content: "";
  width: 250px;
  height: 100%;
  top: 0;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  position: absolute;
  right: -20%;
}

.cure-shap-left {
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  border-top-left-radius: 50px;
  position: relative;
}

.page-id-2468 .iq-breadcrumb-one {
  display: none;
}

.page-id-2468 .content-area .site-main {
  padding-top: 0;
}

/*---------------------------------------------------------------------
              Fancy Services
-----------------------------------------------------------------------*/
.fancy_service {
  border-radius: 5px;
  border: 1px solid #f4f8ff;
  box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 60px;
  padding: 0;
  display: block;
  float: left;
  width: 100%;
}

.fancy_service .fancy-info {
  float: left;
  width: 70%;
  padding: 30px;
}

.fancy_service .fancy_img {
  position: absolute;
  right: -70px;
  bottom: -50px;
}

.fancy_service .fancy_block {
  display: block;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #ffffff;
}

.fancy_service .bg_img {
  opacity: 0;
  position: absolute;
  right: -10px;
  top: -26px;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-animation: bounces 2s infinite alternate;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.fancy_service .fancy_img {
  width: 50%;
  float: left;
  z-index: -1;
}

.fancy_service:hover .bg_img {
  opacity: 1;
}

/*---------------------------------------------------------------------
                Services box effect
-----------------------------------------------------------------------*/
.squre-effect-top {
  margin-top: -35px;
}

.squre-effect-bottom {
  margin-top: -35px;
}

.squre-effect {
  position: relative;
  box-shadow: 0px 0px 150px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.squre-effect .icon-box .img-effect i {
  display: inline-block;
  line-height: normal;
  font-size: 45px;
}

.squre-effect .icon-box {
  padding: 30px;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}

.squre-effect:hover:before {
  transform: rotate(12deg);
}

.squre-effect:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
  background-image: -webkit-linear-gradient(-20deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29) 100%);
  background-image: -ms-linear-gradient(-20deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29) 100%);
  transform: rotate(0deg);
  z-index: -1;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-radius: 4px;
  transform: rotate(0deg);
}

.org-gradiant-bg {
  background-image: -moz-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
  background-image: -webkit-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
  background-image: -ms-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
}

/*---------------------------------------------------------------------
               Frequently Asked Questions
-----------------------------------------------------------------------*/
.iq-accordion.career-style.faq-style.style-two .iq-accordion-block.accordion-active .accordion-title:before,
.iq-accordion.career-style.faq-style.style-two .accordion-title:before {
  background: transparent;
  color: #7ca0f4;
  box-shadow: none;
}

.iq-accordion.career-style.faq-style.style-two .iq-accordion-block .accordion-details p {
  padding-left: 74px;
  padding-top: 0;
  padding-bottom: 15px;
}





/*---------------------------------------------------------------------
              Fancy box
-----------------------------------------------------------------------*/
.Fancy-box-position .fancy-style-five {
  position: absolute;
  top: 0;
  background: #ffff;
  right: 0;
  width: 360px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.Fancy-box-position .fancy-style-five i {
  color: #57bfb1;
}

.Fancy-box-position .fancy-style-five:hover {
  right: 20px;
}

.fancy-style-five i {
  font-size: 34px;
}

.fancy-style-five [class*=" flaticon-"]:before {
  font-size: 30px;
}

.fancy-style-five.one {
  top: 12%;
}

.fancy-style-five.two {
  top: 38%;
}

.fancy-style-five.three {
  top: 65%;
}

.fancy-style-five {
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 8px;
  background: #ffff;
  margin-bottom: 30px;
}

.page-id-2747 .iq-breadcrumb-one {
  display: none;
}

.page-id-2747 .content-area .site-main {
  padding-top: 0;
  padding-bottom: 120px;
}

.page-id-2254 .iq-breadcrumb-one {
  display: none;
}

.page-id-2254 .content-area .site-main {
  padding-top: 0;
  padding-bottom: 120px;
}

/*---------------------------------------------------------------------
              Fancy box five
-----------------------------------------------------------------------*/
.hover-slide-effect {
  position: relative;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
}

.hover-box-effect {
  padding: 55px 15px 55px 65px;
  position: relative;
  box-shadow: 0px 3px 60px 0px rgba(85, 128, 255, 0.05);
  margin: 0px 15px 50px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.hover-box-effect i {
  font-size: 36px;
}

.hover-box-effect .title {
  margin-bottom: 10px;
}

.hover-box-effect:hover {
  -webkit-box-shadow: 5px 9px 60px 0px rgba(82, 119, 229, 0.22);
  box-shadow: 5px 9px 60px 0px rgba(82, 119, 229, 0.22);
}

.hover-box-effect .icon-box {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background: #000;
  line-height: 70px;
  text-align: center;
  position: absolute;
  left: -30px;
}


/*---------------------------------------------------------------------
 animation rotate-effect-two
-----------------------------------------------------------------------*/
/* .card-img-effect .main-img img{transform: rotate(-25deg); } */
.card-img-effect .main-img {
  z-index: 2;
  position: relative;
}

.card-img-effect .sub-img img {
  position: absolute;
  bottom: 105px;
  right: 21%;
  z-index: 0;
}

.rotate-effect-two {
  -webkit-animation: rotate-effect-two 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: rotate-effect-two 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes rotate-effect-two {
  0% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes rotate-effect-two {
  0% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.rotate-effect-one {
  -webkit-animation: rotate-effect-one 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: rotate-effect-one 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes rotate-effect-one {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes rotate-effect-one {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

/*---------------------------------------------------------------------
  Title
-----------------------------------------------------------------------*/
.main-left {
  margin-bottom: 60px !important;
}

.title-box {
  margin-top: -5px;
}

.sofbox-contact .title-box.text-center {
  margin-bottom: 30px
}

.title-box.text-left p {
  margin-bottom: 0px
}

.title-box h2 {
  margin-bottom: 15px;
}

.title-box-space,
.title-box.text-left.title-box-space {
  margin-bottom: 30px;
}

.title-box .title-design {
  font-weight: normal;
  color: #8e989f;
  display: inline-block;
  position: relative;
}

.title-box.text-left {
  margin-bottom: 40px;
}

.title-box span.title-design.sub-title {
  color: #ffffff;
}

.title-box.text-white h2 {
  color: #ffffff;
}

.title-box span.title-design.sub-title:before {
  background: #ffffff;
  content: "";
}

.title-box.text-center {
  margin-bottom: 80px;
}

.title-box.title-shadow {
  margin-bottom: 15px;
}

.title-box .subtitle {
  position: relative;
}

/*---------------------------------------------------------------------
Custom Tab
-----------------------------------------------------------------------*/
.custom-tab-chat .tab-pane.fade.active.show {
  display: block;
}

.custom-tab-chat .tab-pane.fade {
  display: none;
}

.custom-tab-chat .nav.nav-pills {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
}

.custom-tab-chat .nav.nav-pills:after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: rgb(2, 13, 30, 0.2);
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 1;
}

.custom-tab-chat .nav.nav-pills li {
  display: inline-block;
  width: 25%;
  float: left;
  position: relative;
}

.custom-tab-chat .nav.nav-pills li a {
  background: transparent;
  color: #ff6a5c;
  border: none;
  font-size: 16px;
  padding: 10px 35px;
  font-family: var(--body-font-family);
  font-weight: 600;
}

.custom-tab-chat .nav.nav-pills li a:hover:before {
  display: inline-block;
  width: 100%;
  height: 3px;
  background: #1e283c;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}

.custom-tab-chat .nav.nav-pills[aria-baorientation="vertical"] li.nav-item {
  width: 100%;
  vertical-align: middle;
  float: left;
}

.custom-tab-chat .nav-pills .nav-link .tab-title {
  display: inline-block;
  font-family: var(--body-font-family);
  font-size: 16px;
  font-family: var(--body-font-family);
  font-weight: 600;
  color: #8e989f;
}

.custom-tab-chat .nav-pills .nav-link .tab-title:hover {
  color: #1e283c;
}

.custom-tab-chat .nav-pills .nav-link.active .tab-title {
  color: #1e283c;
}

.custom-tab-chat .nav-pills .nav-link.active:before {
  display: inline-block;
  width: 100%;
  height: 3px;
  background: #1e283c;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 2;
}

.custom-tab-chat .show_content {
  width: 100%;
}


.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] {
  box-shadow: none;
  border-radius: 0;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] li.nav-item {
  width: 100%;
  vertical-align: middle;
  float: left;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link {
  padding: 15px;
  border-radius: 0;
  text-align: center;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link h5 {
  font-size: 18px;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link:last-child,
.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] li.nav-item:last-child {
  margin-bottom: 0;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link.active h5,
.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover h5 {
  color: #ffffff;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link.active,
.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover {
  box-shadow: 0px 22px 40px 0px rgba(0, 0, 0, 0.05);
  background: #437eeb;
}

.custom-tab-chat .nav.nav-pills[aria-orientation="vertical"] .nav-link.active:before,
.custom-tab .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover:before {
  display: none;
}

.share-social li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  color: #fff;
  transition: all 0.25s ease-in-out 0s;
}

.share-social li:hover a {
  color: rgb(255, 255, 255);
}

.share-social li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  margin-right: 10px;
  transition: all 0.25s ease-in-out 0s;
  background: rgb(255, 255, 255, 0.28);
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: inline-block !important;
}

.deatils-social span {
  color: rgb(30, 30, 30);
  font-family: Now;
}

.share-social li:hover {
  background-size: 200%;
  background-image: linear-gradient(to right, var(--linear-color-1) 0%, var(--linear-color-2) 51%, #016b97cc 100%);
}


.deatils-social {
  display: flex;
}

.deatils-social .share-tags {
  display: inline-block;
  flex: 1 1 0%;
}

.deatils-social .share-tags li {
  margin-right: 5px;
}

.deatils-social .share-tags li:last-child {
  margin-right: 0px;
}

.deatils-social .share-tags li a {
  text-decoration: none;
  padding: 5px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 12px;
}

.border-bottom {
  border-bottom: 1px solid rgba(30, 30, 30, 0.08);
}

.spliter {
  display: inline-block;
  height: 3px;
  width: 73px;
  clear: both;
  background: #32A7C6;
}

/* Form */
label {
  color: #1e1e1e;
  margin-bottom: 0;
}

input,
input[type=text],
input[type=email],
input[type=search],
input[type=password],
textarea {
  width: 100%;
  float: left;
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
  background: #ffffff;
  border: 1px solid #eff1fe;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #8e989f;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

select {
  border: 1px solid rgba(36, 38, 43, 0.14);
  background: #ffffff;
  line-height: 48px;
  height: 48px;
  padding: 0 10px;
  width: 100%;
  color: #8e989f;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

textarea {
  padding: 0 15px;
  height: 150px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

input:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=search]:focus,
input[type=password]:focus,
textarea:focus,
select:focus {
  border-color: var(--primary-color);
  box-shadow: none;
  outline: none;
}

input::-webkit-input-placeholder {
  color: inherit;
}

input::-moz-placeholder {
  color: inherit;
}

input:-ms-input-placeholder {
  color: inherit;
}

textarea::-webkit-input-placeholder {
  color: inherit;
}

textarea::-moz-placeholder {
  color: inherit;
}

textarea:-ms-input-placeholder {
  color: inherit;
}

input[type=email]::-webkit-input-placeholder {
  color: inherit;
}

input[type=email]::-moz-placeholder {
  color: inherit;
}

input[type=email]:-ms-input-placeholder {
  color: inherit;
}

input[type=submit],
.contact-form .cfield input[type=submit] {
  color: #ffffff;
  background: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  height: 50px;
  padding-left: 15px;
  margin-bottom: 30px;
  line-height: 2 !important;
  border: none;
  position: relative;
  display: inline-block;
  width: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input[type=submit]:hover,
.contact-form .cfield input[type=submit]:hover {
  color: #ffffff;
  background: #1e1e1e;
  outline: none;
}

input[type=checkbox] {
  width: auto;
  margin-right: 10px;
  line-height: 2;
  height: 32px;
}

.comments-area blockquote {
  background: #ffffff;
}

.comments-area table td,
.comments-area table th {
  border: 1px solid #1e1e1e;
}

.wp-block-button__link {
  padding: 10px 30px;
  font-size: 16px;
}

.error {
  color: #ff0000 !important;
}

div.wpcf7-response-output {
  display: none;
}

.wpcf7-form .cfield .wpcf7-not-valid {
  border: 1px solid #f00;
}

.site-content {
  display: inline-block;
  width: 100%;
  float: left;
}

.vc_parallax {
  overflow: hidden !important;
}

div.wpcf7-mail-sent-ok {
  display: block;
  position: absolute;
  left: 23px;
  right: 0;
  bottom: -8px;
  border: none;
  color: #398f14;
}

input,
input.form-control {
  font-size: 14px;
  height: 50px;
  padding-left: 15px;
  margin-bottom: 30px;
  border: 1px solid #ced4da;
  position: relative;
  display: inline-block;
}

/* Audio */
article.hentry.format-audio .consult-detail {
  text-align: left;
}

article.hentry.format-audio .consult-detail p:nth-child(-n+2) {
  display: inline-block;
  margin-bottom: 0;
  padding-top: 30px;
}

article.hentry.format-audio .consult-detail p {
  margin-bottom: 0;
}

article.hentry.format-video .consult-detail p {
  margin-bottom: 0;
}




/*---------------------------------------------------------------------
STICKY
-----------------------------------------------------------------------*/

.modal.modal-sticky.modal-sticky-bottom-right {
  padding: 0 !important;
  max-width: 500px;
  height: auto;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 25px;
  right: 25px;
  margin: 0;
  -webkit-box-shadow: 0 0 60px -15px rgba(0, 0, 0, .2);
  box-shadow: 0 0 60px -15px rgba(0, 0, 0, .2);
  border-radius: .42rem
}

.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog {
  position: static;
  max-width: 500px;
  width: auto;
  margin: 0;
  border-radius: .42rem
}

.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog .modal-content {
  border: 0;
  border-radius: .42rem
}

.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg {
  max-width: 600px
}

.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg .modal-dialog {
  max-width: 600px
}

@media (max-width: 991.98px) {
  .modal.modal-sticky.modal-sticky-bottom-right {
    bottom: 10px;
    right: 10px
  }
}

@media (max-width: 767.98px) {
  .modal.modal-sticky.modal-sticky-bottom-right {
    max-width: 90%
  }
}

.sticky-toolbar {
  width: 46px;
  position: fixed;
  top: 30%;
  right: 0;
  list-style: none;
  margin: 0;
  z-index: 95;
  background: #fff;
  -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top-left-radius: .42rem;
  border-bottom-left-radius: .42rem
}

.flip-icon i {
  font-size: 45px;
}

.feature-flip-box {
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  height: calc(100% - 30px);
}

.feature-flip-box .flipbox-wrapper {
  position: relative;
  z-index: 1;
  min-height: 270px;
  text-align: center;
  perspective: 1000px;
  height: 100%;
}

.feature-flip-box .flipbox-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
}

.feature-flip-box .front-side,
.feature-flip-box .back-side {
  display: block;
  backface-visibility: unset;
  transition: transform 1s ease 0s, visibility 0s ease 0.3s;
  min-height: inherit;
  border-radius: 10px;
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 36px;
  perspective: inherit;
  transform-style: preserve-3d;
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {

  .feature-flip-box .front-side,
  .feature-flip-box .back-side {
    backface-visibility: hidden;
    transition: transform 0.8s cubic-bezier(0.5, 0.2, 0.2, 0.8) 0s;
  }
}

.feature-flip-box .front-side {
  box-sizing: border-box;
  padding: 45px 30px;
  visibility: visible;
  height: 100%;
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box .front-side {
    display: flex;
    flex-direction: column;
    visibility: unset;
  }
}

.feature-flip-box .front-side .flipbox_logo,
.feature-flip-box .front-side .flipbox-title,
.feature-flip-box .front-side .flipbox-details {
  transform-style: inherit;
  perspective: inherit;
  backface-visibility: inherit;
}

.feature-flip-box .front-side .flipbox_logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: translate3d(0px, 0px, 35px) scale(0.95);
}

.feature-flip-box .front-side .flipbox_subtitle {
  transform: translate3d(0px, 0px, 60px) scale(0.68);
}

.feature-flip-box .front-side .flipbox-title {
  padding: 0px 13px;
  margin: 20px 0px 10px;
  box-sizing: border-box;
  transform: translate3d(0px, 0px, 70px) scale(0.86);
}

.feature-flip-box .front-side .flipbox-title img {
  height: 60px;
  object-fit: contain;
}

.feature-flip-box .front-side .flipbox-details {
  padding: 0px 5px;
  font-size: 16px;
  line-height: 1.5 !important;
  transform: translate3d(0px, 0px, 55px) scale(0.86);
}

.feature-flip-box .back-side {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 30px;
  visibility: hidden;
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box .back-side {
    display: flex;
    justify-content: center;
    flex-direction: column;
    visibility: unset;
  }
}

.feature-flip-box .back-side .flipbox_logo,
.feature-flip-box .back-side .flipbox-title,
.feature-flip-box .back-side .flipbox-content {
  transform-style: inherit;
  perspective: inherit;
  backface-visibility: inherit;
}

.feature-flip-box .back-side .flipbox_logo {
  height: 20px;
  transform: translate3d(0px, -10px, 35px) scale(0.86);
}

.feature-flip-box .back-side .flipbox-title {
  padding: 0px 20px;
  margin-bottom: 10px;
  transform: translate3d(0px, 0px, 70px) scale(0.86);
}

.feature-flip-box .back-side .flipbox-content {
  padding: 0px 9px;
  line-height: 1.875;
  transform: translate3d(0px, 0px, 60px) scale(0.86);
}

.feature-flip-box .back-side .softlab_module_button {
  margin-top: 23px;
}

.feature-flip-box .back-side .softlab_module_button a {
  margin-bottom: 0px;
}

.feature-flip-box .flipbox_button {
  padding-top: 10px;
}

.feature-flip-box .flipbox_button::after {
  color: inherit;
}

.feature-flip-box .back-side .flipbox-content a.blue-btn.button {
  color: rgb(255, 255, 255);
  background: rgb(0, 188, 198) !important;
}

.feature-flip-box .back-side .flipbox-content a.blue-btn.button:hover {
  color: rgb(26, 147, 176);
  background: rgb(255, 255, 255) !important;
}

.feature-flip-box.flip-right .front-side {
  transform: rotateY(0deg);
}

.feature-flip-box.flip-right .front-side .flip-icon {
  transform: translate3d(0px, 0px, 55px) scale(0.86);
}

.feature-flip-box.flip-right .back-side {
  transform: rotateY(-180deg);
}

.feature-flip-box.flip-right:hover .front-side {
  visibility: hidden;
  transform: rotateY(180deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-right:hover .front-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-right:hover .back-side {
  visibility: visible;
  transform: rotateY(0deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-right:hover .back-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-left .front-side {
  transform: rotateY(0deg);
}

.feature-flip-box.flip-left .back-side {
  transform: rotateY(180deg);
}

.feature-flip-box.flip-left:hover .front-side {
  visibility: hidden;
  transform: rotateY(-180deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-left:hover .front-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-left:hover .back-side {
  visibility: visible;
  transform: rotateY(0deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-left:hover .back-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-top .front-side {
  transform: rotateX(0deg);
}

.feature-flip-box.flip-top .back-side {
  transform: rotateX(-180deg);
}

.feature-flip-box.flip-top:hover .front-side {
  visibility: hidden;
  transform: rotateX(180deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-top:hover .front-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-top:hover .back-side {
  visibility: visible;
  transform: rotateX(0deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-top:hover .back-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-bottom .front-side {
  transform: rotateX(0deg);
}

.feature-flip-box.flip-bottom .back-side {
  transform: rotateX(180deg);
}

.feature-flip-box.flip-bottom:hover .front-side {
  visibility: hidden;
  transform: rotateX(-180deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-bottom:hover .front-side {
    visibility: unset;
  }
}

.feature-flip-box.flip-bottom:hover .back-side {
  visibility: visible;
  transform: rotateX(0deg);
}

@supports ((-webkit-backface-visibility:hidden) or (backface-visibility:hidden)) {
  .feature-flip-box.flip-bottom:hover .back-side {
    visibility: unset;
  }
}

.iq-get-team img {
  max-width: none !important;
}

.iq-best-pricing.active {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 95px 0px;
  position: relative;
  z-index: 99;
  border-bottom: 2px solid rgb(248, 126, 126);
}

.iq-best-pricing {
  padding: 50px 30px;
  background: rgb(255, 255, 255);
  margin: 0px 5px 30px;
  transition: all 0.5s ease-in-out 0s;
  text-align: left;
  border-radius: 10px;
}

.iq-best-pricing .price-title .month-circle .month-price {
  margin-bottom: 0px;
}

.iq-best-pricing .price-title .month-circle .month-price .sub-title {
  margin-bottom: 15px;
}

.iq-best-pricing .price-title .month-circle .month-price h2 {
  display: inline;
  font-weight: bold;
}

.iq-best-pricing.active .price-title .month-circle .month-price h2 {
  font-weight: bold;
}

.iq-best-pricing .price-title .month-circle .month-price .duration {
  margin-top: 10px;
}

.iq-best-pricing .price-title .title {
  margin-bottom: 15px;
}

.iq-best-pricing .pricing-list {
  margin-top: 40px;
  padding: 0px;
}

.iq-best-pricing .pricing-list li {
  list-style-type: none;
  margin-bottom: 8px;
  display: inline-flex;
}

.iq-best-pricing .pricing-list li:last-child {
  margin-bottom: 0px;
}

.iq-best-pricing .price-footer {
  margin-top: 45px;
}

.custom-tab.horizontaltab-demo15 .nav-pills {
  position: absolute;
  bottom: 50px;
  left: -70%;
}

.custom-tab.horizontaltab-demo15 .nav-pills .nav-link.active {
  background: rgb(0, 123, 255);
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.custom-tab.horizontaltab-demo15 .nav-pills .nav-link {
  padding: 10px 30px;
}

.custom-tab.horizontaltab-demo15 a.nav-link.active h6.tab-title {
  color: rgb(255, 255, 255);
}

.custom-tab.horizontaltab-demo15 a.nav-link h6.tab-title {
  color: rgb(30, 30, 30);
}

.custom-tab.horizontaltab-demo15 .tab-pane.active {
  display: block;
}

.custom-tab.horizontaltab-demo15 .tab-pane {
  display: none;
}

.iq-feedback-testi {
  overflow: inherit !important;
}

.iq-feedback-testi .owl-carousel .owl-nav {
  left: -35%;
  top: 80%;
}

.iq-feedback-testi .owl-carousel .owl-nav .owl-next {
  left: 0%;
}

.iq-feedback-testi .vc_custom_1577353048523 {
  position: relative;
}

.iq-feedback-testi .iq-background::before {
  position: absolute;
  left: 130px;
  top: -5%;
  content: "";
  height: 120%;
  width: 200%;
  background-color: rgb(71, 49, 182);
  border-radius: 20px 0px 0px 20px;
}

.iq-banner-bot-shape {
  position: relative;
  background-position: center bottom !important;
}

.iq-banner-bot-shape::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 167px;
  
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.custom-tab.horizontaltab-slider .tab-pane.active {
  display: block;
}

.custom-tab.horizontaltab-slider .tab-pane {
  display: none;
}

.custom-tab.horizontaltab-slider ul li img {
  display: inline-block;
  margin-right: 10px;
}

.custom-tab.horizontaltab-slider ul li h6.tab-title {
  display: inline-block;
  color: rgb(255, 255, 255);
}

.custom-tab.horizontaltab-slider ul.nav {
  display: block;
  text-align: center;
}

.custom-tab.horizontaltab-slider ul li {
  display: inline-block;
}

.custom-tab.horizontaltab-slider ul li a.active {
  background: rgb(90, 70, 189);
}

.custom-tab.horizontaltab-slider ul li a {
  margin-right: 30px;
  border-radius: 5px;
  padding: 10px 20px;
}

.custom-tab.horizontaltab-slider .show_content {
  position: relative;
  overflow: hidden;
}

.custom-tab.horizontaltab-slider .tab-pane.active {
  display: block;
  padding: 25px 120px 70px;
}

.custom-tab.horizontaltab-slider .show_content .tab-pane {
  position: relative;
  margin: 0px 15px;
}

.custom-tab.horizontaltab-slider .show_content .tab-pane::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -4px;
  display: inline-block;
  width: 100%;
  height: 101%;
  z-index: 1;
}

@media (max-width: 420px) {
  .custom-tab.horizontaltab-slider .tab-pane.active {
    padding: 4px 41px 20px 33px;
  }
}

@media (max-width: 737px) {
  .custom-tab.horizontaltab-slider .tab-pane.active {
    padding: 11px 72px 30px 63px;
  }
}

@media (max-width: 618px) {
  .custom-tab.horizontaltab-slider .tab-pane.active {
    padding: 11px 59px 30px 52px;
  }
}

@media (max-width: 820px) {
  .custom-tab.horizontaltab-slider .tab-pane.active {
    padding: 8px 79px 50px 71px;
  }
}

.testimonial-style.owl-carousel .item.iq-best-testimonial {
  padding: 75px 0px 40px;
}

.testimonial-style.owl-carousel .item.iq-best-testimonial .iq-testimonial {
  margin: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) -10px 10px 10px 0px;
}

.testimonial-style.owl-carousel .item.iq-best-testimonial .iq-testimonial .iq-content p {
  margin-bottom: 30px;
}

.testimonial-style.owl-carousel .item.iq-best-testimonial .iq-testimonial::before {
  display: none;
}

.testimonial-style.owl-carousel .item.iq-best-testimonial .iq-testimonial::after {
  display: none;
}

.testimonial-style.owl-carousel .item.iq-best-testimonial .iq-testimonial .media .img-box {
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin-right: 15px;
}

.testimonial-style.owl-carousel .item.iq-best-testimonial .iq-testimonial .media .testimonial-name {
  text-align: left;
  margin-top: 15px;
}

.our-services-tab.side-tab .vc_tta.vc_tta-spacing-1 .vc_tta-tab {
  width: 33%;
  text-align: center;
}

.our-services-tab .vc_tta.vc_general .vc_tta-panel-body {
  padding: 14px 0px !important;
}

.our-services-tab .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab.vc_active>a,
.our-services-tab .vc_tta-color-white.vc_tta-style-classic .vc_tta-tab.vc_active>a,
.our-services-tab .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a:hover {
  color: var(--primary-color) !important;
}

.our-services-tab .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a {
  color: rgb(111, 111, 111) !important;
}

.our-services-tab ul li a {
  font-family: var(--body-font-family);
  font-weight: 600;
  background: none !important;
  border: none !important;
  font-size: 16px !important;
  padding: 10px 35px !important;
}

.our-services-tab ul li {
  position: relative;
}

.our-services-tab ul li.vc_tta-tab::before {
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
  height: 3px;
  background: var(--primary-color);
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.our-services-tab .vc_tta-tabs-list::before {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: rgba(2, 13, 30, 0.2);
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.our-services-tab .vc_tta-panel-body {
  padding-top: 30px !important;
}

.our-services-tab .iq-list li:last-child,
.our-services-tab .iq-list,
.our-services-tab .vc_tta-container {
  margin-bottom: 0px !important;
}

.our-services-tab .vc_tta-panels {
  background: none !important;
  border: none !important;
}

.our-services-tab ul li.vc_tta-tab.vc_active::before,
.our-services-tab ul li.vc_tta-tab:hover::before {
  display: inline-block;
  width: 100%;
  height: 3px;
  background: var(--primary-color);
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.our-services-tab.map-contact .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab.vc_active>a,
.our-services-tab.map-contact .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a:hover {
  font-weight: normal;
  color: rgb(255, 255, 255) !important;
}

.our-services-tab.map-contact ul li a {
  font-family: "Open Sans", sans-serif;
}

.our-services-tab.map-contact .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a {
  color: rgb(255, 255, 255);
  font-weight: normal;
}

.contact-tab .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a {
  color: rgb(255, 255, 255) !important;
  padding: 10px 15px !important;
}

.contact-tab .map-contact ul li.vc_tta-tab.vc_active,
.contact-tab .map-contact ul li.vc_tta-tab:hover {
  background: rgb(20, 44, 75);
}

.chabot-bg-shap::before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 649px;
  z-index: -1;
}

.fancy-clients {
  border-width: 1px 0px 0px;
  border-top-style: solid;
  border-top-color: rgba(2, 13, 30, 0.1);
  border-image: initial;
  border-left-style: initial;
  border-left-color: initial;
  border-right-style: initial;
  border-right-color: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
}

.fancy-clients .col-lg-2.col-md-3.col-sm-12 {
  padding: 15px;
  text-align: center;
}

.fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(1),
.fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(7),
.fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(13) {
  border-left: 1px solid rgba(2, 13, 30, 0.1);
}

.fancy-clients .col-lg-2.col-md-3.col-sm-12 .clients-block img {
  opacity: 0.4;
}

.fancy-clients .col-lg-2.col-md-3.col-sm-12 .clients-block:hover img {
  opacity: 1;
}

.fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(n+1) {
  border-bottom: 1px solid rgba(2, 13, 30, 0.1);
  border-right: 1px solid rgba(2, 13, 30, 0.1);
}

@media (max-width: 992px) {

  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(5),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(9) {
    border-left: 1px solid rgba(2, 13, 30, 0.1);
  }
}

@media (max-width: 767px) {

  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(2),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(3),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(4),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(6),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(8),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(10),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(11),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(12),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(14),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(15),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(16),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(17),
  .fancy-clients .col-lg-2.col-md-3.col-sm-12:nth-child(18) {
    border-left: 1px solid rgba(2, 13, 30, 0.1);
  }
}


/*---------------------------------------------------------------------
                          Progress Bar
-----------------------------------------------------------------------*/
.sofbox-services:hover.sofbox-services:after {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  top: 0%;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(100, #ffffff));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.sofbox-services {
  margin-bottom: 30px;
  padding: 30px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
}

.sofbox-services:before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 0;
  background: var(--primary-color);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  z-index: 2
}

.sofbox-services:hover:before {
  width: 100%;
  height: 4px;
  left: 0;
}

.sofbox-services .services-detail {
  z-index: 3;
  position: relative;
}

.sofbox-services i {
  font-size: 40px;
  color: var(--primary-color);
  z-index: 1;
  margin-bottom: 15px;
  line-height: 50px;
  display: inherit;
}

.sofbox-services img {
  width: 48px;
  margin-bottom: 15px;
}

.sofbox-services img.hover-img {
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.sofbox-services:hover img.hover-img {
  z-index: 0;
  opacity: 1;
  top: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

/*---------------------------------------------------------------------
 sofbox Tab
-----------------------------------------------------------------------*/
.sofbox-tabs .video-btn a {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 4px;
}

.sofbox-tabs .video-btn a i {
  line-height: 100px;
  font-size: 20px;
  color: #1e1e1e;
}

.sofbox-tabs .video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-btn .video-btn {
  background: var(--primary-color);
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  float: left;
  border-radius: 100%;
  z-index: 9;
  position: absolute;
  left: 35px;
  top: 35px;
}

.sofbox-tabs .video-btn .video-btn i {
  margin-left: 5px;
  font-family: FontAwesome;
}

.sofbox-tabs .video-btn .video-btn:hover i {
  color: #ffffff;
}

.video-btn .btn-waves {
  width: 250px;
  height: 250px;
  z-index: 2;
}

.video-btn .btn-waves .waves {
  position: absolute;
  width: 250px;
  height: 250px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 320px;
  background-clip: padding-box;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.video-btn .btn-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.video-btn .btn-waves .wave-2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.video-btn .btn-waves .wave-3 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.wpcf7-form p:nth-child(n+4) {
  margin-bottom: 0;
}



/*---------------------------------------------------------------------
 Contact form
-----------------------------------------------------------------------*/
.wpcf7-response-output.wpcf7-validation-errors,
div.wpcf7-mail-sent-ng,
div.wpcf7-aborted {
  position: absolute;
  bottom: -5px;
  left: 0;
  color: red;
  border: none;
  padding: 0;
  margin: 5px 0;
}

.sofbox-contact {
  padding: 45px;
  background: #eff1fe;
}

.sofbox-contact h2 {
  text-align: center;
}

.sofbox-contact input:hover,
.sofbox-contact input[type=text]:hover,
.sofbox-contact textarea:hover {
  border: 1px solid var(--primary-color);
}

.sofbox-contact .button {
  cursor: pointer;
  margin-top: 15px;
}

.sofbox-contact input,
.sofbox-contact input[type=text],
.sofbox-contact textarea {
  border: 1px solid #eff1fe;
}

.contact-form .cfield textarea {
  background: transparent;
}

.wpcf7-form p:nth-child(n+6) {
  margin-bottom: 0;
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing,
.wpcf7-not-valid-tip {
  display: none !important;
}

.contact-form .cfield {
  position: relative;
  width: 100%;
  display: inline-block;
}

.contact-form .cfield input[type=submit] {
  margin-top: 15px;
}

.contact-form .cfield input {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  background: transparent;
  border-radius: 4px;
  padding-left: 15px;
  height: 55px;
  line-height: 55px;
  clear: both;
  border: 1px solid #eff1fe;
}

.contact-form .cfield input:focus,
.contact-form .cfield.textarea textarea:focus {
  border: 1px solid var(--primary-color);
  box-shadow: none;
  outline: none;
}

.contact-form .cfield.textarea textarea {
  margin-bottom: 15px;
  width: 100%;
  float: left;
  background: #ffffff;
  border: 1px solid #eff1fe;
  border-radius: 4px;
  padding: 15px 0 0 20px;
  resize: none;
}

.contact-form .cfield .wpcf7-not-valid {
  border: 1px solid #f00;
}

.contact-form .ajax-loader {
  margin: 32px 0 0 5px;
}

/*---------------------------------------------------------------------
overview page
-----------------------------------------------------------------------*/
.overview-bg:before {
  display: inline-block;
  width: 387px;
  position: absolute;
  right: 0;
  height: 810px;
  bottom: 0;
}

.man-img {
  position: absolute;
  right: 0;
  top: -30px;
  z-index: 3;
}

/*---------------------------------------------------------------------
                               Portfolio
-----------------------------------------------------------------------*/
.isotope-filters {
  border-bottom: 1px solid rgba(30, 30, 30, 0.06);
  border-top: 1px solid rgba(30, 30, 30, 0.06);
  display: table;
  margin: 0 auto 30px;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.isotope-filters button {
  margin: -3px 3px;
  padding: 8px 20px;
  position: relative;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: transparent;
  color: #1e1e1e;
  border: none;
  font-weight: normal;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.isotope-filters button.active,
.isotope-filters button:hover {
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(1, 107, 151, 0.2);
  color: #ffffff;
  position: relative;
}

.isotope-filters button.active:before,
.isotope-filters button:hover:before {
  width: 15px;
}

.isotope-filters button:focus {
  outline: none;
  outline-style: none;
  outline-offset: 0;
}

/* Grid And Masonry */
.isotope.no-padding .iq-grid-item {
  padding: 0 !important;
}

.iq-masonry.no-padding .iq-masonry-item {
  padding: 0 !important;
}

.isotope,
.iq-masonry {
  margin: 0 0 -15px 0;
  float: left;
  width: 100%;
}

.isotope,
.iq-masonry.no-padding {
  margin-bottom: 30px;
}

.isotope .iq-grid-item {
  padding: 15px;
}

.isotope .iq-grid-item img,
.iq-masonry .iq-masonry-item img {
  width: 100%;
}

/* Grid */
.isotope.iq-columns-1 .iq-grid-item {
  width: 100%;
}

.isotope.iq-columns-2 .iq-grid-item {
  width: 50%;
}

.isotope.iq-columns-3 .iq-grid-item {
  width: 33.33333333%;
}

.isotope.iq-columns-4 .iq-grid-item {
  width: 25%;
}

.isotope.iq-columns-5 .iq-grid-item {
  width: 20%;
}

/* Masonry */
.iq-masonry.iq-columns-2 .iq-masonry-item {
  width: 50%;
  padding: 15px;
}

.iq-masonry.iq-columns-3 .iq-masonry-item {
  width: 33.333333%;
  padding: 15px;
}

.iq-masonry.iq-columns-4 .iq-masonry-item {
  width: 25%;
  padding: 15px;
}

.iq-masonry.iq-columns-5 .iq-masonry-item {
  width: 20%;
  padding: 15px;
}

.iq-masonry-item .iq-portfolio:hover .iq-portfolio-content .consult-details .consult-effect:before {
  bottom: 98px;
}

.iq-masonry-item.style-two .iq-portfolio:hover .iq-portfolio-content .consult-details .consult-effect:before {
  bottom: 81px;
}

/* Grid Full Screen */
.isotope.full-grid,
.iq-masonry.full-grid {
  margin: 0 -30px 0 0;
}

.isotope.full-grid .iq-grid-item {
  padding: 0 30px 30px 0;
}

/* Grid Full Screen No Space*/
.isotope.full-grid.no-padding,
.iq-masonry.full-grid.no-padding {
  margin: 0;
}

.isotope.full-grid.no-padding .iq-grid-item {
  padding: 0 !important;
}

@media(max-width:1199px) {

  .isotope.iq-columns-4 .iq-grid-item,
  .iq-masonry.iq-columns-4 .iq-masonry-item {
    width: 33.3%
  }

  .isotope.iq-columns-5 .iq-grid-item,
  .iq-masonry.iq-columns-5 .iq-masonry-item {
    width: 25%;
  }
}

@media(max-width:992px) {

  .isotope.iq-columns-4 .iq-grid-item,
  .iq-masonry.iq-columns-4 .iq-masonry-item {
    width: 50%;
  }

  .isotope.iq-columns-3 .iq-grid-item,
  .isotope.iq-columns-5 .iq-grid-item,
  .iq-masonry.iq-columns-3 .iq-masonry-item,
  .iq-masonry.iq-columns-5 .iq-masonry-item {
    width: 50%;
  }
}

@media(max-width:767px) {

  .isotope.iq-columns-4 .iq-grid-item,
  .isotope.iq-columns-2 .iq-grid-item,
  .isotope.iq-columns-3 .iq-grid-item,
  .isotope.iq-columns-5 .iq-grid-item,
  .iq-masonry.iq-columns-2 .iq-masonry-item,
  .iq-masonry.iq-columns-3 .iq-masonry-item,
  .iq-masonry.iq-columns-4 .iq-masonry-item,
  .iq-masonry.iq-columns-5 .iq-masonry-item {
    width: 100%
  }
}



/*---------------------------------------------------------------------
Testimonial-info side widget
-----------------------------------------------------------------------*/
.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info {
  padding: 30px;
  background: transparent;
}

.testimonail-widget-menu .owl-carousel .owl-stage-outer {
  background: #1e1e1e;
}

.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info img {
  width: 65px;
  height: 65px;
  border-radius: 4px;
  margin-right: 15px;
}

.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info .post-img {
  display: flex;
}

.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info .testimonial-name {
  flex: 1;
}

.testimonail-widget-menu .owl-carousel .owl-dots .owl-dot {
  background: #ffffff;
}

.testimonail-widget-menu .owl-carousel .owl-dots .owl-dot.active,
.testimonail-widget-menu .owl-carousel .owl-dots .owl-dot:hover {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.testimonail-widget-menu .owl-carousel .owl-dots {
  text-align: right;
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info p {
  color: #ffffff;
}

.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info .testimonial-name h5 {
  color: #ffffff;
}

.testimonail-widget-menu .owl-carousel .owl-item .testimonial-info .testimonial-name .sub-title {
  color: #ffffff;
}

.sidebar-widget-left {
  padding-right: 30px;
}

.sidebar-left {
  padding-right: 15px;
}

.sidebar-right {
  padding-left: 15px;
}

.sidebar-widget-right {
  padding-left: 30px;
}

.sidebar-service-left {
  padding-right: 30px;
}

.sidebar-service-right {
  padding-left: 30px;
}



/*---------------------------------------------------------------------
Recent News side widget
-----------------------------------------------------------------------*/
.sidebar-service-left .widget_nav_menu ul.menu li.current-menu-item a,
.sidebar-service-left .widget_nav_menu ul.menu li:hover a {
  color: #ffffff;
  background: var(--primary-color);
}

.sidebar-service-left .widget_nav_menu ul.menu li {
  margin-bottom: 2px;
  border: none;
  padding: 0;
}

.sidebar-service-left .widget_nav_menu ul.menu li:last-child {
  margin-bottom: 0;
}

.sidebar-service-left .widget_nav_menu ul.menu li a,
.sidebar-service-left .widget.get-file a {
  background: #eff1fe;
  color: #1e1e1e;
  text-transform: capitalize;
}

/*---------------------------------------------------------------------
Categories side widget
-----------------------------------------------------------------------*/
.widget_categories ul li span,
.widget.widget_archive ul li span.archiveCount {
  border-radius: 50px;
  float: right;
  color: #ffffff;
  background: #1e1e1e;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}

.download-item li {
  display: block;
  background: #eff1fe;
  padding-bottom: 0 !important;
}

.download-item li i {
  background: var(--primary-color);
  padding: 15px;
  color: #ffffff;
  font-size: 24px;
}

.widget.get-file {
  margin-bottom: 15px;
  padding: 0;
  background: transparent;
}

.widget.get-file a:hover {
  color: var(--primary-color);
}

.widget.get-file a {
  text-decoration: none;
}

/*---------------------------------------------------------------------
Service-box
-----------------------------------------------------------------------*/
.Service-box {
  box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.03);
  margin-bottom: 30px;
}

.Service-box .content {
  padding: 30px 0;
}

.Service-box .blue-btn.button {
  font-size: 14px;
  padding: 6px 20px;
}

/*---------------------------------------------------------------------
Portfolio style-one
-----------------------------------------------------------------------*/
.iq-blog-box .iq-blog-image img,
.iq-blog-image img {
  width: 100%;
}

.single-portfolio-border .iq-portfolio {
  border: 2px solid #ffffff;
}

.iq-portfolio:hover a.portfolio-img {
  position: relative;
}

.iq-portfolio:hover .portfolio-img .portfolio-link {
  opacity: 1;
}

.iq-portfolio .portfolio-img .portfolio-link {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.portfolio-link .icon {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 4px;
  background: #ffffff;
  color: var(--primary-color);
}

.iq-portfolio .portfolio-link {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.iq-portfolio:hover a.portfolio-img:before {
  width: 100%;
  opacity: 1;
  left: 0;
}

.iq-portfolio a.portfolio-img:before {
  content: "";
  background: #016b97cc;
  width: 0%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  z-index: 1;
  right: 0;
}

.iq-portfolio a {
  display: inherit;
  overflow: hidden;
  position: relative;
}

.iq-blog-box {
  margin-bottom: 30px;
}

.iq-portfolio,
.iq-blog-box {
  border: 1px solid #eff1fe;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  padding: 10px 10px 0;
}

.iq-portfolio:hover {
  box-shadow: 0.557px 3.961px 40px 0px rgba(0, 0, 0, 0.08);
}

.iq-portfolio:hover:before {
  opacity: 1;
}

.iq-portfolio:hover .iq-portfolio-content {
  z-index: 9;
}

.iq-portfolio {
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #ffffff;
}

.iq-portfolio:hover .iq-portfolio-content .consult-details .consult-effect:before {
  bottom: 80px;
}

.iq-portfolio img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.iq-portfolio:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.iq-portfolio-content .details-box {
  overflow: hidden;
  padding: 15px;
}

.iq-portfolio:hover .iq-portfolio-content {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.iq-portfolio-content .consult-details .consult-effect:before {
  content: "";
  transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
  -moz-transition: all 5s ease-in-out;
  -ms-transition: all 5s ease-in-out;
  -o-transition: all 5s ease-in-out;
  -webkit-transition: all 5s ease-in-out;
  opacity: 1;
  opacity: 1;
  z-index: 1;
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 110px;
  left: 0;
  -ms-transform: skewY(-2deg);
  -webkit-transform: skewY(-2deg);
  transform: skewY(-2deg);
  background: var(--primary-color);
  content: "";
  z-index: 1;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-portfolio .iq-portfolio-content .consult-details p {
  color: #525f81;
}

.isotope-filters button .post_no {
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-columns-4 .iq-portfolio .iq-portfolio-content .details-box,
.iq-columns-5 .iq-portfolio .iq-portfolio-content .details-box,
.iq-columns-3 .iq-portfolio .iq-portfolio-content .details-box {
  padding: 15px;
  z-index: 2;
}

.iq-columns-4 .iq-portfolio-content .consult-details .consult-effect:before,
.iq-columns-5 .iq-portfolio-content .consult-details .consult-effect:before,
.iq-columns-3 .iq-portfolio-content .consult-details .consult-effect:before {
  bottom: 90px;
}

.iq-columns-4 .iq-portfolio:hover .iq-portfolio-content .consult-details .consult-effect:before,
.iq-columns-3 .iq-portfolio:hover .iq-portfolio-content .consult-details .consult-effect:before,
.iq-columns-5 .iq-portfolio:hover .iq-portfolio-content .consult-details .consult-effect:before {
  bottom: 81px;
}

.iq-portfolio-content .details-box a {
  text-decoration: none;
}

.iq-portfolio-content .details-box a:hover .text-hover {
  color: var(--primary-color);
}

.iq-columns-2 .iq-portfolio .iq-portfolio-content .consult-details .consult-effect:before {
  top: -86px
}

.iq-columns-4 .iq-portfolio-content .details-box a,
.iq-columns-5 .iq-portfolio-content .details-box a {
  line-height: 60px;
  z-index: 3;
  position: relative;
}

.iq-columns-4 .iq-portfolio-content .details-box a i,
.iq-columns-5 .iq-portfolio-content .details-box a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/*---------------------------------------------------------------------
Portfolio style-two
-----------------------------------------------------------------------*/
.iq-portfolio.style-two img {
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}

.iq-portfolio.style-two:hover img {
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}

.iq-portfolio.style-two .iq-portfolio-content .details-box {
  background: transparent;
  padding: 0;
}

.iq-portfolio.style-two .iq-portfolio-content .details-box h5,
.iq-portfolio.style-two .iq-portfolio-content .consult-details p {
  color: #ffffff;
}

.iq-portfolio.style-two .iq-portfolio-content .details-box a i {
  background: var(--primary-color);
}

.iq-portfolio.style-two .iq-portfolio-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  background: #1e1e1e;
  color: #ffffff;
  width: 100%;
  top: auto;
  bottom: 0;
  opacity: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
  transition: transform 0.4s, opacity 0.1s 0.3s;
}

.iq-portfolio.style-two:hover .iq-portfolio-content {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s;
}


/*---------------------------------------------------------------------
Sofbox tab
-----------------------------------------------------------------------*/
.Sofbox-tab .nav-pills .nav-link.active,
.Sofbox-tab .nav-pills .show>.nav-link,
.Sofbox-tab .nav-pills .nav-link {
  background: transparent;
  color: #8e989f;
}

.Sofbox-tab .nav-pills .nav-link {
  padding: 0;
}

.Sofbox-tab .nav-pills .nav-link:last-child {
  margin-bottom: 0px;
}

.Sofbox-tab .nav-pills .nav-link:hover .active-title,
.Sofbox-tab .nav-pills .nav-link.active .active-title {
  color: var(--primary-color);
}

.Sofbox-tab .nav-pills .nav-link .saas-works-box,
.analysis-box {
  padding: 30px 15px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border: 1px solid transparent;
}

.Sofbox-tab .nav-pills .nav-link.active .saas-works-box,
.Sofbox-tab .nav-pills .saas-works-box:hover,
.analysis-box:hover {
  box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.Sofbox-tab .tab-pane.fade.active.show {
  display: block;
}

.Sofbox-tab .tab-pane.fade {
  display: none;
}

/*---------------------------------------------------------------------
Blog
-----------------------------------------------------------------------*/
.iq-blog-box {
  position: relative;
}

.owl-carousel .iq-blog-box .iq-blog-detail {
  display: inline-block;
  float: left;
  width: 100%;
  padding: 15px 15px;
}

.iq-blog-box .iq-blog-detail {
  padding: 15px 15px;
}

.owl-carousel .iq-blog-box {
  margin: 0px 15px 50px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  background: #ffffff;
  border-radius: 4px;
}

.owl-carousel .iq-blog-box:hover {
  box-shadow: -6px 17px 30px 0px rgba(0, 0, 0, 0.06);
}

.iq-blog-detail .iq-blog-meta li a {
  color: #8e989f;
}

.iq-blog-detail .iq-blog-meta li a:hover {
  color: var(--primary-color);
}

.iq-blog-detail .iq-blog-meta li {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 2px 10px;
}

.our-map .wpb_map_wraper:hover {
  -webkit-filter: none;
  filter: none;
}

.our-map .wpb_map_wraper iframe {
  border-radius: 4px;
}

.our-map .wpb_map_wraper {
  webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.social-team .info-share li a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: var(--primary-color);
  display: block;
  color: #ffffff;
  text-align: center;
  border-radius: 90px;
}

.social-team.color ul li a {
  position: relative;
  background: transparent;
  color: var(--primary-color);
}

.social-team.color ul li a:hover {
  color: #ffffff;
}

.social-team.color .info-share li a:hover:before {
  color: #ffffff;
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.social-team.color .info-share li a:before {
  content: "";
  width: 45px;
  background: #ebecf9;
  height: 45px;
  z-index: -1;
  line-height: 45px;
  color: var(--primary-color);
  position: absolute;
  left: 0;
  display: inline-block;
  border-radius: 90px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.sofbox-blog .share-box .share-tag a:first-child {
  margin-left: 15px;
}

.share-box .share-tag a {
  text-decoration: none;
  padding: 5px;
  background: rgba(0, 0, 0, 0.05);
  margin-right: 10px;
  border-radius: 4px;
  font-size: 12px;
}

.share-box {
  padding-top: 35px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  clear: both;
}

.sofbox-blog .share-box .share-tag a:last-child {
  margin-right: 0px;
}

/*---------------------------------------------------------------------
list
-----------------------------------------------------------------------*/
.iq-list {
  list-style: none;
  padding: 0;
}

.iq-list li {
  margin-bottom: 10px;
  position: relative;
}

.iq-list li i {
  margin-right: 10px;
  position: absolute;
  top: 8px;
}

.iq-list li span {
  display: table-cell;
  padding-left: 30px;
  color: #1e1e1e;
}

.iq-list li:last-child {
  margin-bottom: 0px;
}

.Social-btn {
  width: 45px !important;
  text-align: center !important;
  height: 45px !important;
}

/*---------------------------------------------------------------------
color pallet Style-1
-----------------------------------------------------------------------*/
.color-palette:hover {
  background: transparent;
}

.color-palette .circle-icon i {
  font-size: 40px;
}

.color-palette {
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 30px;
  background: #ffffff;
  height: calc(100% - 30px);
}

.color-palette .circle-icon,
.color-palette .color-text,
.color-palette p {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.color-palette:before,
.sox-services:before {
  border-radius: 4px;
  content: "";
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.color-palette:hover:before,
.sox-services:hover:before {
  opacity: 1;
  z-index: -1;
}

.color-palette:hover .circle-icon:before {
  opacity: 0.4;
}

.color-palette .circle-icon:before {
  border-radius: 90px;
  line-height: 80px;
  width: 80px;
  height: 80px;
  text-align: center;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.2;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.color-palette .media:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 95px;
  height: 95px;
  bottom: 10px;
}

.color-palette .circle-icon {
  z-index: 0;
  width: 80px;
  height: 80px;
  margin-right: 30px;
  color: #ffffff;
  position: relative;
  line-height: 80px;
  text-align: center;
}

.color-palette:hover .color-text {
  color: #ffffff;
}

.color-palette:hover p {
  color: #ffffff;
}

/*color pallet box-1*/
.main-bg {
  background-image: -moz-linear-gradient(90deg, rgb(30, 40, 60) 0%, rgb(30, 40, 60) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(30, 40, 60) 0%, rgb(30, 40, 60) 100%);
}

.color-palette.purple-hover::before,
.sox-services.purple-hover::before {
  background-image: -webkit-linear-gradient(0deg, rgb(30, 40, 60) 0%, rgb(54, 72, 107) 100%);
  box-shadow: rgb(34 44 64 / 38%) 0px 10px 20px 0px;
}

.color-palette.purple-hover.purple-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: -6%;
  width: 58px;
  height: 87px;
  bottom: -62%;
}

.color-palette.purple-hover .circle-icon {
  color: #6e73ef;
}

.color-palette.purple-hover:hover .circle-icon {
  color: #ffffff;
}

.color-palette.purple-hover .circle-icon:before {
  background-image: -moz-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(23, 51, 195) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(23, 51, 195) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(23, 51, 195) 100%);
}

/*color pallet box-2*/

.color-palette.org-hover:before,
.sox-services.org-hover:before {
  background-image: -moz-linear-gradient(0deg, rgb(255, 121, 109) 0%, rgb(255, 175, 167) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(255, 121, 109) 0%, rgb(255, 175, 167) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(255, 121, 109) 0%, rgb(255, 175, 167) 100%);
  box-shadow: 0px 10px 20px 0px rgba(255, 121, 109, 0.4);
}

.color-palette.org-hover.org-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -6%;
  width: 58px;
  height: 87px;
  bottom: -62%;
}

.color-palette.org-hover .circle-icon {
  color: #ff6a5c
}

.color-palette.org-hover:hover .circle-icon {
  color: #ffffff
}

.color-palette.org-hover .circle-icon:before {
  background-image: -moz-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 106, 92) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 106, 92) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 106, 92) 100%);
}

/*color pallet box-2 - chatbot*/

.color-palette.chatbot-hover:before,
.sox-services.chatbot-hover:before {
  background-image: -moz-linear-gradient(0deg, rgb(255, 121, 109) 0%, rgb(255, 175, 167) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(137, 193, 212) 0%, rgb(0, 126, 173) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(137, 193, 212) 0%, rgb(0, 126, 173) 100%);
  box-shadow: 0px 10px 20px 0px rgba(10, 44, 57, 0.4);
}

.color-palette.chatbot-hover.chatbot-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -6%;
  width: 58px;
  height: 87px;
  bottom: -62%;
}

.color-palette.chatbot-hover .circle-icon {
  color: #007ead
}

.color-palette.chatbot-hover:hover .circle-icon {
  color: #ffffff
}

.color-palette.chatbot-hover .circle-icon:before {
  background-image: -moz-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 106, 92) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(137, 193, 212) 0%, rgb(0, 126, 173) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(137, 193, 212) 0%, rgb(0, 126, 173) 100%);
}



/*color pallet box-3*/

.color-palette.green-hover:before,
.sox-services.green-hover:before {
  background-image: -moz-linear-gradient(0deg, rgb(108, 218, 193) 0%, rgb(144, 227, 209) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(108, 218, 193) 0%, rgb(144, 227, 209) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(108, 218, 193) 0%, rgb(144, 227, 209) 100%);
  box-shadow: 0px 10px 20px 0px rgba(100, 218, 193, 0.6);
}

.color-palette.green-hover .circle-icon {
  color: #64d8be
}

.color-palette.green-hover:hover .circle-icon {
  color: #ffffff;
}

.color-palette.green-hover .circle-icon:before {
  background-image: -moz-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(100, 216, 190) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(100, 216, 190) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(100, 216, 190) 100%);
}

/*color pallet box-4*/
.color-palette.yellow-hover:before,
.sox-services.yellow-hover:before {
  background-image: -moz-linear-gradient(0deg, rgb(253, 184, 75) 0%, rgb(252, 211, 147) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(253, 184, 75) 0%, rgb(252, 211, 147) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(253, 184, 75) 0%, rgb(252, 211, 147) 100%);
  box-shadow: 0px 10px 20px 0px rgba(252, 211, 147, 0.6);
}

.color-palette.yellow-hover .circle-icon {
  color: #fcd393
}

.color-palette.yellow-hover:hover .circle-icon {
  color: #ffffff;
}

.color-palette.yellow-hover .circle-icon:before {
  background-image: -moz-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(248, 186, 89) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(248, 186, 89) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(248, 186, 89) 100%);
}

/*---------------------------------------------------------------------
color pallet Style-2
-----------------------------------------------------------------------*/
.color-palette.style-three .media {
  display: block;
  padding: 30px;
  position: relative;
  z-index: 0
}

.color-palette.style-three.purple-hover:after,
.color-palette.style-three.org-hover:after {
  display: none
}

.color-palette.style-three .circle-style {
  position: absolute;
  left: 0;
  top: 0;
}

.color-palette.style-three .media-body {
  margin-top: 120px;
}

.color-palette.style-three .circle-icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.color-palette.style-three .circle-icon {
  position: inherit;
}

.color-palette.style-three {
  padding: 0;
}

.contact-box .color-palette.style-three {
  padding: 0;
  min-height: 282px;
}

.color-palette.style-three:before {
  z-index: 0;
}

.color-palette.style-three .circle-icon:before {
  border-top-left-radius: 6px;
  width: 120px;
  height: 120px;
  left: 0px;
  top: 0px;
}

/*---------------------------------------------------------------------
Service-box process Style-2
-----------------------------------------------------------------------*/
.sox-services {
  border-radius: 4px;
  min-height: 160px;
  margin: 0px 0 30px;
  padding: 20px;
  z-index: 0;
  text-align: center;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.sox-services .service-number {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: rgba(111, 115, 240, 0.20);
  color: rgba(111, 115, 240, 1);
  border-radius: 90px;
}

.sox-services .sox-icon h5 {
  font-size: 18px;
}

.sox-services .sox-icon i {
  font-size: 45px;
}

.sox-services .sox-icon i,
.sox-services .text-hover,
.sox-services .service-number {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.sox-services.org-hover .service-number {
  background: rgba(255, 121, 109, 0.4);
}

.sox-services.purple-hover .service-number {
  background: rgba(111, 115, 240, 0.20);
}

.sox-services.purple-hover:hover .service-number {
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.sox-services.org-hover:hover .service-number {
  box-shadow: 0px 10px 20px 0px rgba(255, 121, 109, 0.4);
}

.sox-services.green-hover:hover .service-number {
  box-shadow: 0px 10px 20px 0px rgba(100, 218, 193, 0.6);
}

.sox-services.green-hover .service-number {
  background: rgba(108, 218, 193, 0.20);
}

.sox-services.purple-hover .service-number,
.sox-services.purple-hover .sox-icon i {
  color: #6f73f0;
}

.sox-services.org-hover .service-number,
.sox-services.org-hover .sox-icon i {
  color: #ff796d;
}

.sox-services.green-hover .service-number,
.sox-services.green-hover .sox-icon i {
  color: #6cdac1;
}

.sox-services:hover i,
.sox-services:hover .text-hover {
  color: #ffffff !important;
}

.sox-services:hover .service-number {
  background: #ffffff;
}

/*---------------------------------------------------------------------
Service-box Style-3
-----------------------------------------------------------------------*/
.sox-services.service-pattern {
  position: relative;
  background: #ffffff;
}

.sox-services.service-pattern i {
  font-size: 45px;
}

.sox-services.service-pattern {
  min-height: 160px;
}

.sox-services.service-pattern:hover:after {
  z-index: -2;
}

.sox-services.service-pattern:after {
  content: "";
  width: 70px;
  height: 70px;
  background-image: radial-gradient(#6f73f0 1px, transparent 1px);
  background-size: 10px 10px;
  z-index: -2;
  content: "";
  position: absolute;
  right: -20px;
  bottom: -20px;
  opacity: 0;
  transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.sox-services.service-pattern:hover:after {
  opacity: 1;
}

.sox-services.service-pattern.purple-hover:after {
  background-image: radial-gradient(#6f73f0 1px, transparent 1px);
}

.sox-services.service-pattern.org-hover:after {
  background-image: radial-gradient(#ff796d 1px, transparent 1px);
}

.sox-services.service-pattern.green-hover:after {
  background-image: radial-gradient(#6cdac1 1px, transparent 1px);
}

.sox-services.service-pattern.blue-hover:after {
  background-image: radial-gradient(var(--primary-color) 1px, transparent 1px);
}

.color-palette.blue-hover:before,
.sox-services.blue-hover:before {
  background: var(--primary-color);
}

.color-palette.blue-hover .circle-icon,
.sox-services.blue-hover .sox-icon i {
  color: var(--primary-color);
}

.color-palette.blue-hover:hover .circle-icon {
  color: #ffffff;
}

.color-palette.blue-hover .circle-icon:before {
  background: var(--primary-color);
}

/*---------------------------------------------------------------------
Testimonial Circle
-----------------------------------------------------------------------*/
.testimonial-circle {
  position: relative;
  display: inline-block;
  width: 550px;
  height: 550px;
}

.testimonial-circle .testimonial-img:before {
  content: "";
  border: 1px solid rgba(30, 30, 30, 0.1);
  -webkit-animation: anti-clockwise 25s linear 100000;
  animation: anti-clockwise 25s linear 100000;
  width: 500px;
  height: 500px;
  border-radius: 900px;
  display: inline-block;
}

.testimonial-circle .testimonial-img {
  margin: 0 auto;
  display: block;
  width: 500px;
  height: 500px;
  position: relative;
  /*  animation-name: circle; */
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: all .4s ease;
  transform-origin: center center
}

.testimonial-circle .circle-bg {
  width: 140px;
  text-align: center;
  position: relative;
  height: 140px;
  line-height: 140px;
  border-radius: 900px;
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.testimonial-circle .dot-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.testimonial-circle .effect-circle:before {
  width: 210px;
  height: 210px;
  display: inline-block;
  content: "";
}

.testimonial-circle .effect-circle {
  width: 210px;
  height: 210px;
  border-radius: 900px;
  border: 1px solid rgba(255, 106, 92, 1);
  -webkit-animation: rotate-center 25s linear 100000;
  animation: rotate-center 25s linear 100000;
}

.testimonial-circle .main-circle {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-right: -50%;
}

.testimonial-circle .testimonial-img ul li img {
  position: absolute;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 90px;
  border: 2px solid #fff;
}

.testimonial-circle .testimonial-img ul li:nth-child(1) img {
  top: -38px;
  left: -16px;
  right: 0;
}

.testimonial-circle .testimonial-img ul li:nth-child(2) img {
  top: 10%;
  left: 4%;
}

.testimonial-circle .testimonial-img ul li:nth-child(3) img {
  top: 10%;
  right: 4%;
}

.testimonial-circle .testimonial-img ul li:nth-child(4) img {
  top: 44%;
  right: -6%;
}

.testimonial-circle .testimonial-img ul li:nth-child(5) img {
  top: 44%;
  left: -6%;
}

.testimonial-circle .testimonial-img ul li:nth-child(6) img {
  top: 78%;
  left: 8%;
}

.testimonial-circle .testimonial-img ul li:nth-child(7) img {
  top: 78%;
  right: 8%;
}

.testimonial-circle .testimonial-img ul li:nth-child(8) img {
  bottom: -6%;
  left: 43%;
}

@keyframes circle {
  0% {
    transform: rotate(0deg)
  }

  15% {
    transform: rotate(2deg)
  }

  33% {
    transform: rotate(4deg)
  }

  66% {
    transform: rotate(2deg)
  }

  100% {
    transform: rotate(0deg)
  }
}

/*
 ----------------------------------------
    animation rotate-center
  ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes anti-clockwise {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes anti-clockwise {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/*
 ----------------------------------------
   PopUp Images Effect left
  ----------------------------------------
 */
.popup-images {
  position: relative;
}

.popup-images.align-left .img-one img {
  position: relative;
  left: -27%;
  max-width: none !important;
}

.popup-images.align-left .img-two {
  left: 306px;
  top: 0px;
}

.popup-images.align-left .img-three {
  left: -158px;
  top: 195px;
}


/*---------------------------------------------------------------------
PopUp Images Effect  Right
-----------------------------------------------------------------------*/
.sofbox-fullrside-img .iq-objects.style-one.right-top .iq-objects-01 img {
  right: -318px;
  top: 0px;
  max-width: 140%;
  z-index: -2;
}

.sofbox-fullrside-img .iq-objects.style-two .iq-objects-03 img {
  left: inherit;
  bottom: -18%;
  right: -10%;
}

.popup-images.align-right {
  text-align: right;
}

.popup-images.align-right .img-one img {
  position: relative;
  right: 0;
}

.popup-images.align-right .img-two {
  left: 22%;
  bottom: -6%;
}

.popup-images.align-right .img-three {
  top: -10%;
  right: -17%;
}

.popup-images.align-right .img-four {
  bottom: -8%;
  right: -4%;
}

/*
 ----------------------------------------
   Flting layer
  ----------------------------------------
 */
@keyframes bounces {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-25px);
  }
}

@-webkit-keyframes bounces {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-25px);
  }
}

.iq-objects.style-two .iq-objects-03 img,
.iq-objects.style-two .iq-objects-02 img {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-animation: bounces 2s infinite alternate;
}

.iq-objects.style-two .iq-objects-03 img {
  position: absolute;
  z-index: -1;
  left: -15%;
  right: 0;
  bottom: 0;
}

.iq-objects.style-one.left-top .iq-objects-01 img {
  position: absolute;
  left: -700px;
  top: 0;
  z-index: -2;
}

.iq-objects.style-two.left-top .iq-objects-02 img {
  position: absolute;
  left: -300px;
  top: 0;
  z-index: -2;
  -webkit-animation: bounces 2s infinite alternate;
  max-width: 130%;
}

.iq-objects.style-two.right-top .iq-objects-02 img {
  position: absolute;
  right: -300px;
  top: 0;
  z-index: -2;
  -webkit-animation: bounces 2s infinite alternate;
}

.iq-objects.style-one .iq-objects-01 img {
  -webkit-animation: bounces 2s infinite alternate;
  z-index: -1;
}

.iq-objects.style-one.right-top .iq-objects-01 img {
  position: absolute;
  right: -700px;
  top: 0;
  z-index: -2;
}

.iq-objects.style-one.left .iq-objects-01 img {
  position: absolute;
  left: -45%;
  bottom: 0;
}

.uniq-round .iq-objects.style-one.right-top .iq-objects-01 img {
  right: -500px;
  top: 25%;
}



/*---------------------------------------------------------------------
                            Who is Sofbox ?
-----------------------------------------------------------------------*/
.how-works {
  position: relative;
  overflow: hidden;
}

.iq-works-img {
  width: 120%;
}

.iq-objects {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.iq-objects .iq-objects-01 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.iq-objects .iq-objects-02 {
  position: absolute;
  top: 10%;
  right: 35%;
}

.iq-objects .iq-objects-03 {
  position: absolute;
  top: 30%;
  right: 0;
}

.iq-objects .iq-objects-04 {
  position: absolute;
  top: 0%;
  right: -13%;
  border: 56px solid #00c8c8;
  border-radius: 900px;
  height: 600px;
  width: 600px;
}

/*---------------------------------------------------------------------
 Testimonial style 1
-----------------------------------------------------------------------*/
.testimonial-style.owl-carousel .testimonial-info:before {
  content: "";
  display: inline-block;
  position: absolute;
  font-size: 84px;
  width: 205px;
  height: 140px;
  opacity: 1;
  top: 111%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.testimonial-style.owl-carousel .owl-item .testimonial-info img {
  width: 65px;
  height: 65px;
  display: inline-block;
  margin-bottom: 45px;
  box-shadow: 0px 8px 38px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid #ffffff;
}

.testimonial-style.owl-carousel .testimonial-name {
  text-align: center;
  position: relative;
  z-index: 1;
  display: block;
}

.testimonial-style.owl-carousel .testimonial-name .content {
  display: inline-block;
}

.testimonial-style.owl-carousel .iq-testimonial p {
  margin-bottom: 0;
  z-index: 1;
  position: relative;
}

.testimonial-style.owl-carousel .owl-dots {
  position: absolute;
  bottom: 0px;
}

.testimonial-style.owl-carousel .iq-testimonial {
  padding: 45px 30px;
  margin: 52px 30px 70px 30px;
  box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 4px;
  display: block;
  position: relative;
}

.testimonial-style.owl-carousel .iq-testimonial:before {
  content: "";
  position: absolute;
  bottom: -12px;
  width: 90%;
  z-index: -1;
  border-radius: 4px;
  height: 100%;
  background: #fff;
  box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
}

.testimonial-style.owl-carousel .iq-testimonial:after {
  content: "";
  position: absolute;
  bottom: -24px;
  width: 82%;
  left: 0;
  margin: 0 auto;
  right: 0;
  z-index: -2;
  border-radius: 4px;
  height: 100%;
  background: #fff;
  box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
}

.testimonial-style .testimonial-info {
  position: relative;
  text-align: center;
  display: block;
  margin-bottom: 25px;
}

.test-shadow .testimonial-style.owl-carousel .iq-testimonial {
  margin: 15px 36px 66px 36px;
}

.test-shadow .testimonial-style.owl-carousel .owl-dots {
  position: absolute;
  top: 68%;
  left: -106%;
  width: auto;
}

/*---------------------------------------------------------------------
 Testimonial style 2
-----------------------------------------------------------------------*/
.our-testimonial .iq-testimonial {
  padding: 30px;
  margin-bottom: 25px;
}

.our-testimonial.owl-carousel .owl-dots .owl-dot.active {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.our-testimonial.owl-carousel .owl-dots {
  position: absolute;
  width: auto;
  left: 30px;
  bottom: 0;
}

.our-testimonial.owl-carousel .owl-dots .owl-dot {
  background: transparent;
  border: 1px solid var(--primary-color);
}

.our-testimonial .iq-testimonial p {
  font-style: italic;
}

.our-testimonial.owl-carousel .owl-item .testimonial-info img {
  width: 65px;
  height: 65px;
  margin-right: 25px;
}

.our-testimonial .testimonial-info {
  position: relative;
  text-align: left;
  display: flex;
}

.our-testimonial .testimonial-name span {
  color: #8e989f;
}

.our-testimonial .owl-stage-outer {
  position: relative;
}

.our-testimonial .owl-stage-outer:before {
  content: "";
  display: inline-block;
  position: absolute;
  font-size: 84px;
  width: 205px;
  font-family: "Font Awesome 5 Free";
  height: 140px;
  opacity: 1;
  left: 10px;
  top: 0px;
  z-index: -1;
}

.our-testimonial .testimonial-name {
  flex: 1;
}

/*---------------------------------------------------------------------
 Testimonial style 3
-----------------------------------------------------------------------*/
.our-testimonial.style-three {
  padding: 0;
}

.our-testimonial.style-three {
  position: relative;
}

.our-testimonial .owl-stage-outer:before {
  display: none;
}

.our-testimonial.style-three:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 130px;
  opacity: 1;
  width: 205px;
  top: 62px;
  left: 29%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}


.fancy-icon {
  margin-bottom: 45px;
}

.fancy-icon .media img {
  position: relative;
  width: 70px;
  height: 70px;
}

.fancy-icon .media img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  font-size: 30px;
  height: 80px;
  line-height: 80px;
  background: #f5f2ff;
  text-align: center;
  margin-right: 15px;
  border-radius: 90px;
}

.fancy-icon .media .media-body {
  padding-left: 130px;
}

.fancy-icon .media .fancy-img img {
  width: 100px;
  height: 100px;
  max-width: initial;
}

.fancy-icon .media .fancy-img i {
  font-size: 40px;
  line-height: 80px;
  color: var(--primary-color)
}

.fancy-icon .media .fancy-img {
  width: 80px;
  height: 80px;
  line-height: 80px;
  content: "";
  text-align: center;
  border-radius: 90px;
  position: absolute;
}

.shap-one {
  padding-top: 250px !important;
}

.over-view-pt {
  padding-top: 200px !important;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-13 15:46:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/*---------------------------------------------------------------------
 Box-effect style one( Feature box one)
-----------------------------------------------------------------------*/
.box-effect .icon-box span.country {
  margin-bottom: 30px;
  display: inline-block;
}

.box-effect .blue-btn.link-button {
  display: inline-block;
  margin-top: 15px !important;
  position: relative;
}

.box-effect .blue-btn.link-button:after {
  content: "\f363";
  position: absolute;
  right: -12px;
  top: -2px;
  font-family: "Ionicons";
  font-size: 18px;
}

.box-effect .blue-btn.link-button:hover:after {
  right: -15px;
}

.box-effect.text-center .img-effect {
  margin: 0 auto;
}

.box-effect.shadow-effect.selected {
  box-shadow: 0 44px 98px 0 rgba(0, 0, 0, .12);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.box-effect.shadow-effect {
  background: #f7fafd;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.box-effect {
  padding: 30px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  margin-bottom: 30px;
  border-radius: 4px;
}

.box-effect.selected {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.box-effect .img-effect i {
  font-size: 40px;
  margin-top: 45px;
}

.box-effect .img-effect img {
  width: auto;
}

.box-effect .img-effect {
  content: "";
  border-radius: 90px;
  position: relative;
}

.box-effect .img-effect.shadow-effect img {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.08);
}

.box-effect .img-effect.circle-image img {
  border-radius: 900px;
}

.box-effect.box-shadow {
  box-shadow: 0 44px 98px 0 rgba(0, 0, 0, .12);
  padding: 50px 30px !important;
}

.box-effect.box-shadow .img-effect.mb-4 {
  margin-bottom: 0 !important;
}

.box-effect.box-shadow img {
  margin-bottom: 40px !important;
}

.box-effect.box-shadow i {
  margin-bottom: 30px !important;
}

.box-effect.box-shadow i:before {
  font-size: 60px;
  line-height: 60px;
}

.box-effect .img-effect .pattern-one:before,
.box-effect .img-effect .pattern-two:before,
.box-effect .img-effect .pattern-three:before {
  position: absolute;
  content: "";
  top: 0;
  width: 22px;
  height: 22px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.box-effect.selected .img-effect .pattern-one:before,
.box-effect.selected .img-effect .pattern-two:before,
.box-effect.selected .img-effect .pattern-three:before {
  opacity: 1;
}

.box-effect .img-effect .pattern-one:before {
  top: 24px;
  right: 14px;
  -webkit-animation: rotate-center 10s linear 100000;
  animation: rotate-center 10s linear 100000;
}

.box-effect .img-effect .pattern-two:before {
  top: 20px;
  left: -5px;
  -webkit-animation: bounces 2s infinite alternate;
}

.box-effect .img-effect .pattern-three:before {
  top: -10px;
}

.box-effect-transparent .box-effect {
  background: transparent;
}

.box-effect-transparent .box-effect.selected {
  background: #f7fafd;
}







/*---------------------------------------------------------------------
 Box-effect style one( Feature box Two)
-----------------------------------------------------------------------*/
.box-effect-two {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  top: 0;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.box-effect-two .img-effect img {
  width: auto;
}

.box-effect-two.selected:before {
  -webkit-transform: scaleX(1);
  -khtml-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.box-effect-two:before {
  content: '';
  position: absolute;
  bottom: 0;
  background: #6f73f0;
  left: 0;
  height: 4px;
  width: 100%;
  -webkit-transform: scaleX(0);
  -khtml-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 0;
  -webkit-transition: all .3s linear 0ms;
  -khtml-transition: all .3s linear 0ms;
  -moz-transition: all .3s linear 0ms;
  -ms-transition: all .3s linear 0ms;
  -o-transition: all .3s linear 0ms;
  transition: all .3s linear 0ms;
}

.box-effect-two.selected {
  box-shadow: 0 44px 98px 0 rgba(0, 0, 0, .12);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  background: #ffffff;
  top: -4px;
}

/*---------------------------------------------------------------------
 Box-effect ( Feature box four with background effect)
-----------------------------------------------------------------------*/
.box-effect-two.bg-img.selected .icon-box img.fancy-list-img {
  opacity: 1;
  z-index: 0;
}

.box-effect-two.bg-img {
  padding: 50px 30px;
}

.box-effect-two.bg-img:before {
  display: none;
}

.box-effect-two.bg-img.selected {
  z-index: 0;
}

.box-effect-two.bg-img .feature-details {
  position: relative;
  z-index: 1;
}

.box-effect-two.bg-img .icon-box img.fancy-list-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0
}

/*---------------------------------------------------------------------
Counter
-----------------------------------------------------------------------*/
.iq-timer.counter-vertical .timer-details .timer,
.iq-timer.counter-vertical .timer-details .total-cust,
.timer-details .price {
  font-size: 35px;
  color: #ffffff;
}

.iq-timer.counter-vertical .timer-details i {
  font-size: 30px;
  color: #ffffff;
}

.iq-timer.counter-vertical .timer-details p {
  margin-top: 50px;
}

.iq-timer.counter-vertical .timer-details {
  color: #ffffff;
  text-align: center;
}


/*---------------------------------------------------------------------
border effect
-----------------------------------------------------------------------*/
.border-effect {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.title-saas {
  position: relative;
  background: #ffffff;
  height: 30px;
  width: 2px;
  overflow: hidden;
}

.title-saas::after,
.title-saas::before {
  content: "";
  position: absolute;
  background: var(--primary-color);
  width: 2px;
  height: 3px;
  left: 0;
  z-index: 1;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}

/*---------------------------------------------------------------------
Mobile image effect
-----------------------------------------------------------------------*/
.mobile-effect {
  position: relative;
}

.mobile-effect .img-one img {
  position: absolute;
  top: -193px;
  left: -22px;
}

.mobile-effect .img-two img {
  position: absolute;
  top: 211px;
  left: 46%;
}

.mobile-effect .img-three img {
  position: absolute;
  top: -88px;
  left: 66%;
}

.mobile-effect .img-four img {
  position: absolute;
  top: 312px;
  left: 114%;
}

.mobile-effect .img-five img {
  position: absolute;
  top: -124px;
  right: -100%;
}

/*---------------------------------------------------------------------
Our Team
-----------------------------------------------------------------------*/
ul.our-new-team {
  width: 100%;
  float: left;
  padding: 0;
}

ul.our-new-team li:first-child {
  width: 40%;
}

ul.our-new-team li img {
  width: 100%;
}

ul.our-new-team li {
  display: inline-block;
  padding: 5px;
  float: left;
  width: 20%;
}

ul.our-new-team .team-blog {
  position: relative;
  text-align: center;
  overflow: hidden;
}

ul.our-new-team .team-blog:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
  opacity: 0;
}

ul.our-new-team .team-blog .team-description {
  position: absolute;
  width: 100%;
  bottom: 0%;
  left: 0;
  color: #ffffff;
  margin-top: -20px;
  padding: 0;
  opacity: 0;
  font-size: 16px;
  display: inline-block;
}

ul.our-new-team .team-blog .team-description a:hover {
  color: #222222;
}

ul.our-new-team .team-blog:hover:before {
  opacity: 0.9;
}

ul.our-new-team .team-blog .team-social {
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 100%;
  opacity: 0;
  display: inline-block;
}

ul.our-new-team .team-blog .team-social ul {
  width: 100%;
  display: inline-block;
  padding: 0px 10px;
}

ul.our-new-team .team-blog .team-social ul li {
  display: inline-block;
  width: auto;
  float: inherit
}

ul.our-new-team .team-blog .team-social ul li a {
  color: #ffffff;
  padding: 4px;
}

ul.our-new-team .team-blog .team-social ul li a:hover {
  color: #1e1e1e;
}

ul.our-new-team .team-blog .team-social ul li a i {
  font-size: 18px;
  display: inline-block;
  float: none;
  text-align: center;
  background: transparent;
}

ul.our-new-team .team-blog:before,
ul.our-new-team .team-blog:hover:before,
ul.our-new-team .team-blog:hover .team-description,
ul.our-new-team .team-blog:hover .team-social,
ul.our-new-team .team-blog .team-social {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

ul.our-new-team .team-blog:hover .team-description {
  bottom: 30%;
  opacity: 1;
}

ul.our-new-team .team-blog:hover .team-social {
  bottom: 6%;
  opacity: 1;
}

ul.our-new-team li:first-child .team-blog:hover .team-description {
  bottom: 20%;
}

ul.our-new-team p {
  margin-bottom: 0;
  font-size: 14px;
}

ul.our-new-team .team-blog .team-images img {
  width: 100%;
  webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

ul.our-new-team .team-blog:hover .team-images img {
  -webkit-filter: none;
  filter: none;
}

.iq-team7.team-top {
  margin-top: -110px;
}

ul.our-new-team .iq-link {
  color: #ffffff;
  position: relative;
}

ul.our-new-team .iq-link:before {
  position: absolute;
  bottom: 6px;
  left: 0;
  height: 4px;
  width: 0;
  content: "";
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

ul.our-new-team .iq-link:hover:before {
  width: 100%;
}

/*---------------------------------------------------------------------
Our Team style  two
-----------------------------------------------------------------------*/
ul.our-new-team.style-two li:first-child {
  width: 20%;
}


/*---------------------------------------------------------------------
About us style box
-----------------------------------------------------------------------*/
.about-style {
  position: relative;
}

.about-style .circle-bg {
  width: 80px;
  text-align: center;
  position: relative;
  height: 80px;
  border-radius: 900px;
}

.about-style .circle-bg i {
  font-size: 40px;
  line-height: 80px;
}

.about-style .circle-bg img {
  width: 50px;
  margin-top: 15px;
}

.about-style .main-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes iq-circle-effect {
  0% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes iq-circle-effect {
  0% {
    -moz-transform: rotate(30deg);
    transform: rotate(30deg)
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes iq-circle-effect {
  0% {
    -o-transform: rotate(30deg);
    transform: rotate(30deg)
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes iq-circle-effect {
  0% {
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.about-style:hover .effect-circle {
  webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

.about-style .effect-circle {
  width: 180px;
  height: 180px;
  border-radius: 900px;
  border: 1px solid rgba(255, 106, 92, 1);
  position: absolute;
  -webkit-animation: iq-circle-effect 10s infinite linear;
  -moz-animation: iq-circle-effect 10s infinite linear;
  -o-animation: iq-circle-effect 10s infinite linear;
  animation: iq-circle-effect 10s infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.about-style .dot-circle {
  position: relative;
  width: 180px;
  height: 180px;
  display: inline-block;
  margin-bottom: 30px;
}

.about-style .effect-circle:before {
  width: 10px;
  height: 10px;
  top: -5px;
  background: #ffffff;
  border: 1px solid #6f73f0;
  border-radius: 90px;
  display: inline-block;
  position: absolute;
  content: "";
  opacity: 1;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.about-style:hover .effect-circle:before {
  opacity: 1;
}

.about-style.purple-hover .effect-circle {
  border: 1px solid rgba(139, 118, 246, 0.6);
}

.about-style.purple-hover .circle-bg {
  background: rgba(139, 118, 246, 0.2);
}

.about-style.purple-hover .main-circle i {
  color: #6f73f0;
}

.about-style.purple-hover .effect-circle:before {
  border-color: #6f73f0;
}

.about-style.org-hover .effect-circle {
  border: 1px solid rgba(255, 106, 92, 0.6);
}

.about-style.org-hover .circle-bg {
  background: rgba(255, 106, 92, 0.2);
}

.about-style.org-hover .main-circle i {
  color: #ff796d;
}

.about-style.org-hover .effect-circle:before {
  border-color: #ff796d;
}

.about-style.green-hover .effect-circle {
  border: 1px solid rgba(100, 218, 193, 0.6);
}

.about-style.green-hover .circle-bg {
  background: rgba(100, 218, 193, 0.2);
}

.about-style.green-hover .main-circle i {
  color: #6cdac1;
}

.about-style.green-hover .effect-circle:before {
  border-color: #6cdac1;
}


/*---------------------------------------------------------------------
Swiper Slider(about-us page-2)
-----------------------------------------------------------------------*/
.over-history-blog.swiper-container .consulting-project .project-details {
  text-align: left;
}

.over-history-blog.swiper-container .project-year {
  position: relative;
  margin-bottom: 15px;
}

.over-history-blog.swiper-container .swiper-slide {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 30px;
  margin-top: 30px;
}

.over-history-blog.swiper-container .project-year:before {
  position: absolute;
  content: "";
  left: 0;
  top: -40px;
  right: 0;
  width: 20px;
  height: 20px;
  background-image: -moz-linear-gradient(0deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29, 0.95) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29, 0.95) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29, 0.95) 100%);
  margin: 0 auto;
  border-radius: 90px;
}

.swiper-container.over-history-blog .swiper-scrollbar {
  background: rgba(30, 30, 30, 0.04);
}

/*---------------------------------------------------------------------
Consulting Project
-----------------------------------------------------------------------*/
.consulting-project {
  position: relative;
  margin: 30px 15px;
  padding: 10px;
  background: #ffffff;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  display: inline-block;
  -webkit-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.swiper-scrollbar {
  background: #f7f7f9;
}

.consulting-project .project-details {
  text-align: center;
  padding: 15px;
}

.consulting-project .project-details span {
  font-weight: normal;
  color: #6f73f0;
  font-family: var(--body-font-family);
  margin-bottom: 10px;
}

.consulting-project .project-button span {
  color: #ffffff;
}

.consulting-project .consult-effect:before {
  content: "";
  transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
  -moz-transition: all 5s ease-in-out;
  -ms-transition: all 5s ease-in-out;
  -o-transition: all 5s ease-in-out;
  -webkit-transition: all 5s ease-in-out;
  opacity: 1;
  z-index: 1;
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: -120px;
  left: 0;
  -ms-transform: skewY(-5deg);
  -webkit-transform: skewY(-5deg);
  transform: skewY(-3deg);
  background-image: -moz-linear-gradient(0deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29, 0.95) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29, 0.95) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29, 0.95) 100%);
  content: "";
  z-index: 1;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.consulting-project:hover .consult-effect:before {
  bottom: -93px;
  content: "";
}


/*---------------------------------------------------------------------
Dot Pulse
-----------------------------------------------------------------------*/
.loader-dot {
  position: absolute;
  width: 30%;
  height: 0px;
  padding-top: 28%;
  right: -40%;
  top: 32%;
  transform: translateX(-50%) translateY(-50%);
}

.loader-dot div:nth-child(1) {
  position: absolute;
  left: 15%;
  top: 38%;
  width: 8px;
  height: 8px;
  background-color: #e7eaf3;
  border-radius: 100%;
  transform: translateX(-50%) translateY(-50%);
  animation: wobble 1s linear 0.2s infinite;
}

.loader-dot div:nth-child(2) {
  position: absolute;
  left: 30%;
  top: 38%;
  width: 8px;
  height: 8px;
  background-color: #e7eaf3;
  border-radius: 100%;
  transform: translateX(-50%) translateY(-50%);
  animation: wobble 1s linear 0.4s infinite;
}

.loader-dot div:nth-child(3) {
  position: absolute;
  left: 45%;
  top: 38%;
  width: 8px;
  height: 8px;
  background-color: #e7eaf3;
  border-radius: 100%;
  transform: translateX(-50%) translateY(-50%);
  animation: wobble 1s linear 0.6s infinite;
}

@keyframes wobble {

  0%,
  50%,
  75% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }

  25% {
    transform: translateX(-50%) translateY(-50%) scale(1.4);
  }
}

.testimonial-style.owl-carousel .testimonial-info:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 140px;
  opacity: 1;
  top: 111%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/*---------------------------------------------------------------------
Images jump effect
-----------------------------------------------------------------------*/
.bg-img-jump.left,
.bg-img-jump.right {
  position: relative;
}

.bg-img-jump img {
  position: absolute;
  border-radius: 90px;
  width: 60px;
}

.bg-img-jump.left .img-two {
  position: absolute;
  left: -26%;
  top: -26%;
}

.bg-img-jump.left .img-one {
  position: absolute;
  left: 18%;
  top: -100px;
}

.bg-img-jump.left .img-three {
  position: absolute;
  left: -15%;
  top: 132px;
}

.bg-img-jump.left .img-four {
  position: absolute;
  top: 200px;
  left: -32%;
}

.bg-img-jump.right .img-two {
  position: absolute;
  right: -26%;
  top: -26%;
}

.bg-img-jump.right .img-one {
  position: absolute;
  right: 18%;
  top: -100px;
}

.bg-img-jump.right .img-three {
  position: absolute;
  right: -15%;
  top: 132px;
}

.bg-img-jump.right .img-four {
  position: absolute;
  top: 200px;
  right: -32%;
}

/*---------------------------------------------------------------------
Advance-Feature
-----------------------------------------------------------------------*/
.lightbluegradient-bg:before {
  background-image: -moz-linear-gradient(90deg, rgba(30, 187, 240, 0) 0%, rgba(55, 125, 254, 0.10196) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(30, 187, 240, 0) 0%, rgba(55, 125, 254, 0.10196) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(30, 187, 240, 0) 0%, rgba(55, 125, 254, 0.10196) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  content: "" !important;
}

.advance-feature .user-count,
.advance-feature .month-count,
.advance-feature .total-count {
  display: inline-block;
  width: 25%;
  margin-bottom: 45px;
}

.advance-feature .pricing-table {
  position: absolute;
  top: 10%;
  left: 14%;
}

.advance-feature .pricing-table ul {
  padding: 10px;
  border: 1px solid rgb(118, 124, 240, 0.2);
}

.advance-feature .col-lg-6.border-right-0 {
  padding: 45px;
  border: 1px solid #eff1fe;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.advance-feature .col-lg-6 {
  padding: 45px;
  border: 1px solid #eff1fe;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.advance-feature .col-lg-6.btn-top {
  padding-top: 180px;
}

.advance-feature .user-count,
.advance-feature .month-count {
  margin-right: 30px;
}

.advance-feature .user-count,
.advance-feature .month-count {
  position: relative;
}

.advance-feature .month-count:before {
  content: "=";
  position: absolute;
  right: -23px;
  top: 48%;
  color: #1e1e1e;
  font-family: "Ionicons";
}

.advance-feature .service-price ul li {
  list-style: none;
  margin-bottom: 15px;
  color: #1e1e1e;
}

.advance-feature .service-price ul {
  position: relative;
  padding-left: 110px;
}

.advance-feature input[type="range"] {
  width: 100%;
  height: 28px;
  outline: none;
  border: 0;
  padding: 0 8px;
  margin: 25px 0;
}

.advance-feature .user-count:before {
  content: "\f2c7";
  position: absolute;
  right: -23px;
  top: 48%;
  color: #1e1e1e;
  font-family: "Ionicons";
}

.advance-feature .service-price input[type=checkbox] {
  width: 20px;
}

.advance-feature .nav-pills .nav-item {
  margin-right: 15px;
}

.advance-feature .nav-pills .nav-item:last-child {
  margin-right: 0px;
}

.advance-feature .nav-pills .nav-link {
  color: #1e1e1e;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-radius: 4px;
  padding: 10px 30px;
  border: none;
  position: relative;
  display: inline-block;
  background-size: 200% auto;
  cursor: pointer;
  z-index: 4;
  background-color: rgb(118, 124, 240, 0.4);
}

.advance-feature .nav-pills .nav-link.active {
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-radius: 4px;
  padding: 10px 30px;
  border: none;
  position: relative;
  display: inline-block;
  background-size: 200% auto;
  background-image: linear-gradient(to right, var(--linear-color-1) 0%, var(--linear-color-2) 51%, #016b97cc 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
  cursor: pointer;
  z-index: 4;
}

.advance-feature input[type=range].input-range {
  -webkit-appearance: none;
  box-shadow: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: rgba(30, 30, 30, 0.48);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.advance-feature input[type=range].input-range:hover {
  opacity: 1;
}

.advance-feature input[type=range].input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--linear-color-2);
  cursor: pointer;
  box-shadow: 0px 10px 20px 0px rgb(1, 107, 151, 0.4);
}

.advance-feature [type="checkbox"]:checked,
.advance-feature [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.advance-feature [type="checkbox"]:checked+label,
.advance-feature [type="checkbox"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.advance-feature [type="checkbox"]:checked+label:before,
.advance-feature [type="checkbox"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  background: #fff;
  border-radius: 90px;
}

.advance-feature [type="checkbox"]:checked+label:after,
.advance-feature [type="checkbox"]:not(:checked)+label:after {
  content: '';
  width: 4px;
  height: 4px;
  background: var(--primary-color);
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 90px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.advance-feature .form-control:disabled,
.advance-feature .form-control[readonly] {
  background: #ffffff;
  border-radius: 4px;
}

.advance-feature [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.advance-feature [type="checkbox"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


.ui.range {
  width: 100%;
  height: 20px;
}

.ui.range .inner {
  height: 20px;
  position: relative;
}

.ui.range .inner:hover {
  cursor: pointer;
}

.ui.range .inner .track {
  position: absolute;
  width: 100%;
  height: 5px;
  border-radius: 4px;
  top: 9px;
  left: 0;
  background-color: rgba(0, 0, 0, .05);
}

.ui.inverted.range .inner .track {
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.ui.range .inner .track-fill {
  position: absolute;
  width: 0;
  height: 5px;
  border-radius: 4px;
  top: 9px;
  left: 0;
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.ui.inverted.range .inner .track-fill {
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.ui.range .inner .thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 90px;
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}


/*---------------------------------------------------------------------
Banner-shap
-----------------------------------------------------------------------*/
.banner-one-shap .iq-objects.style-two.left-top .iq-objects-02 img {
  left: inherit;
  z-index: -1;
  right: -62%;
  width: 800px;
}

.banner-one-shap .iq-objects.style-two .iq-objects-03 img {
  width: 400px;
  left: -400px;
  bottom: 0;
}

.banner-overlap {
  position: relative;
  z-index: 0;
}

/*---------------------------------------------------------------------
Pricing Table
-----------------------------------------------------------------------*/
.iq-pricing .month-price h2,
.iq-pricing .month-price h6 {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  color: #1e1e1e;
}

.iq-pricing:hover .month-price h2,
.iq-pricing:hover .month-price h6 {
  color: #ffffff;
  z-index: 1;
  position: relative;
}

.iq-pricing ul {
  margin-bottom: 0;
  padding: 0;
  margin-top: 30px;
}

.iq-pricing ul li {
  margin-bottom: 30px;
  list-style: none;
}

.iq-pricing .month-circle {
  position: absolute;
  top: 0;
  left: 0;
}

.iq-pricing .main-title {
  text-align: right;
}

.iq-pricing .main-title .title {
  padding: 45px;
}

.iq-pricing .month-price {
  padding-top: 15px;
  width: 120px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 50%;
  border-top-left-radius: 0;
}

.iq-pricing {
  padding: 10px 10px 0;
  background: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-bottom: 30px;
}

.iq-pricing:hover {
  box-shadow: 0px 8px 57.4px 12.6px rgba(0, 0, 0, 0.08);
  border: solid 1px transparent;
}

.iq-pricing .price-title {
  min-height: 150px;
  position: relative;
}

.iq-pricing .price-footer .button {
  margin-bottom: 45px;
}

.iq-pricing.green-hover {
  border: 1px solid rgba(100, 218, 193, 0.4);
}

.iq-pricing.green-hover:hover {
  border: solid 1px transparent;
}

.iq-pricing.green-hover .price-footer .button {
  margin-bottom: 45px;
  background: rgba(100, 218, 193, 0.2) !important;
  color: #6cdac1;
  position: relative;
}

.iq-pricing.green-hover .price-footer .button:hover {
  margin-bottom: 45px;
  background: rgba(100, 218, 193, 1) !important;
  color: #ffffff;
}

.iq-pricing.green-hover:hover .month-price {
  background: rgba(100, 218, 193, 1);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.iq-pricing.green-hover .price-title {
  background: rgba(100, 218, 193, 0.2);
  min-height: 150px;
  position: relative;
}

.iq-pricing.org-hover {
  border: 1px solid rgba(255, 121, 109, 0.4);
}

.iq-pricing.org-hover:hover {
  border: solid 1px transparent;
}

.iq-pricing.org-hover .price-footer .button {
  margin-bottom: 45px;
  background: rgba(255, 121, 109, 0.2) !important;
  color: #ff796d;
}

.iq-pricing.org-hover .price-footer .button:hover {
  margin-bottom: 45px;
  background: rgba(255, 121, 109, 1) !important;
  color: #ffffff;
}

.iq-pricing.org-hover:hover .month-price {
  background: rgba(255, 121, 109, 1);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.iq-pricing.org-hover .price-title {
  background: rgba(255, 121, 109, 0.2);
  min-height: 150px;
}

.iq-pricing.purple-hover {
  border: 1px solid rgba(139, 118, 246, 0.4);
}

.iq-pricing.purple-hover:hover {
  border: 1px solid transparent;
}

.iq-pricing.purple-hover .price-footer .button {
  margin-bottom: 45px;
  background: rgba(139, 118, 246, 0.2) !important;
  color: #6f73f0;
}

.iq-pricing.purple-hover .price-footer .button:hover {
  margin-bottom: 45px;
  background: rgba(139, 118, 246, 1) !important;
  color: #ffffff;
}

.iq-pricing.purple-hover:hover .month-price {
  background: rgba(139, 118, 246, 1);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.iq-pricing.purple-hover .price-title {
  background: rgba(139, 118, 246, 0.2);
  min-height: 150px;
}

.iq-pricing.blue-hover {
  border: 1px solid rgba(55, 125, 255, 0.4);
}

.iq-pricing.blue-hover:hover {
  border: solid 1px transparent;
}

.iq-pricing.blue-hover .price-footer .button {
  margin-bottom: 45px;
  background: rgba(55, 125, 255, 0.2) !important;
  position: relative;
}

.iq-pricing.blue-hover .price-footer .button:hover {
  margin-bottom: 45px;
  background: rgba(55, 125, 255, 1) !important;
  color: #ffffff;
}

.iq-pricing.blue-hover:hover .month-price {
  background: rgba(55, 125, 255, 1);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.iq-pricing.blue-hover .price-title {
  background: rgba(55, 125, 255, 0.4);
  min-height: 150px;
  position: relative;
}

.month-price:before {
  border-radius: 4px;
  content: "";
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  border-top-left-radius: 0;
  width: 120px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 50%;
  border-top-left-radius: 0;
}

.iq-pricing.purple-hover:hover .month-price:before {
  opacity: 1;
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
  z-index: 0;
}

.iq-pricing.org-hover:hover .month-price:before {
  opacity: 1;
  background-image: -moz-linear-gradient(-60deg, rgb(254, 86, 5) 0%, rgb(255, 145, 92) 100%);
  background-image: -webkit-linear-gradient(-60deg, rgb(254, 86, 5) 0%, rgb(255, 145, 92) 100%);
  background-image: -ms-linear-gradient(-60deg, rgb(254, 86, 5) 0%, rgb(255, 145, 92) 100%);
  box-shadow: 0px 10px 20px 0px rgba(255, 121, 109, 0.4);
  z-index: 0;
}

.iq-pricing.green-hover:hover .month-price:before {
  opacity: 1;
  background-image: -moz-linear-gradient(0deg, rgb(108, 218, 193) 0%, rgb(144, 227, 209) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(108, 218, 193) 0%, rgb(144, 227, 209) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(108, 218, 193) 0%, rgb(144, 227, 209) 100%);
  box-shadow: 0px 10px 20px 0px rgba(100, 218, 193, 0.6);
  z-index: 0;
}

.platform-position {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: -2%;
}

.bg-img-platform img {
  border-radius: 90px;
  width: 80px;
}


/*---------------------------------------------------------------------
                         Pricing Table
-----------------------------------------------------------------------*/
.iq-pricing-table {
  text-align: center;
  padding: 50px 20px;
  background: none;
  float: left;
  width: 100%;
  -webkit-animation: rotate 0.5s;
  -moz-animation: rotate 0.5s;
  animation: rotate 0.5s;
  -webkit-animation: rotate-inverse 0.5s;
  -moz-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 0px 150px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.iq-pricing-table h2 {
  font-size: 40px;
  margin-bottom: 20px;
  line-height: normal;
}

.iq-pricing-table h2 span {
  font-size: 16px;
  font-weight: normal;
}

.iq-pricing-table ul li {
  list-style: none;
  color: #1e1e1e;
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.iq-pricing-table ul li i {
  margin-right: 15px;
}

.iq-pricing-table .button {
  background: #16171d;
}

.iq-pricing-table .button.blue-btn {
  background: #3277f8;
}

.iq-pricing-table .button.blue-btn:hover {
  background: #1e1e1e;
}

.iq-pricing-table .iq-mtb-30 {
  background: #f7f7f7;
  padding: 30px;
  border-radius: 4px;
}

ul.our-new-team.blue-color .team-blog:before,
.fancy-service-list.blue-tick .fancy-service-detail .iq-img i {
  background: #3277f8;
}

header.blue-menu .navbar .navbar-nav .nav-item a:hover,
header.blue-menu .navbar .navbar-nav .nav-item a:focus,
header.blue-menu .navbar .navbar-nav .nav-item a.active,
header.blue-menu .navbar .navbar-nav .nav-item a.active:focus,
header.blue-menu .navbar .navbar-nav .nav-item a.active:hover {
  color: #3277f8;
}

header.blue-menu .navbar .navbar-nav .nav-item a::before,
header.blue-menu .navbar .sub-main ul.shop_list li .cart_count #mini-cart-count:before {
  background: #3277f8;
}

#back-to-top .top.blue-color:hover {
  background: #3277f8;
}

/*---------------------------------------------------------------------
Blog
-----------------------------------------------------------------------*/
.iq-blog-box.sofbox-blog {
  margin-bottom: 45px;
}

.iq-blog-box.sofbox-blog .blog-title .entry-title a:hover {
  color: var(--primary-color);
}

.iq-widget-menu .iq-post li a.date-widget {
  font-size: 14px;
}

/*---------------------------------------------------------------------
Job details
-----------------------------------------------------------------------*/
.job-details span.dot-postion {
  position: relative;
}

.job-details span.deatils {
  color: #1e1e1e;
  font-family: 'Now';
}

.job-details li {
  margin-bottom: 15px;
  list-style: none;
}

.job-details .dot-postion:before {
  content: ":";
  position: absolute;
  right: 0;
}

.job-details .dot-postion {
  width: 25%;
  float: left;
}

.deatils-social {
  display: flex;
}

.deatils-social .share-tags {
  display: inline-block;
  flex: 1;
}

.deatils-social .share-tags li {
  margin-right: 5px;
}

.deatils-social .share-tags li:last-child {
  margin-right: 0px;
}

.deatils-social .share-tags li a {
  text-decoration: none;
  padding: 5px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 12px;
}

.border-bottom {
  border-bottom: 1px solid rgba(30, 30, 30, 0.08);
}


/*---------------------------------------------------------------------
Careers
-----------------------------------------------------------------------*/
.iq-accordion.career-style .active-faq .row {
  background: #eff1fe;
  padding: 10px;
  border-radius: 4px;
}

.iq-accordion.career-style .active-faq a.accordion-title {
  color: #1e1e1e;
  padding-left: 0px;
  text-decoration: none;
}

.iq-accordion.career-style .iq-accordion-block {
  margin-bottom: 30px;
}

.iq-accordion.career-style .iq-accordion-block .accordion-title span {
  font-size: 20px;
  font-family: 'Now';
  display: table-cell;
}

.iq-accordion.career-style .iq-accordion-block:last-child {
  margin-bottom: 0px;
}

.iq-accordion.career-style .iq-accordion-block.accordion-active .accordion-title:before {
  content: "\f209";
  display: none;
}

.iq-accordion.career-style .accordion-title:before {
  display: none;
  cursor: pointer;
  content: "\f218";
  font-family: "Ionicons";
  position: absolute;
  top: 0;
  left: 15px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  line-height: 40px;
  text-align: center;
}

.iq-accordion.career-style .active-faq .button {
  float: right;
  border-radius: 90px;
  overflow: hidden;
}

/*---------------------------------------------------------------------
                        Frequently Asked Questions
-----------------------------------------------------------------------*/
.iq-asked {
  position: relative;
}

.iq-accordion {
  z-index: 9;
  position: relative;
}

.iq-accordion .ad-title {
  position: relative;
  padding: 15px 50px 15px 15px;
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
  background-color: #f5f7fb;
  display: block;
  text-align: left;
  color: #2c3e50;
  text-decoration: none;
  border-radius: 4px;
}

.iq-accordion .ad-title::before {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 15px 0px;
  cursor: pointer;
  content: "";
  font-family: Ionicons;
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  padding: 14px 20px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  font-weight: normal;
  transition: all 0.25s ease-in-out 0s;
  background: rgb(0, 202, 214) !important;
}

.iq-accordion .ad-active .ad-title:before {
  content: "\f3d8";
  font-family: "Ionicons";
}

.iq-accordion .ad-details {
  display: none;
  overflow: hidden;
  text-align: left;
  padding: 15px 15px;
  line-height: 24px;
  background: #fff;
  border: 1px solid #eeeeee;
  border-top: none;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
}

.iq-accordion .iq-ad-block {
  margin-bottom: 30px;
}

.iq-accordion .iq-ad-block:last-child {
  margin-bottom: 0;
}

.iq-objects-asked {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.iq-objects-asked .iq-objects-01 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.iq-objects-asked .iq-objects-02 {
  position: absolute;
  top: 37%;
  left: 35%;
}

.iq-objects-asked .iq-objects-03 {
  position: absolute;
  top: 28%;
  left: 13%;
  border: 25px solid #4ac4f3;
  border-radius: 900px;
  height: 350px;
  width: 350px;
}


/*---------------------------------------------------------------------
contact us social
-----------------------------------------------------------------------*/
.team-social {
  opacity: 1;
  display: inline-block;
}

.team-social ul {
  width: 100%;
  display: inline-block;
  padding: 0px 10px;
}

.team-social ul li {
  display: inline-block;
  width: auto;
  float: inherit
}

.team-social ul li a {
  color: #ffffff;
  padding: 10px;
}

.team-social ul li a:hover {
  color: var(--primary-color);
}

.team-social ul li a i {
  font-size: 18px;
  display: inline-block;
  float: none;
  text-align: center;
  background: transparent;
}

.contact-basic-details .contact-box ul li:hover {
  background: #ffffff;
}

.contact-basic-details .contact-box ul li:hover a {
  color: #6f73f0;
}

.contact-basic-details .contact-box ul li a {
  color: #ffffff
}

.our-map .wpb_gmaps_widget .wpb_wrapper {
  padding: 0;
}

.contact-basic-details .contact-box ul li {
  display: inline-block !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  color: #ffffff;
  background: transparent;
  text-align: center;
  margin-right: 10px;
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
}

.contact-basic-details .contact-box {
  position: absolute;
  bottom: 10%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.contact-basic-details .contact-box ul {
  padding: 0;
}

.contact-basic-details {
  position: relative;
}

.contact-basic-details .contact-basic {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}


/*---------------------------------------------------------------------
 Breadcrumb style 1 2 and 3 4 5
-----------------------------------------------------------------------*/
.breadcrumb li {
  display: inline-block;
  word-break: break-all;
}

.iq-breadcrumb {
  padding: 100px 0;
  background: #eff1fe;
}

.iq-breadcrumb-two {
  padding: 45px 0;
  position: relative;
  z-index: 2;
}

.iq-breadcrumb-one {
  display: block;
  padding: 150px 0 80px;
  z-index: 9;
  position: relative;
}

.iq-breadcrumb-one ol li a {
  color: var(--primary-color);
  text-decoration: none;
}

.iq-breadcrumb-one ol li {
  list-style: none;
  display: inline-block;
}

.iq-breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
  content: ">";
  color: var(--primary-color);
}

.iq-breadcrumb-one .breadcrumb {
  position: relative;
  display: inline-block;
}

.iq-breadcrumb-one .breadcrumb-item.active {
  color: var(--primary-color);
  position: relative;
}

.iq-breadcrumb-one ol {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.iq-breadcrumb-two .breadcrumb {
  display: inline-block;
}

.iq-breadcrumb-two ol li {
  display: inline-block;
}

.iq-breadcrumb-three .breadcrumb {
  margin-top: 0;
}

.iq-breadcrumb-three .iq-breadcrumb-one {
  padding: 0;
}

.iq-breadcrumb-three {
  padding: 45px 0;
}

.breadcrumb-bg,
.breadcrumb-video,
.video-iq-bg-over {
  position: relative;
  overflow: hidden;
}

.breadcrumb-bg video,
.breadcrumb-bg #video-background,
.breadcrumb-video video,
.video-iq-bg-over video,
.video-breadcrumb-bg #video-background,
.video-iq-bg-over #video-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100%;
}

.breadcrumb-bg.iq-over-dark-80:before,
.breadcrumb-ui:before {
  z-index: 0;
}

.sofbox-gradient-bg-top {
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}


/*---------------------------------------------------------------------
                     CIRCLE PROGRESS BAR
-----------------------------------------------------------------------*/
.progressbar-circle {
  display: inline-block;
  width: 100%;
}

.progressbar-circle .circle {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  text-align: center;
}

.progressbar-circle .circle canvas {
  vertical-align: middle;
  height: 160px !important;
  width: 160px !important;
}

.progressbar-circle .circle div {
  position: absolute;
  top: 45px;
  left: 5px;
  width: 100%;
  text-align: center;
  line-height: 80px;
  font-size: 32px;
  color: #8e989f;
}

.progressbar-circle .circle strong i {
  font-style: normal;
  font-size: 0.6em;
  font-weight: normal;
}

.progressbar-circle .circle span {
  display: block;
  color: #aaa;
  margin-top: 12px;
}

.progressbar-circle .circle p {
  color: #1e1e1e;
}

/*---------------------------------------------------------------------
                          Progress Bar
-----------------------------------------------------------------------*/
.iq-services-box .services-content {
  margin-bottom: 25px;
}

.iq-services-box .services-content h6 {
  font-size: 15px;
}

.iq-services-box .services-content:last-child {
  margin-bottom: 0
}

.progress-value {
  float: right;
  border-radius: 0;
  color: #142c4b;
  line-height: 30px;
  font-weight: 600;
  position: relative;
  bottom: 27px;
  right: 0;
}

.iq-progress-bar {
  background: rgba(36, 38, 43, .09);
  box-shadow: 0 0 0;
  height: 4px;
  margin: 0;
  position: relative;
  width: 100%;
  margin: 15px 0 0;
}

.iq-progress-bar>span {
  background-image: linear-gradient(to right, var(--linear-color-1) 0%, var(--linear-color-2) 51%, #016b97cc 100%);
  display: block;
  height: 100%;
  width: 0;
}

.slide-in-top {
  -webkit-animation: slide-in-top 1.6s linear infinite alternate backwards;
  animation: slide-in-top 1.6s linear infinite alternate backwards;
}

.iq-progress-bar>span:before {
  width: 15px;
  content: "";
  height: 15px;
  position: absolute;
  top: -6px;
  background: #ffffff;
  right: 0;
  border-radius: 90px;
  display: inline-block;
  z-index: 9;
  box-shadow: 0px 3px 12.48px 0.52px rgba(0, 0, 0, 0.15);
}

/*---------------------------------------------------------------------
  Social icone
-----------------------------------------------------------------------*/
.share-box ul li:hover {
  background-size: 200% auto;
  background-image: linear-gradient(to right, var(--linear-color-1) 0%, var(--linear-color-2) 51%, #016b97cc 100%);
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.share-box ul li:hover a {
  color: #ffffff;
}

.share-box ul li a {
  color: #6f73f0;
}

.share-box ul li {
  display: inline-block !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  margin-right: 10px;
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
}


/*---------------------------------------------------------------------
 Owl slider arrow
-----------------------------------------------------------------------*/
.owl-carousel.arrow .owl-nav button.owl-next {
  right: 4%;
}

.owl-carousel.arrow .owl-nav button.owl-prev {
  left: 4%;
}

.owl-carousel.arrow .owl-nav i {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

/*---------------------------------------------------------------------
                               woocommerce
-----------------------------------------------------------------------*/
.woocommerce .loader::before,
.woocommerce-products-header {
  display: none;
}

.woocommerce .woocommerce-ordering {
  margin: 0 0 30px;
}

.woocommerce .woocommerce-result-count {
  margin: 7px 0 15px;
}

.woocommerce-message,
.woocommerce-info {
  border-top-color: var(--primary-color);
  line-height: 2.8em;
  background: #f5f7fb;
}

.woocommerce-message::before,
.woocommerce-info::before {
  color: var(--primary-color);
}

.woocommerce .woocommerce-message .button {
  background: var(--primary-color);
  color: #ffffff;
  font-weight: 500;
}

.woocommerce .woocommerce-message .button:hover {
  background: #333333;
  color: #ffffff;
}

/* inject:scss */
.woocommerce .products ul,
.woocommerce ul.products {
  margin: 0;
}

.woocommerce .products.columns-4 li.product {
  margin: 15px;
  margin-bottom: 45px;
  width: 22%;
  list-style: none;
  display: inline-block;
}

.woocommerce .products li.product.last,
.woocommerce-page .products li.last {
  margin-right: 0;
}

/*---------------------------------------------------------------------
                               Product
-----------------------------------------------------------------------*/
.iq-product-block {
  position: relative;
}

.woocommerce ul.products li.product a img {
  margin: 0;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title {
  margin: 0;
  text-align: center;
}

.iq-product-image img {
  width: 100%;
}

/* onsale */
.woocommerce span.onsale,
.woocommerce ul.products li.product .onsale {
  color: #fff;
  background: var(--primary-color);
  text-transform: uppercase;
  padding: 5px 10px;
  text-align: center;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  right: auto;
  margin: 0;
  min-height: auto;
  line-height: 18px;
  font-weight: 400;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* woocommerce Product */
.iq-product-image {
  border-radius: 8px;
  overflow: hidden;
}

.iq-product-img-wrap {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.iq-product-block .woocommerce-loop-product__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.woocommerce ul.products li.product .iq-product-block .hover_image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.woocommerce ul.products li.product:hover .hover_image {
  opacity: 1;
}

/* woocommerce Product button */
.woocommerce ul.products li.product .iq-product-block .button {
  position: absolute;
  left: 30px;
  margin: 0 auto;
  right: 30px;
  padding: 5px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  bottom: -50px;
  border-radius: 4px;
}

.woocommerce a.added_to_cart {
  margin: 0;
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  display: inline-block;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  background: #ffffff;
  font-family: 'Now';
  z-index: 9;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-align: center;
  color: #1e1e1e;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.woocommerce ul.products li.product:hover .iq-product-block .button,
.woocommerce ul.products li.product:hover .iq-product-block a.added_to_cart {
  bottom: 10px;
}

.yith-wcwl-add-to-wishlist {
  position: absolute;
  top: 8px;
  right: 15px;
  margin: 0;
  z-index: 9;
}

.yith-wcwl-add-to-wishlist .yith-wcwl-add-button a {
  font-size: 0;
}

.yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:before {
  content: "\f08a ";
  font-family: FontAwesome;
  color: #1e1e1e;
  font-size: 16px;
}

.yith-wcwl-add-to-wishlist .yith-wcwl-add-button.hide {
  display: block !important;
}

.yith-wcwl-add-to-wishlist .yith-wcwl-add-button.hide a:before,
.yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:hover:before {
  color: var(--primary-color);
  text-decoration: none;
}

.yith-wcwl-add-to-wishlist .ajax-loading,
.yith-wcwl-wishlistexistsbrowse.show,
.yith-wcwl-wishlistaddedbrowse.show,
#yith-wcwl-popup-message,
.yith-wcwl-wishlistexistsbrowse.show {
  display: none !important;
}


/* woocommerce Product detail */
.woocommerce ul li.product .iq-product-block .product-caption h3 {
  margin: 0;
  padding: 0;
  color: #1e1e1e;
  line-height: 1.5;
  font-size: 18px;
}

.woocommerce ul li.product .iq-product-block .product-caption h3 a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.woocommerce ul.products li.product .iq-product-block .product-caption .price {
  margin: 0;
  padding: 0;
  color: var(--primary-color);
  font-size: 16px;
}

.woocommerce ul.products li.product .iq-product-block .product-caption .price ins {
  font-weight: 400;
  background: transparent;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
  color: var(--primary-color);
  font-weight: 400;
  margin: 15px 0;
}

.woocommerce .quantity .qty {
  padding-right: 0;
  text-align: left;
}

.product_meta .sku_wrapper,
.product_meta .posted_in {
  display: block;
}

.woocommerce ul.products li.product .price del {
  color: #8e989f;
}

.woocommerce ul.products li.product .product-caption .star-rating {
  font-size: 12px;
  margin: 0;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 10px;
}

.woocommerce ul.products li.product .product-caption .star-rating span,
.woocommerce .star-rating span {
  color: #ff8d39;
  padding-top: 1em;
}

/*---------------------------------------------------------------------
                           woocommerce Pagination
-----------------------------------------------------------------------*/
.woocommerce nav.woocommerce-pagination ul {
  margin: 0;
  border: none;
}

.woocommerce nav.woocommerce-pagination ul li {
  border: none;
  margin-right: 5px;
}

.woocommerce nav.woocommerce-pagination ul li:last-child {
  margin-right: 0;
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers {
  position: relative;
  display: block;
  padding: 5px 15px;
  margin: 0;
  color: #666666;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  font-size: 16px;
  line-height: 2;
}

.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
  font-weight: 400;
}

/*---------------------------------------------------------------------
                           Tab products
-----------------------------------------------------------------------*/
.woocommerce div.product .woocommerce-tabs ul.tabs::before {
  border-bottom: 1px solid #e9e9e9;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  text-align: center;
  padding: 0;
  margin: 0 0;
  border: none;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
  padding: 0 30px;
  margin: 0;
  background: none;
  border-color: #e9e9e9;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active::before,
.woocommerce div.product .woocommerce-tabs ul.tabs li::after,
.woocommerce div.product .woocommerce-tabs ul.tabs li::before {
  display: none !important;
}

.woocommerce-tabs {
  margin-bottom: 80px;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
  background: var(--primary-color);
  color: #ffffff;
  border-color: var(--primary-color);
}

.woocommerce-Tabs-panel h2 {
  margin-bottom: 15px;
}

.woocommerce .comment-respond .comment-reply-title:before {
  display: none;
}

.woocommerce-Tabs-panel .comment-respond .comment-form-author,
.woocommerce-Tabs-panel .comment-respond .comment-form-email,
.woocommerce-Tabs-panel .comment-respond .comment-form-url {
  width: 48%;
}

.woocommerce-Tabs-panel .comment-respond .comment-form-email {
  float: right;
}

.woocommerce .woocommerce-Tabs-panel #review_form .comment-respond .form-submit {
  margin: 0;
}

.woocommerce div.product .woocommerce-tabs .panel {
  margin: 0;
  padding: 30px 30px 15px;
  border: 1px solid #e9e9e9;
  border-top: none;
}

/*---------------------------------------------------------------------
                           Cart
-----------------------------------------------------------------------*/
.woocommerce-cart-form {
  margin-bottom: 80px;
}

.woocommerce-additional-fields h3 {
  margin-top: 30px;
}

.cross-sells h2,
.cart_totals h2 {
  margin-bottom: 30px;
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 150px;
  padding-left: 10px;
  border-radius: 4px;
}

.woocommerce .cart_totals .wc-proceed-to-checkout .button {
  width: 100%;
  padding: 15px 30px;
}

.woocommerce-billing-fields h3,
#order_review_heading,
.woocommerce-order-details__title {
  margin-bottom: 15px;
}

.woocommerce table.shop_table {
  border: 1px solid #eaeaea;
}

.woocommerce table.shop_table td {
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-left: 1px solid #eaeaea;
  border-bottom: none;
  border-right: none;
}

.woocommerce-billing-fields__field-wrapper label,
.woocommerce-billing-fields__field-wrapper .woocommerce-input-wrapper {
  display: inline-block;
  width: 100%;
}

.woocommerce form .form-row .select2-container {
  border: 1px solid #ededed;
  background: #f5f7fb;
  line-height: 45px;
  height: 45px;
  padding: 0 10px;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.select2-search--dropdown {
  display: inline-block;
  padding: 10px;
  width: 100%;
}

.select2-container--default .select2-selection--single {
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
}

.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[data-selected=true] {
  background: #f5f7fb;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
  background: var(--primary-color);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #666666;
  line-height: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 44px;
}

.select2-dropdown {
  border: 1px solid #ededed;
}

.order-total {
  color: var(--primary-color);
}

.woocommerce-input-wrapper {
  display: inline-block;
  width: 100%;
}

.woocommerce form .form-row textarea {
  padding: 15px;
  height: 6em;
}

.woocommerce-checkout #payment ul.payment_methods li input {
  width: inherit;
  float: left;
  height: auto;
  margin: 10px 5px 0 0;
}

.woocommerce ul.order_details {
  margin: 15px 0;
  padding: 15px;
  border: 1px solid #ededed;
  background: #f5f7fb;
}

/*---------------------------------------------------------------------
                           Related products
-----------------------------------------------------------------------*/
.related.products h2,
.upsells.products h2 {
  margin-bottom: 30px;
  text-align: center;
}

.woocommerce .related.products .products.columns-4 li.product {
  margin-bottom: 0;
}

/* woocommerce Button */
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce #respond input#submit,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce button.button.alt.disabled {
  color: #fff;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  border: none;
  position: relative;
  background: var(--primary-color);
  font-family: 'Now';
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce .cart_totals .wc-proceed-to-checkout .button:hover,
.woocommerce button.button.alt:hover {
  color: #ffffff;
  background: #333333;
  text-decoration: none;
}

.woocommerce #respond input#submit {
  margin-top: 30px;
  float: right;
  padding: 10px 30px;
}

/*---------------------------------------------------------------------
                           My Account
-----------------------------------------------------------------------*/
.woocommerce form.login {
  border-color: #e0e0e0;
}

input[type=checkbox] {
  height: 34px;
  width: 13px;
  width: auto;
  margin-right: 5px;
}

.woocommerce-form__label-for-checkbox {
  margin-top: 5px;
  margin-left: 30px;
}

.woocommerce form .form-row {
  padding: 0;
  margin: 0 0 15px;
}

.woocommerce-MyAccount-navigation ul {
  margin: 0 15px 0 0;
  padding: 0;
  border: 1px solid #e0e0e0;
}

.woocommerce-MyAccount-navigation li {
  list-style: none;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.woocommerce-MyAccount-navigation li a {
  padding: 10px 15px;
  color: var(--primary-color);
  display: inline-block;
  width: 100%;
}

.woocommerce-MyAccount-navigation li a:hover,
.woocommerce-MyAccount-navigation li.is-active a {
  color: #ffffff;
  text-decoration: none;
  background: var(--primary-color);
}

.woocommerce-MyAccount-navigation li a:before {
  float: right;
  font-family: FontAwesome;
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--dashboard a:before {
  content: "\f0e4";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--orders a:before {
  content: "\f291";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--downloads a:before {
  content: "\f1c6";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--edit-address a:before {
  content: "\f015";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--payment-methods a:before {
  content: "\f09d";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--edit-account a:before {
  content: "\f007";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--customer-logout a:before {
  content: "\f08b";
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--subscriptions a:before {
  content: "\f021";
}

/*---------------------------------------------------------------------
                           Swiper style one
-----------------------------------------------------------------------*/
.swiper-style-two {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-style-two {
  width: 100%;
  height: 100%;
}

.swiper-style-two .swiper-slide img {
  border-radius: 4px;
}

.swiper-style-two .swiper-slide {
  text-align: center;
  width: 80%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-style-two .video-btn a i {
  line-height: 100px;
  font-size: 20px;
  color: #ffffff;
}

.swiper-style-two .video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.swiper-style-two .video-btn a {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-image: -moz-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  background-image: -webkit-linear-gradient(0deg, var(--linear-color-1) 0%, var(--linear-color-2) 100%);
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 90px;
}

/*---------------------------------------------------------------------
                    Portfolio page
-----------------------------------------------------------------------*/
.main-portfolio .nav-links .nav-previous,
.main-portfolio .nav-links .nav-next {
  display: inline-block;
}

.main-portfolio .nav-links .nav-next {
  float: right;
}

.main-portfolio .nav-links {
  padding-top: 45px;
  border-top: 1px solid rgba(30, 30, 30, 0.08);
}

/*---------------------------------------------------------------------
                    Maintenance & comingsoon
-----------------------------------------------------------------------*/
.maintenance {
  text-align: center;
  padding-top: 80px;
}

.iq-coming {
  padding: 143px 0;
  text-align: center;
}

.iq-coming ul {
  list-style: none;
}

.iq-coming .countdown {
  list-style: none;
  margin: 20px 0 30px 0;
  padding: 0;
  text-align: center;
  display: inline-block;
}

.iq-coming .countdown li {
  display: inline-block;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.13);
  width: 140px;
  height: 150px;
  padding: 14px;
}

.iq-coming .countdown li span {
  font-size: 50px;
  font-weight: 800;
  line-height: 80px;
  color: #1e1e1e;
  position: relative;
}

.iq-coming .countdown li.seperator {
  font-size: 50px;
  line-height: 40px;
  vertical-align: top;
  margin: 16px 0px;
  color: #1e1e1e;
}

.iq-coming .countdown li h6 {
  color: #1e1e1e;
  font-size: 20px;
  padding-right: 30px;
}

.iq-coming .countdown li p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1e1e1e;
}

.iq-coming .countdown li:last-child span:after {
  content: none;
}

@media(max-width:1024px) {

  .popup-absolute-images .img-one,
  .popup-absolute-images .img-two {
    display: none;
  }

  .color-palette .color-text {
    font-size: 16px;
  }
}

@media(max-width:992px) {
  .maintenance {
    padding: 150px 0;
  }

  .iq-coming .countdown li {
    width: 114px;
    height: 130px;
  }

  .iq-coming {
    padding: 100px 0;
  }
}

@media(max-width:767px) {
  .maintenance h2 {
    font-size: 40px;
  }

  .iq-coming h1 {
    font-size: 64px;
  }

  .iq-coming .countdown li {
    width: 63px;
    height: 120px;
    margin: 0 10px;
  }

  .sofbox-feature-circle ul {
    text-align: center;
  }

  .sofbox-feature-circle .sofbox-img ul li .feature-info {
    height: 126px !important;
    width: 126px !important;
  }

  .sofbox-feature-circle .sofbox-img ul li .feature-info h6 {
    font-size: 14px;
  }

  .sofbox-feature-circle .sofbox-img ul li {
    height: 115px;
    width: 115px;
  }
}

@media(max-width:479px) {
  .iq-coming h1 {
    font-size: 44px;
  }

  .maintenance h2 {
    font-size: 32px;
  }

  .iq-coming .countdown li {
    margin: 10px;
    width: auto;
    height: auto;
    padding: 10px;
    margin: 0;
    border: none;
  }

  .iq-coming .countdown li span {
    font-size: 24px;
    line-height: 24px;
  }

  .iq-coming .countdown li p {
    font-size: 14px;
    line-height: 20px;
    font-weight: inherit;
  }
}

/*---------------------------------------------------------------------
                   Career details
-----------------------------------------------------------------------*/
.career-details-box {
  background: #ffffff;
  border: 1px solid rgba(30, 30, 30, 0.07);
  padding: 30px 15px !important;
  border-radius: 4px;
}

.apply-box {
  padding: 30px 15px !important;
  border: 1px solid rgba(30, 30, 30, 0.07);
  border-top: none;
}

.cfield.upload-file input {
  padding: 0;
}

/*fancy list style 3*/
.fancy-service-list .fancy-service-detail {
  margin: 15px 0;
}

.fancy-service-list {
  box-shadow: 0px 20px 90px 0px rgba(0, 0, 0, 0.15);
  padding: 45px 30px;
}

.fancy-service-list .fancy-service-detail .iq-img {
  border-radius: 100%;
  position: relative;
  border: 20px solid #f3f7fd;
  background: #e7effa;
  text-align: center;
  height: 200px;
  width: 200px;
  margin: 0 auto 30px;
  line-height: 160px;
}

.fancy-service-list .fancy-service-detail .iq-img i {
  color: #ffffff;
  position: absolute;
  height: 37px;
  width: 37px;
  border-radius: 90px;
  right: 0;
  top: -20px;
  font-size: 16px;
  line-height: 37px;
  background: linear-gradient(to right, var(--linear-color-1) 0%, var(--linear-color-2) 51%, #016b97cc 100%);
}

.fancy-service-list .fancy-service-detail .iq-img img {
  width: auto;
  margin: 0 auto;
}

.fancy-list-box {
  display: flex;
}

.fancy-list-box img.fancy-list-img {
  margin-right: 30px;
}

/*feature circle*/
.sofbox-feature-circle {
  position: relative;
  overflow: hidden;
}

.sofbox-feature-circle .main-circle {
  margin: 0 auto;
  text-align: center;
}

.sofbox-feature-circle .main-circle img.fancy-list-img {
  margin-top: 25%;
}

.sofbox-feature-circle .dot-circle {
  border-radius: 100%;
  height: 950px;
  width: 950px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}

.sofbox-feature-circle .effect-circle {
  border-radius: 100%;
  height: 600px;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}

.sofbox-feature-circle .sofbox-img ul li .feature-info {
  text-align: center;
  height: 136px;
  border-radius: 100%;
  background: #ffffff;
  width: 136px;
  padding: 20px;
}

.sofbox-feature-circle .sofbox-img ul li {
  position: absolute;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(1) {
  left: 3%;
  top: 50%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(2) {
  left: 20%;
  top: 15%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(3) {
  right: 20%;
  top: 15%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(4) {
  right: 3%;
  top: 58%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(5) {
  left: 20%;
  bottom: 15%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(6) {
  left: 24%;
  top: 40%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(7) {
  right: 40%;
  top: 25%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(8) {
  right: 20%;
  top: 45%;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(9) {
  right: 20%;
  bottom: 10%;
}

/* Mobile and Table */
@media screen and (max-width: 768px) {
  .sofbox-feature-circle .sofbox-img ul li:nth-child(1) {
    left: 0;
    top: 75%;
    bottom: unset;
    right: unset;
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(2) {
    left: 0;
    top: 0;
    bottom: unset;
    right: unset;
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(3) {
    left: unset;
    top: 0;
    bottom: unset;
    right: 8px;
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(4) {
    left: unset;
    top: 75%;
    bottom: unset;
    right: 8px;
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(5) {
    left: 50%;
    top: 0;
    bottom: unset;
    right: unset;
    transform: translateX(calc(-50% - 8px));
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(6) {
    left: 0;
    top: 25%;
    bottom: unset;
    right: unset;
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(7) {
    left: unset;
    top: 25%;
    bottom: unset;
    right: 8px;
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(8) {
    left: 50%;
    top: 25%;
    bottom: unset;
    right: unset;
    transform: translateX(calc(-50% - 8px));
  }

  .sofbox-feature-circle .sofbox-img ul li:nth-child(9) {
    left: unset;
    top: 75%;
    bottom: unset;
    right: 50%;
    transform: translateX(calc(50% - 8px));
  }
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(1) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(2) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(3) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(4) .feature-info {
  height: 120px;
  width: 120px;
}

.sofbox-feature-circle .sofbox-img ul li .feature-info .feature-img {
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: initial;
}

/*verticle tab*/
.custom-tab.verticaltab3 .nav.nav-pills .nav-link {
  position: relative;
}

.custom-tab.verticaltab3 .nav.nav-pills .nav-link:after {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: -4px;
  bottom: 0;
  background: #ffffff;
}

.custom-tab.verticaltab3 .nav.nav-pills .nav-link:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: -4px;
  top: 0;
  background: #ffffff;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] {
  box-shadow: none;
  border-radius: 0;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] li.nav-item {
  padding: 0 30px 20px;
  width: 100%;
  vertical-align: middle;
  float: left;
  margin-bottom: 0px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] li.nav-item:last-child {
  margin-bottom: 0;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link {
  border-left: 4px solid #32A7C6;
  padding: 15px 25px;
  border-radius: 0px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 50px 0px;
  transition: all 0.5s ease-in-out 0s;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link img {
  margin-right: 15px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link p {
  display: none;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link h4 {
  font-size: 18px;
  margin-top: 10px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:last-child,
.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] li.nav-item:last-child {
  margin-bottom: 0;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover h4 {
  color: #32A7C6;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active {
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 29px 60px 0px rgba(143, 144, 145, 0.39);
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active h4 {
  margin-top: -10px;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover {
  background: #ffffff;
}

.custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active p {
  display: block;
  color: #8e989f;
}

/* .custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link.active:before, .custom-tab.verticaltab3 .nav.nav-pills[aria-orientation="vertical"] .nav-link:hover:before { display: none; } */
.custom-tab.verticaltab3 .tab-pane.fade.active.show {
  display: block;
}

.custom-tab.verticaltab3 .tab-pane.fade {
  display: none;
}

/*counter horizontal*/
.iq-timer.counter-horizontal .timer-details .timer,
.iq-timer.counter-horizontal .timer-details .total-cust,
.iq-timer.counter-horizontal .timer-details .price {
  font-size: 35px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}

.iq-timer.counter-horizontal .timer-details i {
  font-size: 30px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}

.iq-timer.counter-horizontal .timer-details p {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}

.iq-timer.counter-horizontal .timer-details {
  color: #ffffff;
  padding: 10px;
}

.iq-timer.counter-horizontal .border-effect {
  width: 30px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  margin: 0 15px;
}

.iq-timer.counter-horizontal .border-effect .title-saas {
  position: relative;
  background: #000000;
  height: 2px;
  width: 30px;
  overflow: hidden;
}

.iq-timer.counter-horizontal {
  text-align: center;
  margin: 0 auto 30px;
  border-radius: 4px;
}

.iq-timer.counter-horizontal .border-effect .title-saas::after,
.iq-timer.counter-horizontal .border-effect .title-saas::before {
  content: "";
  position: absolute;
  background: #ffffff;
  width: 3px;
  height: 2px;
  left: 0;
  z-index: 1;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: H-MOVE-BG;
  animation-name: H-MOVE-BG;
}

@-webkit-keyframes H-MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
  }
}

@keyframes H-MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
  }
}

.tab-img-shadow img {
  box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.1);
}

.popup-absolute-images {
  position: absolute;
  bottom: 50%;
  width: 100%;
  -webkit-animation: bounces 5s infinite alternate;
}

.popup-absolute-images .img-two {
  right: 0;
  left: auto;
  bottom: 0;
  z-index: 2;
}

.popup-absolute-images .img-one {
  left: 0;
  z-index: 2;
}

.popup-absolute-images .img-three {
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  bottom: -250px;
}

.popup-absolute-images .img-four {
  right: 20%;
  text-align: center;
  top: -250px;
  z-index: -1;
}

.popup-absolute-images.align-left,
.popup-absolute-images.align-right {
  top: 50%;
}

.popup-absolute-images.align-left .img-one {
  left: 0;
  top: 0;
}

.popup-absolute-images.align-left .img-two {
  left: 10%;
  top: 15%;
}

.popup-absolute-images.align-left .img-three {
  left: 0%;
  top: 45%;
}

.popup-absolute-images.align-left .img-four {
  left: -10%;
  top: 80%;
}

.popup-absolute-images.align-right .img-one {
  right: 0;
  top: 0;
}

.popup-absolute-images.align-right .img-two {
  right: 10%;
  top: 15%;
}

.popup-absolute-images.align-right .img-three {
  right: 0%;
  top: 45%;
}

.popup-absolute-images.align-right .img-four {
  right: -10%;
  top: 80%;
}

.custom-tab.horizontaltab .tab-pane.fade.active.show {
  display: block;
}

.custom-tab.horizontaltab .tab-pane.fade {
  display: none;
}

a.nav-link.active.show {
  background: transparent;
}

.custom-tab.horizontaltab .nav.nav-pills {
  display: block;
  width: 100%;
  overflow: hidden;
}

.custom-tab.horizontaltab .nav.nav-pills li {
  display: inline-block;
  width: auto;
}

.custom-tab.horizontaltab .nav-pills .nav-link .tab-title {
  display: inline-block;
}

.custom-tab.horizontaltab .show_content {
  width: 100%;
  text-align: left;
}

.custom-tab.horizontaltab .nav-pills .nav-link.active,
.custom-tab.horizontaltab .nav-pills .show>.nav-link,
.custom-tab.horizontaltab .nav-pills .nav-link:hover {
  background-image: linear-gradient(to right, rgb(0 201 213) 0%, rgb(0 202 214) 100%);
}

.custom-tab.horizontaltab .nav-pills .nav-link.active .tab-title,
.custom-tab.horizontaltab .nav-pills .show>.nav-link .tab-title,
.custom-tab.horizontaltab .nav-pills .nav-link:hover .tab-title {
  color: #ffffff;
}

.page-id-1790 .content-area .site-main,
.page-id-1584 .content-area .site-main {
  padding: 0;
}

.page-id-1790 .iq-breadcrumb-one,
.page-id-1584 .iq-breadcrumb-one {
  display: none;
}

.fancy-list-box .fancy-list-img {
  margin-right: 30px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: #ffffff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 30px;
}

.fancy-list-box .service-detail {
  flex: 1;
  display: inline-block;
  vertical-align: middle;
}

.fancy-list-box .fancy-list-img:before {
  border-radius: 90px;
  line-height: 80px;
  width: 80px;
  height: 80px;
  text-align: center;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-top-left-radius: 0px;
}

.fancy-list-box .fancy-list-img.purple-hover:before {
  background-image: -moz-linear-gradient(90deg, rgb(1, 106, 150) 0%, rgb(22, 97, 127) 100%);
  background-image: linear-gradient(135deg, rgb(27, 151, 180) 0%, rgb(11, 6, 39) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(135, 154, 251) 0%, rgb(23, 51, 195) 100%);
}

.fancy-list-box .fancy-list-img.org-hover:before {
  background-image: -moz-linear-gradient(90deg, rgb(230, 166, 160) 0%, rgb(255, 106, 92) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(230, 166, 160) 0%, rgb(255, 106, 92) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(230, 166, 160) 0%, rgb(255, 106, 92) 100%);
}

.fancy-list-box .fancy-list-img.green-hover:before {
  background-image: -moz-linear-gradient(90deg, rgb(149, 239, 218) 0%, rgb(100, 216, 190) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(149, 239, 218) 0%, rgb(100, 216, 190) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(149, 239, 218) 0%, rgb(100, 216, 190) 100%);
}


.full-img-left img {
  max-width: none !important;
  left: -60%;
  position: relative;
}

.full-img-left-two img {
  max-width: none !important;
  left: -40%;
  position: relative;
}

.full-img-effect img {
  max-width: none !important;
  left: -39%;
  position: relative;
}

.full-img-right img {
  max-width: none !important;
  right: 0;
  position: relative;
}

@media (max-width:1024px) {
  .full-img-right img {
    max-width: 100% !important;
  }
}

.job-potal-img img {
  max-width: none !important;
  position: absolute;
  top: -150px;
}

/*---------------------------------------------------------------------
                  Rating Effect (Feedback)
-----------------------------------------------------------------------*/
.owl-rating {
  padding: 30px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}

.owl-rating .iq-star {
  margin-bottom: 15px;
  display: inline-block;
}

.owl-rating .iq-star i {
  color: #fbdc00;
  margin-right: 5px;
}

.owl-rating .iq-star i:last-child {
  margin: 0;
}

/*---------------------------------------------------------------------
                Curve shap effect
-----------------------------------------------------------------------*/
.cure-shap-right:before {
  content: "";
  width: 250px;
  height: 100%;
  top: 0;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  position: absolute;
  left: -20%;
}

.cure-shap-right {
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  border-top-right-radius: 50px;
  position: relative;
}

.cure-shap-left:before {
  content: "";
  width: 250px;
  height: 100%;
  top: 0;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  position: absolute;
  right: -20%;
}

.cure-shap-left {
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(243, 249, 255) 100%);
  border-top-left-radius: 50px;
  position: relative;
}

.page-id-2468 .iq-breadcrumb-one {
  display: none;
}

.page-id-2468 .content-area .site-main {
  padding-top: 0;
}

/*---------------------------------------------------------------------
                Fancy Services
-----------------------------------------------------------------------*/
.fancy_service {
  border-radius: 5px;
  border: 1px solid #f4f8ff;
  box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 60px;
  padding: 0;
  display: block;
  float: left;
  width: 100%;
}

.fancy_service .fancy-info {
  float: left;
  width: 70%;
  padding: 30px;
}

.fancy_service .fancy_img {
  position: absolute;
  right: -70px;
  bottom: -50px;
}

.fancy_service .fancy_block {
  display: block;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #ffffff;
}

.fancy_service .bg_img {
  opacity: 0;
  position: absolute;
  right: -10px;
  top: -26px;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-animation: bounces 2s infinite alternate;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.fancy_service .fancy_img {
  width: 50%;
  float: left;
  z-index: -1;
}

.fancy_service:hover .bg_img {
  opacity: 1;
}

/*---------------------------------------------------------------------
                Services box effect
-----------------------------------------------------------------------*/
.squre-effect-top {
  margin-top: -35px;
}

.squre-effect-bottom {
  margin-top: -35px;
}

.squre-effect {
  position: relative;
  box-shadow: 0px 0px 150px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.squre-effect .icon-box .img-effect i {
  display: inline-block;
  line-height: normal;
  font-size: 45px;
}

.squre-effect .icon-box {
  padding: 30px;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}

.squre-effect:hover:before {
  transform: rotate(12deg);
}

.squre-effect:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
  background-image: -webkit-linear-gradient(-20deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29) 100%);
  background-image: -ms-linear-gradient(-20deg, rgb(1, 107, 151) 0%, rgb(1, 21, 29) 100%);
  transform: rotate(0deg);
  z-index: -1;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-radius: 4px;
  transform: rotate(0deg);
}

.org-gradiant-bg {
  background-image: -moz-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
  background-image: -webkit-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
  background-image: -ms-linear-gradient(-20deg, rgb(249, 122, 63) 0%, rgb(253, 106, 105) 100%);
}

/*---------------------------------------------------------------------
                 Frequently Asked Questions
-----------------------------------------------------------------------*/
.iq-accordion.career-style.faq-style.style-two .iq-accordion-block.accordion-active .accordion-title:before,
.iq-accordion.career-style.faq-style.style-two .accordion-title:before {
  background: transparent;
  color: #7ca0f4;
  box-shadow: none;
}

.iq-accordion.career-style.faq-style.style-two .iq-accordion-block .accordion-details p {
  padding-left: 74px;
  padding-top: 0;
  padding-bottom: 15px;
}

/*---------------------------------------------------------------------
 isotope-filters
-----------------------------------------------------------------------*/
#demos-isotop .isotope-filters-fixed.menu-sticky {
  position: fixed;
  left: 0;
  right: 0;
  top: 74px;
  z-index: 99;
  margin: 0;
  opacity: 0;
}

#demos-isotop .isotope-filters-fixed.menu-sticky.show {
  opacity: 1;
}

#demos-isotop .isotope-filters-fixed.menu-sticky+.iq-masonry.iq-columns-3 {
  margin-top: 100px;
}

.isotope-filters.isotope-tooltip .more-btn,
.isotope-filters.isotope-tooltip button {
  outline: medium none;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  padding: 15px 10px;
  width: 100%;
  background: transparent;
  border: none;
  color: #142c4b;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.isotope-filters.isotope-tooltip>button:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.isotope-filters.isotope-tooltip button.active {
  color: #fff;
  background-color: #32A7C6;
}

.isotope-filters.isotope-tooltip button span {
  color: #32A7C6;
  margin-left: 5px;
}

.isotope-filters.isotope-tooltip button.active span {
  color: #ffffff;
}

.isotope-filters.isotope-button {
  display: table;
  margin: 0 auto 40px;
  text-align: center;
  border: 1px solid #f2f2f2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 10px
}

.isotope-filters.isotope-button button {
  margin: 4px;
  cursor: pointer;
  padding: 8px 20px;
  font-size: 16px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: 0 0;
  color: #666;
  border: none;
  font-weight: 400;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out
}

.isotope-filters.isotope-button button.active,
.isotope-filters.isotope-button button:hover {
  background: #32A7C6;
  color: #fff;
  -webkit-box-shadow: 0 6px 20px -5px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 6px 20px -5px rgba(0, 0, 0, .2);
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, .2)
}

.isotope-filters.isotope-button button:focus {
  outline: none;
  outline-style: none;
  outline-offset: 0
}

.isotope-filters.isotope-line button {
  border: none;
  border-bottom: 2px solid transparent;
  background: 0 0;
  color: #666;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0;
  padding: 10px 30px
}

.isotope-filters.isotope-line button.active,
.isotope-filters.isotope-line button:hover {
  border-bottom: 2px solid #32A7C6;
  color: #32A7C6
}

.isotope-filters.isotope-border button {
  border: 1px solid transparent;
  background: 0 0;
  color: #666;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0
}

.isotope-filters.isotope-border button.active,
.isotope-filters.isotope-line button:hover {
  border: 1px solid #32A7C6;
  color: #32A7C6
}

.isotope-filters.isotope-dots button {
  position: relative;
  color: #777
}

.isotope-filters.isotope-dots button.active,
.isotope-filters.isotope-dots button:hover {
  background: 0 0;
  box-shadow: none;
  color: #32A7C6
}

.isotope-filters.isotope-text button {
  color: #777
}

.isotope-filters.isotope-text button.active,
.isotope-filters.isotope-text button:hover {
  background: 0 0;
  box-shadow: none;
  color: #32A7C6
}

.iq-masonry-item {
  padding: 0 15px;
  width: 33.33%;
}

.iq-masonry-item .iq-portfolio-04 {
  padding: 6px;
  box-shadow: 0px 8px 60px 12px rgba(35, 38, 43, 0.2);
  position: relative;
}

.more-btn .more-menu {
  position: absolute;
  top: 100%;
  background: #fff;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.45s ease 0s;
}

.isotope-filters.isotope-tooltip .more-btn:hover .more-menu {
  opacity: 1;
  transition: all 0.45s ease 0s;
}

.isotope-filters.isotope-tooltip .more-btn .more-menu button {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.isotope-filters.isotope-tooltip .more-btn .more-menu button:last-child {
  border-bottom: none;
}

.iq-masonry.iq-columns-3 {
  width: 100%;
}

.iq-masonry-item a {
  font-weight: 500;
}

.iq-masonry-item a:hover,
.iq-masonry-item h5:hover {
  color: #32A7C6 !important;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #32A7C6 !important;
}






/* isoTop*/
@media(max-width:1399px) {

  .isotope-filters.isotope-tooltip .more-btn,
  .isotope-filters.isotope-tooltip button {
    padding: 15px 5px;
  }
}

/* isoTop*/
@media(min-width:992px) {
  .isotope-filters-button {
    display: none;
  }

  .isotope-filters.isotope-tooltip {
    margin: 0 auto 70px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

/* isoTop*/
@media (min-width:768px) {
  .isotope-filters.isotope-tooltip {
    margin: 0 auto 70px;
    background: #ffffff;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

}

@media(max-width:992px) {
  header .btn-purchase {
    position: absolute;
    right: 100px;
    top: -50px;
  }

  .iq-masonry-item {
    width: 50%;
  }

  .container-fluid {
    padding: 15px 0;
  }

  button.isotope-filters-button>i {
    line-height: 30px;
  }

  .isotope-filters.isotope-tooltip {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    background: #ffffff;
  }

  button.isotope-filters-button {
    width: 100%;
    color: #fff;
    background-color: #32A7C6;
    border: transparent;
    padding: 10px;
    font-weight: 700;
  }
}

@media(max-width:767px) {
  .iq-masonry-item {
    width: 100%;
  }
}

.iq-badge {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: normal;
  padding: 10px;
  margin-bottom: 15px;
}

.iq-fancy-boxnew {
  padding: 40px 15px 25px 15px;
  overflow: hidden;
  position: relative;
  background: #fff;
}

.iq-fancy-boxnew .iq-icon {
  border-radius: 90px;
  display: inline-block;
  height: 86px;
  width: 86px;
  line-height: 86px;
  text-align: center;
  color: #fff;
  background: #085878;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-fancy-boxnew .iq-icon i {
  font-size: 46px;
}

.iq-fancy-boxnew .fancy-content h5 {
  z-index: 9;
  position: relative;
}

.iq-works-imgs {
  width: 100%;
}

.iq-fancy-boxnew.text-center .icon-bg {
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  margin: auto;
  position: relative;
  background-color: #085878;
  background-image: linear-gradient(-45deg, #085878 0%, #085878 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: one-animated 10s infinite;
  overflow: hidden;
}

/*----------------
  Loader
------------------------*/
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 4rem;
  height: 4rem;
  margin: 1rem auto;
  border: 4px solid #32A7C6;
  border-radius: 50%;
  border-right-color: transparent;
  border-left-color: transparent;
  animation: linear spin 1s infinite;
}

#productos-desc {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
}

#productos-desc .card {
  border: 2px solid #32A7C6;
}

#productos-desc .card p {
  color: #0F145B;
}

#productos-desc .card .btn {
  background: #32A7C6;
}

#productos-desc .card.disabled {
  border: 2px solid #808080;
}

#productos-desc .card.disabled p {
  color: #808080;
}

#productos-desc .card.disabled .btn {
  background: #808080;
}

#productos-desc .card:hover:not(.disabled) {
  border: 2px solid #0F145B;
}

#productos-desc .card:hover:not(.disabled) .btn {
  background: #0F145B;
}

.swiper-slide .swiper-link img {
  transition: all 0.3s ease-out;
}

.swiper-slide .swiper-link img:hover {
  transform: scale(1.08);
}

.swiper-slide[data-product="manejo consentimiento"] {
  width: 12.48rem !important;
}

#producto-video video {
  position: absolute;
  background: #000;
}

[data-frame=laptop] {
  position: absolute;
  top: 12rem;
  left: 0;
}

[data-frame=laptop]+#producto-beneficios {
  position: absolute;
  bottom: 10rem;
}

[data-frame=laptop]#producto-video img {
  transform: scale(2);
}

[data-frame=laptop]#producto-video img~video {
  top: -5.2rem;
  left: -1.6rem;
  width: 38.8rem;
  aspect-ratio: 16/9;
}

[data-frame=laptop]+#producto-beneficios::before {
  content: '';
  width: 75%;
  border-radius: .5rem;
  height: .25rem;
  background: #fff;
  position: absolute;
  top: -5rem;
  left: 12.5%;
}

[data-frame=movil] {
  position: absolute;
  bottom: 0;
  left: -8rem;
}

[data-frame=movil]+#producto-beneficios {
  position: absolute;
  top: 0;
  margin-top: 3rem;
}

[data-frame=movil]#producto-video img~video {
  top: 5.3rem;
  left: 12.6rem;
  width: 18rem;
  aspect-ratio: 9/16;
}

@media screen and (min-width: 1400px) {
  .container-xl {
    max-width: 1320px;
  }
}

@media screen and (min-width: 1200px) {
  #producto-descripcion-contenido {
    min-height: 32rem;
  }
}